import {useContext, useRef, useEffect, useState} from 'react'
import Card from 'shared/components/Card'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'
import Button from 'shared/components/Button'
import {PageContext} from 'shared/contexts/PageContext'
import {parseJwt} from 'shared/utils/index'
import useService from 'shared/hooks/useService'
import useToken from 'shared/hooks/useToken'
import {formatPhoneNumber} from 'shared/utils'
// import { ModalType } from 'shared/constants/modal'
import {RxPlSignupBase, RxPlFormLayout, RxPlFormGroup, RxPlFormContentWrapper, RxPlFormButtonWrapper} from './style'
import {titles} from './mock'
import { gaPageview, gaSignUpProgress } from 'shared/utils/ga'

const Contact = props => {

   const form = useRef(null)
   const [errorMesage, setErrorMessage] = useState("")
   const [titleValidity, setTitleValidity] = useState(false)
   const {api} = useService()
   const {token} = useToken()
   const {customer, setCustomer} = useContext(PageContext)
   const [pharmacyName, setPharmacyName] = useState("")
   const [contactName, setContactName] = useState("")
   const [dba, setDBA] = useState("")
   const [phoneNumber, setPhoneNumber] = useState("")
   const [title, setTitle] = useState("")
   const [faxNumber, setFaxNumber] = useState("")
   const [customerId, setCustomerId] = useState(null)
   const [fetching, setFetching] = useState(false)
   const createCustomer = async () => {

      const jwt = parseJwt(token || localStorage.getItem("token"))
      const formData = new FormData(form.current)
      formData.append("mobileNumber", "") // must supply

      const serializeFormData = Object.fromEntries(formData)
      try {
         const savedToken = localStorage.getItem("token")
         const savedCustomerId = localStorage.getItem("customerId") || null
         let response = savedCustomerId ? await api(savedToken || token).put("/customer/" + savedCustomerId, serializeFormData) : await api(savedToken).post("/customer", serializeFormData)
         const profile = {
            email: jwt["email"] || "",
            ...response.data
         }
         setCustomer({...customer,
            profile})


         localStorage.setItem('contact', JSON.stringify({
            ...serializeFormData,
            customerId: response.data.customerId
         }))

         if (!localStorage.getItem("email")) {
            localStorage.setItem("email", jwt["email"])         
         }

         if (!localStorage.getItem("customerId")) {
            localStorage.setItem("customerId", response.data.customerId)         
         }
         localStorage.setItem('onboarding', 'shipping')
         props.handleNextStep("shipping")
         gaSignUpProgress('contact', response.data.customerId)


      } catch ({response}) {
         const errorMessage = (response && response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         setErrorMessage(errorMessage)
         console.log(response)
      }
   }

   const getCustomer = async(callback) => {
      if (fetching) return
      setFetching(true)
      try {
         const savedToken = localStorage.getItem("token")

         let response = await api(savedToken || token).get("/customer/profile")
         const {customerId, fullName, shortName, uid, contactName, dba, title, officeNumber, mobileNumber} = response.data.data
         const profile = {
            customerId,
            fullName,
            shortName,
            uid,
            contactName,
            dba,
            title,
            mobileNumber,
            officeNumber
         }

         if (typeof callback !== 'undefined') {
            callback(profile)
         }

         setFetching(false)

      } catch ({response}) {
         setFetching(false)

         const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         console.log(response.status)
         if (response.status !== 400 && response.status !== 401 && response.status !== 404) {
            setErrorMessage(errorMessage)
         }

      }
   }

   // const prevStepHandler = e => {
   //    e.preventDefault()
   //    props.handleNextStep("account")
   //  }

   const nextStepHandler = e => {
      setErrorMessage("")
      e.preventDefault()
      createCustomer()

      // localStorage.setItem('testObject', JSON.stringify(testObject));

   }

   const onTitleChange = e => {
      const currentValue = e.target.value
      setTitle(currentValue)
      setTitleValidity(currentValue !== "")
   }

   const onPharmacyNameChange = (e) => {
      setPharmacyName(e.target.value)
   }

   const onDBAChange = (e) => {
      setDBA(e.target.value)
   }

   const onOfficeNumberChange = e => {
      const formatted = formatPhoneNumber(e.target.value)     
      setPhoneNumber(formatted)
   }

   const onContactNameChange = e => {
      setContactName(e.target.value)
   }

   const onFaxNumberChange = e => {
      const formatted = formatPhoneNumber(e.target.value)     
      setFaxNumber(formatted)
   }

   useEffect(()=>{
      let mounted = true
      const enterKeyListener = e => {
         if (e.code === "Enter" || e.code === "NumpadEnter" || e.keyCode === 13) {
            e.preventDefault()
            if (mounted) {
               setErrorMessage("")
               createCustomer()
            }
         }
      }
      // setModal({type: ModalType.LOADING})
      gaPageview("/contact", "Sign up - Contact")

      if (mounted) {
         if (!customerId) {
            getCustomer((data)=> {
               // localStorage.setItem('contact', JSON.stringify(profile))
               setCustomerId(data.customerId)
               setPharmacyName(data.fullName)
               setDBA(data.dba)
               setPhoneNumber(data.officeNumber)
               setContactName(data.contactName)
               setTitle(data.title)
               setTitleValidity(data.title !== "")
               setCustomer({...customer,
                  data})

               if (!localStorage.getItem("customerId")) {
                  localStorage.setItem("customerId", data.customerId)         
               }
   
            })
         }

      }


      document.addEventListener("keydown", enterKeyListener)
      return () => {
        document.removeEventListener("keydown", enterKeyListener);
        mounted = false
      }
   },[])


   return (
         <RxPlSignupBase>
            <RxPlSignupBase.Content>
               <RxPlFormLayout ref={form}>

                  {
                     errorMesage !== "" && <p className="error-message">Error: {errorMesage}</p>

                  }

                  <RxPlFormContentWrapper>

                  <Card>

                     <p>* = Required field</p>
                     <RxPlFormGroup>
                        <label>Pharmacy Name *</label>
                        <Input name="fullName" type="text" placeholder="Business Name" required minlength="4" onChange={onPharmacyNameChange} value={pharmacyName} />
                     </RxPlFormGroup>

                     <RxPlFormGroup>
                        <label>DBA *</label>
                        <Input name="dba" type="text" placeholder="DBA" required minlength="4" onChange={onDBAChange} value={dba} />
                     </RxPlFormGroup>
                     <RxPlFormGroup>
                        <label>Phone Number *</label>
                        <Input name="officeNumber" 
                              placeholder="(###) ###-####" 
                              type="tel" 
                              pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$" required
                              onChange={onOfficeNumberChange}
                              value={phoneNumber}
                              
                              />
                     </RxPlFormGroup>
                  </Card>  

                  <Card>
                     <p>* = Required field</p>
                     <RxPlFormGroup>
                        <label>Main Contact Name *</label>
                        <Input name="contactName" placeholder="Name" required minlength="3" onChange={onContactNameChange} value={contactName}  />
                     </RxPlFormGroup>
                     <RxPlFormGroup>
                        <label>Title (Owner/PIC/Pharmacist) *</label>
                        <Select className={`${titleValidity === true ? '' : 'invalid'}`} name="title" data={titles} onChange={onTitleChange} value={title} required />
                     </RxPlFormGroup>
                     <RxPlFormGroup>
                        <label>Fax Number</label>
                        <Input name="faxNumber" 
                              placeholder="(###) ###-####" 
                              type="tel"                               
                              pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$" 
                              value={faxNumber}
                                 onChange={onFaxNumberChange}
                              />
                     </RxPlFormGroup>
                  </Card>  
                  </RxPlFormContentWrapper>
                   <RxPlFormButtonWrapper>
                   {/* <Button type="outline" onClick={prevStepHandler}>Go to Previous Screen</Button> */}
                  <Button onClick={nextStepHandler} type="primary">Save and Continue</Button>
                   </RxPlFormButtonWrapper>
               </RxPlFormLayout>
             
            </RxPlSignupBase.Content>

         </RxPlSignupBase>
   )
}


export default Contact