
import React, {forwardRef} from 'react'
import {RxPlButton} from './styles'
const Button = forwardRef( (props, ref) => (
   <RxPlButton {...props} ref={ref}>
        {props.children}
    </RxPlButton>
))

export default Button
 