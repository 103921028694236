import React from 'react';
import {  Switch, Route, BrowserRouter } from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Home from 'pages/Home'
import Signup from 'pages/Signup'
import Account from 'pages/Account'
import SearchResults from 'pages/SearchResults'
import Checkout from 'pages/Checkout'
import Cart from 'pages/Cart'
import Uploader from 'pages/Uploader'
import { AccessCode, AdminLogin } from 'pages/Admin';
import PageWrapper from 'pages/layouts'

const customHistory = createBrowserHistory()
const Routes = () => (
  <BrowserRouter history={customHistory}>

  <PageWrapper>

    <Switch>
        <Route path="/signup/:step?" component={Signup} />
        <Route path="/account/:path?" component={Account} />
        <Route path="/results/:code?" component={SearchResults} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/cart" component={Cart} />
        <Route path='/uploader' component={Uploader} />
        <Route path="/admin/access-code" component={AccessCode} />
        <Route path="/admin" component={AdminLogin} />

        <Route component={Home} />

      </Switch>

  </PageWrapper>
  </BrowserRouter>

)

export default Routes;
