import styled from 'styled-components'
import {color} from 'shared/theme'
import {device} from 'shared/layout'

export const RxPlStepper = styled.div`
   display:flex;

`

RxPlStepper.Item = styled.div`
   position: relative;
   /* padding: 0 24px; */
   flex-basis: 0;
   flex-grow: 1;

   &:first-child {
      .bar-left {
         display: none;
      }
   }

   &:last-child {
      .bar-right {
         display: none;
      }
   }

   &.active {
      .circle {
         border: 3px solid ${color.sky};

         &::before {
            display: block;
         }
      }
   }
`

RxPlStepper.Item.Circle = styled.div`
   position: relative;
   width: 18px;
   height: 18px;
   border-radius: 50%;
   border: 3px solid ${color.grayLight};
   margin: 0 auto;
   z-index: 1;
   &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${color.sky};
      display: none;
   }

   @media only screen and ${device.sm} {
      width: 24px;
      height: 24px;

      &:before {
      width: 12px;
      height: 12px;
   }
   }

`

RxPlStepper.Item.BarRight = styled.div`
   position: absolute;
   height: 2px;
   border-top: 3px solid ${color.grayLight};
   right: 0;
   top: 8px;
   width: calc(50% - 11px);
   z-index: 0;


   @media only screen and ${device.sm} {
      top: 11px;

   }
`

RxPlStepper.Item.BarLeft = styled.div`
   position: absolute;
   height: 2px;
   border-top: 3px solid ${color.grayLight};
   left: 0;
   top: 8px;
   width: calc(50% - 11px);
   z-index: 0;
   @media only screen and ${device.sm} {
      top: 11px;

   }  
`
RxPlStepper.Item.Label = styled.div`
   text-align: center;
   margin-top: 10px;
   font-size: 12px;


   @media only screen and ${device.sm} {
      font-size: 16px;
   }
`