import styled from 'styled-components'
import {device} from 'shared/layout'
import {font} from 'shared/theme'

export const RxPlFormLayout = styled.form`
   margin: 24px 0;
   @media only screen and ${device.sm} {
   }
`
export const RxPlFormContentWrapper = styled.div`
   display: block;
   > div {
      width: 100%;
      margin-bottom: 32px;
   }


   @media only screen and ${device.sm} {
      display: flex;

      > div {
         flex-basis: 0;
         flex-grow: 1;
         margin: 0;
         p:first-of-type {
            text-align: right;
         }
      }

   }
`

export const RxPlFormContentCenterWrapper = styled.div`
   display: block;
   > div {
      width: 100%;
      margin-bottom: 32px;
      max-width: unset;
   }


   @media only screen and ${device.sm} {
      display: flex;
      margin: 0 -38px;

      > div {
         max-width: 920px;
         margin: 0 auto;
         p:first-of-type {
            text-align: right;
         }
      }

   }
`

export const RxPlFormGroupWrapper = styled.div`
   display: flex;
   margin: 0 -30px 16px;
   > div {
         flex-basis: 0;
         flex-grow: 1;
         margin: 0 30px;
      }
`
export const RxPlFormButtonWrapper = styled.div`
   width: fit-content;
   margin: 100px auto 0;
   @media only screen and ${device.sm} {
      button {
         width: 276px;
      }
   }

`

export const RxPlFormGroup = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 24px;

   label {
      margin-bottom: 16px;
      ${font.bold}

      span {
         ${font.regular}
      }
   }
   input {
      margin: 0;
      max-width: unset;
   }

`