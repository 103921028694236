import {RxPlStepper} from './style'

const Stepper = props => {

   return (

      <RxPlStepper>
         {
            props.data && props.data.length &&
            props.data.map ( (item, index) => (
               <RxPlStepper.Item key={index} className={`${props.step >= index ? 'active' : ''}`}>
                  <RxPlStepper.Item.Circle className="circle" />
                  <RxPlStepper.Item.BarRight className="bar-right" />
                  <RxPlStepper.Item.BarLeft className="bar-left" />
      
                  <RxPlStepper.Item.Label>
                     {item.title}
                  </RxPlStepper.Item.Label>
              </RxPlStepper.Item>
            ))
         }
      </RxPlStepper>

   )
}

export default Stepper
