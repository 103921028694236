
import {useContext} from 'react'
import {RxPlUtility} from './style'
import {PageContext} from 'shared/contexts/PageContext'
import {RxPlCloseButton} from 'shared/layout'
import closeIcon from 'assets/icons/close.svg'

const LicensorInformation = () => {
    const {
        setModal,
     }  = useContext(PageContext)

    return (
        <RxPlUtility>
            <RxPlCloseButton onClick={ () => setModal({type: ""})}>
            <img src={closeIcon} alt="close icon" />
            </RxPlCloseButton>
        <div className="wrapper">
            <h3>Licensor Information</h3>
            <p>.... In progress</p>
        </div>
    </RxPlUtility>
    )


}

export default LicensorInformation