import Button from 'shared/components/Button'

import {
   RxPlTimeoutMessage,
 
} from './styles'


const TimeoutMessage = props => {


   return (

      <RxPlTimeoutMessage>
        <p>Server took to long to respond.</p>
        <Button onClick={props.onRefresh} type="primary">REFRESH</Button>
      </RxPlTimeoutMessage>
   )
}

export default TimeoutMessage