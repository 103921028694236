import {useContext} from 'react'
import Topbar from 'shared/components/Topbar'
import Select from 'shared/components/Select'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Modal from 'shared/components/Modal'
import Footer from 'shared/components/Footer'
import {RxPlUploaderLayout} from './styles'
import {RxPlContent} from 'shared/layout'
import {PageContext} from 'shared/contexts/PageContext'


const wholesalerOptions = [
    {
       label: "Select Wholesaler",
       value: ""
    },
    {
       label: "Option 1",
       value: "option1"
    },
    {
       label: "Option 2",
       value: "option2"
    }
 ]

const Uploader = () => {

   const {modal} = useContext(PageContext)

   return (
      <>
               { modal.type !== "" && <Modal {...modal} /> }
         <Topbar   />
        <RxPlContent>
            <RxPlUploaderLayout>
                <RxPlUploaderLayout.Content>
                    <div className="item-wrapper">
                        <h4>Select your wholesaler</h4>
                        <form>
                            <Select data={wholesalerOptions} />
                            <p>(max file size 10 MB)</p>
                            <Input type="file" />
                            <Button type="primary" disabled>Upload</Button>
                        </form>
                    </div>

                    <div className="item-wrapper">
                        <h4>Most recently generated file</h4>
                        <p>Full_Inventory_04_26_2021.csv</p>
                    </div>
                </RxPlUploaderLayout.Content>

            </RxPlUploaderLayout>
            </RxPlContent>
         <Footer />

      </>

   )
}


export default Uploader