import React, {forwardRef} from 'react'
import {RxPlSelect} from './style'
const Select = forwardRef( (props, ref) => {
   const {name, id, onChange, value, iconType, className} = props
   const selectProps = {
      name,
      id,
      className,
      onChange,
      iconType,
      value
   }
   return (
      <RxPlSelect {...selectProps} ref={ref}>
      {
         props.data && props.data.length &&
          props.data.map ( (option, index) => (
            <option key={index} value={`${option.value}`}>{option.label}</option>
            )
         )
      }

   </RxPlSelect>

   )
   

})

export default Select
