export const urls = {
    LOCAL_API_URL: 'https://localhost:5001/api',
    STAGING_API_URL: 'https://app.rxplaceinternal.com/pharmacy/api',
    STAGING_ADMIN_API_URL: 'https://app.rxplaceinternal.com/rxadmin/api',
    DEV_API_URL: "https://dev.app.rxplaceinternal.com/pharmacy/api",
    DEV_ADMIN_API_URL: 'https://dev.app.rxplaceinternal.com/rxadmin/api',
    PRODUCTION_API_URL: 'https://app.myrxplace.com/pharmacy/api',
    PRODUCTION_ADMIN_API_URL: 'https://app.myrxplace.com/rxadmin/api',
    DEMO_API: "https://dev.app.rxplaceinternal.com/pharmacydemo/api",
    DEMO_ADMIN_API_URL: "https://dev.app.rxplaceinternal.com/rxadmindemo/api"
}