import {useContext, useState,useEffect, useRef} from 'react'
import Button from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import Form from 'shared/components/Form'
import { IconRegular } from 'shared/constants/icons'
import {RxPlInformationCard, RxPlFormFieldWrapper, RxPlFormButtonWrapper} from './styles'
import {stateList } from 'shared/data/states-list'
import {PageContext} from 'shared/contexts/PageContext'
import useService from 'shared/hooks/useService'
import Spinner from 'shared/components/Spinner'
import isEqual from 'lodash/isEqual';
import { gaPageview } from 'shared/utils/ga'
import Cookies from 'js-cookie'


const titles = [
   {
      value: "",
      label: "Select a title"
   },

   {
      value: "Owner",
      label: "Owner"
   },

   {
      value: "PIC",
      label: "PIC"
   },

   {
      value: "Pharmacist",
      label: "Pharmacist"
   },
   {
      value: "Buyer",
      label: "Buyer"
   }
]

const Information = () => {
   const {get,api} = useService()
   const {customer} = useContext(PageContext)
   const [profile, setProfile] = useState(null)
   const [shipping, setShipping] = useState(null)
   const [billing, setBilling] = useState(null)
   const [edit, setEdit] = useState(false)
   const [isFetching, setIsFetching] = useState(false)
   const [customerEmail, setCustomerEmail] = useState(customer && customer.profile && customer.profile.email || JSON.parse(Cookies.get("profile")).email)
   const formRef = useRef(null)
   const editHandler = () => {
      setEdit(true)
   }

   const billingChangedHandler = (e) => {
      if (e.target.checked) {
         setBilling(prevState => ({
            customerId: prevState.customerId,
            customerMailingId: prevState.customerMailingId,
            address1: shipping.address1,
            city: shipping.city,
            state: shipping.state,
            country: shipping.country,
            zipCode: shipping.zipCode,
          
          }))


      }
   }
   
   const loadProfile = ()=>{
      if (isFetching) return
      let customerId = localStorage.getItem("customerId") || (customer && customer.profile && customer.profile.customerId) || null

      if (!customerId) return
      setIsFetching(true)


      get("/customer/profile", ({data}) => {
        setProfile(data)
      }, 
      () => {
         console.log("error")
      }
      )


      get("/customermailing/customer/" + customerId, 
      ({data}) =>{
         setBilling(data)
         setIsFetching(false)

      },
       () => {
         setIsFetching(false)

       }
      )

      get("/customerShipping/customer/" +  customerId, ({data}) => {


         setShipping(data)
         setIsFetching(false)


      },
      () => {
         setIsFetching(false)

      }
      
      )


   }

   const updateProfile = async () => {

      const formData = new FormData();
      formData.append("fullName", profile.fullName)
      formData.append("dba", profile.dba)
      formData.append("contactName", profile.contactName)
      formData.append("title", profile.title)
      formData.append("officeNumber", profile.officeNumber)
      formData.append("faxNumber", profile.faxNumber || "")
      formData.append("mobileNumber", "") // must supply

      const serializeFormData = Object.fromEntries(formData)
      try {
         const savedToken = localStorage.getItem("token") || Cookies.get("token")
         const savedCustomerId = localStorage.getItem("customerId") || null
         let response = await api(savedToken).put("/customer/" + savedCustomerId, serializeFormData) 
         setProfile(response.data.data)
      } catch ({response}) {
         const errorMessage = (response && response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         console.log(errorMessage)
      }
   }

   const updateBilling = async () => {
      const formData = new FormData()
      formData.append("address1", billing.address1)
      formData.append("address2", "")
      formData.append("county", "")
      formData.append("province", "")
      formData.append("poBox", "")
      formData.append("state", billing.state)
      formData.append("city", billing.city)
      formData.append("zipCode", billing.zipCode)
      formData.append("country", billing.country) // must supply
      const serializeFormData = Object.fromEntries(formData)


      try {

         const savedToken = localStorage.getItem("token") || Cookies.get("token")
         let response = await api(savedToken).put("/customermailing/" + billing.customerMailingId, {...serializeFormData,
            customerId: billing.customerId || parseInt(localStorage.getItem("customerId"))
         }) 

         setBilling(response.data.data)
      } catch ({response}) {

         const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         console.log(errorMessage)
      }
   }

   const onProfileInputChanged = (e) => {
      setProfile(prevState => ({
         ...prevState,
         [e.target.name]: e.target.value
       
       }))
   }

   const onBillingInputChanged = (e) => {
      console.log(e.target.name, e.target.value)
      setBilling(prevState => ({
         ...prevState,
         [e.target.name]: e.target.value
       
       }))
   }

   const onSubmit = async (e) => {
      e.preventDefault()
      setBilling(null)

      updateBilling()
      updateProfile()

      setEdit(false)

   }

   useEffect(()=> {

      if (isEqual(previousShipping.current, shipping)) {
         return
      }
      loadProfile()
      gaPageview("/account", "Account - Information")

   },[customer]) // eslint-disable-next-line react-hooks/exhaustive-deps

   const previousShipping = useRef()
   useEffect(() => {
      previousShipping.current = shipping
   })
   return (
      <>
      
         {
            billing && shipping ?  <RxPlInformationCard> 
            {
               !edit && 
               <Button className="edit-button" onClick={editHandler}>
               <Icon type={IconRegular.EDIT} />Edit Profile
            </Button>
            }


            <RxPlInformationCard.Header>
               { profile && profile.fullName }
            </RxPlInformationCard.Header>

            {
               edit ? 
               <Form ref={formRef}>   
               <RxPlInformationCard.ItemWrapper>

                     <RxPlInformationCard.Item>
                        <RxPlInformationCard.ItemHeader>
                           Contact Information
                        </RxPlInformationCard.ItemHeader>
                           <Form.Field name="fullName" label="Pharmacy Name *" placeholder="Joseph’s Pharmacy" onChange={onProfileInputChanged} defaultValue={profile.fullName} />
                           <Form.Field name="dba" label="DBA *" placeholder="DBA223454" onChange={onProfileInputChanged} defaultValue={profile.dba} />
                           <Form.Field name="contactName" label="Main Contact *" placeholder="Jenny Jenkins" onChange={onProfileInputChanged} defaultValue={profile.contactName}/>
                           <Form.Field name="title" type="select" data={titles} label="Title *" placeholder="1234 Silver Springs Ave" onChange={onProfileInputChanged} value={profile.title}/>

                           <Form.Field name="officeNumber" type="tel" label="Phone *" placeholder="(555) 316-2222" onChange={onProfileInputChanged} defaultValue={profile.officeNumber}/>
                           <Form.Field name="faxNumber" type="tel" label="Fax" placeholder="(555) 316-2224" onChange={onProfileInputChanged} defaultValue={profile.faxNumber}/>
                           <Form.Field type="email" label="Email *" placeholder="jenny@josephspharmacy.com" value={customerEmail} readonly/>

                     </RxPlInformationCard.Item>

                     <RxPlInformationCard.Item>
                        <RxPlInformationCard.ItemHeader>
                           Shipping Address
                        </RxPlInformationCard.ItemHeader>
                        <Form.Field label="Street Address *" placeholder="1234 Silver Springs Ave" defaultValue={shipping.address1} readonly />
                        <Form.Field label="City *" placeholder="1234 Silver Springs Ave" defaultValue={shipping.city} readonly/>
                        <RxPlFormFieldWrapper>
                           <Form.Field label="State *" placeholder="1234 Silver Springs Ave" defaultValue={shipping.state} readonly/>
                           <Form.Field type="number" label="Zipcode *" placeholder="91800" defaultValue={shipping.zipCode} readonly/>   
                        </RxPlFormFieldWrapper>
                        <RxPlInformationCard.ItemHeader>
                           Billing Address
                        </RxPlInformationCard.ItemHeader>
                        <Form.Field id="same-shipping" name="same-shipping" type="checkbox" label="Billing Address is same as Shipping Address" onChange={billingChangedHandler} />   
                        <Form.Field name="address1" label="Street Address *" placeholder="1234 Silver Springs Ave" onChange={onBillingInputChanged} value={billing.address1}/>
                           <Form.Field name="city" label="City *" placeholder="1234 Silver Springs Ave" onChange={onBillingInputChanged} value={billing.city}/>
                           <RxPlFormFieldWrapper>
                              <Form.Field name="state" type="select" data={stateList} label="State *" placeholder="1234 Silver Springs Ave" onChange={onBillingInputChanged} value={billing.state}/>
                              <Form.Field name="zipCode" type="number" label="Zipcode *" placeholder="91800" onChange={onBillingInputChanged} value={billing.zipCode}/>   
                           </RxPlFormFieldWrapper>

                     </RxPlInformationCard.Item>

               </RxPlInformationCard.ItemWrapper>
               <RxPlFormButtonWrapper>
                  <Button type="outline">Cancel</Button>
                  <Button type="primary" onClick={onSubmit}>Save Changes</Button>
               </RxPlFormButtonWrapper>
            </Form> 
            :
            <RxPlInformationCard.ItemWrapper>

            <RxPlInformationCard.Item>
               <RxPlInformationCard.ItemHeader>
                   Contact Information
               </RxPlInformationCard.ItemHeader>
               {/* <p> {Object.keys(customer).length !== 0 ? customer.profile.contactName : "Contact Name"} <br/>
              {Object.keys(customer).length !== 0 ? customer.profile.title : "Title"}</p> */}
               
               <p>Main Contact: {profile && profile.contactName} <br/>
               Title: {profile && profile.title} <br/>
               Phone: {profile && profile.officeNumber} <br/>
               {profile && profile.faxNumber && profile.faxNumber !== "" ? <>Fax: {profile.faxNumber}<br/></>  : ``} 
               Email: {customerEmail}
               </p>
            </RxPlInformationCard.Item>

            <RxPlInformationCard.Item>
               <RxPlInformationCard.ItemHeader>
                  Shipping Address
               </RxPlInformationCard.ItemHeader>
               <p>{shipping.address1}<br/>
               {shipping.city} {shipping.state} {shipping.zipCode}
               </p>
               <RxPlInformationCard.ItemHeader>
                  Billing Address
               </RxPlInformationCard.ItemHeader>
               <p>{billing.address1}<br/>
               {billing.city} {billing.state} {billing.zipCode}
               </p>
            </RxPlInformationCard.Item>
         </RxPlInformationCard.ItemWrapper> 

            }

         </RxPlInformationCard>
         :

         <Spinner />
            
         }

      </>
   )
}


export default Information
