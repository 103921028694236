import {useContext, useRef, useEffect, useState} from 'react'
import Card from 'shared/components/Card'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import {RxPlSignupBase, RxPlFormLayout, RxPlFormGroup, RxPlFormContentCenterWrapper, RxPlFormButtonWrapper, RxPlFormGroupWrapper} from './style'
import useService from 'shared/hooks/useService'
import {PageContext} from 'shared/contexts/PageContext'

import {stateList} from 'shared/data/states-list'
import { gaPageview, gaSignUpProgress } from 'shared/utils/ga'

const Shipping = props => {
   const {customer, setCustomer} = useContext(PageContext)
   const form = useRef(null)
   const {api} = useService()
   const [stateValidity, setStateValidity] = useState(false)
   const [validZip, setValidZip] = useState(false)
   const [errorMesage, setErrorMessage] = useState("")
   const [zip, setZipCode] = useState("")
   const [address, setAddress] = useState("")
   const [city, setCity] = useState("")
   const [state, setState] = useState("")
   const [shippingId, setShippingId] = useState(null)
   const [fetching, setFetching] = useState(false)

   const createCustomerShipping = async () => {
      const formData = new FormData(form.current)
      const serializeFormData = Object.fromEntries(formData)


      try {

         const savedToken = localStorage.getItem("token")
         const customerId = localStorage.getItem("customerId") || JSON.parse(localStorage.getItem("contact")).customerId || (customer.profile && customer.profile.customerId) 
         let response = shippingId ? 
            await api(savedToken).put("/CustomerShipping/" + shippingId, {...serializeFormData,
            customerId: parseInt(customerId)
            }) 
            : await api(savedToken).post("/CustomerShipping", {...serializeFormData,
         customerId: parseInt(customerId)
         })
         setCustomer({...customer,
            shipping: response.data
         })
         // localStorage.setItem('shipping', JSON.stringify(serializeFormData))
         localStorage.setItem('onboarding', 'billing')
        props.handleNextStep("billing")
        gaSignUpProgress('shipping', customerId)

      } catch ({response}) {
         const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         setErrorMessage(errorMessage)
         console.log("error", errorMesage)
      }
   }

   const getCustomerShipping = async(callback) => {
      if (fetching) return
      setFetching(true)
      const savedToken = localStorage.getItem("token")
      if (!savedToken) return
      const customerId = localStorage.getItem("customerId") || JSON.parse(localStorage.getItem("contact")).customerId || (customer.profile && customer.profile.customerId) 
      try {

         let response = await api(savedToken).get("/customershipping/customer/" + customerId)

         if (typeof callback === 'function') {
            callback(response.data.data)
         }

         setFetching(false)
      } catch ({response}) {
         setFetching(false)

         const errorMessage = (response && response.data && response.data.title) || "Something went wrong"
         if (response.status !== 400 && response.status !== 401 && response.status !== 404) {
            setErrorMessage(errorMessage)
         }


      }
   }


   const prevStepHandler = e => {
      props.handleNextStep("contact")

   }
   const nextStepHandler = e => {
      e.preventDefault()
      if (!validZip) {
         setErrorMessage("Please enter a valid ZIP Code.")
         return
      }

     createCustomerShipping()
   }

   const onStateChange = e => {
      const currentValue = e.target.value
      setState(currentValue)
      setStateValidity(currentValue !== "")
   }

   const onZipChanged = e => {
      if (e.target.value.length > 5) return
      setZipCode(e.target.value)
      setValidZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(e.target.value))
   }

   const onCityChanged = e => {
      setCity(e.target.value)
   }

   const onAddressChanged = e => {
      setAddress(e.target.value)
   }

   useEffect(()=>{
      let mounted = true
      const enterKeyListener = e => {
         if (e.code === "Enter" || e.code === "NumpadEnter" || e.keyCode === 13) {
            e.preventDefault()
            if (mounted) {
               if (!validZip) {
                  setErrorMessage("Please enter a valid ZIP Code.")
               } else {
                  createCustomerShipping()

               }
            }
         }
      }
      
      if (!shippingId) {
         getCustomerShipping((data)=> {

            setCustomer({...customer,
               shipping: data
            })

            setAddress(data.address1)
            setCity(data.city)
            setState(data.state)
            setZipCode(data.zipCode)
            setValidZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(data.zipCode))
            setStateValidity(data.state !== "")
            setShippingId(data.customerShippingId)
         })
   
      }
      gaPageview("/shipping", "Sign up - Shipping")

      document.addEventListener("keydown", enterKeyListener)
      return () => {
        document.removeEventListener("keydown", enterKeyListener);
        mounted = false
      }
   },[])


   return (
         <RxPlSignupBase>
            <RxPlSignupBase.Content>

               <RxPlFormLayout ref={form}>

                  {
                     errorMesage !== "" && <p className="error-message">Error: {errorMesage}</p>

                  }

                  <RxPlFormContentCenterWrapper>
                  <Card>
                     <p>* = Required field</p>
                     <RxPlFormGroup>
                        <label>Street Address *</label>
                        <Input type="text" name="address1" placeholder="1234 Silver Springs Ave" value={address} onChange={onAddressChanged} required minlength="4" />
                     </RxPlFormGroup>


                     <RxPlFormGroupWrapper>
                     <RxPlFormGroup>
                        <label>City *</label>
                        <Input type="text" name="city" placeholder="Los Angeles" required minlength="3" value={city} onChange={onCityChanged} />
                     </RxPlFormGroup>
                     <RxPlFormGroup>
                        <label>State *</label>
                        <Select className={`${stateValidity === true ? '' : 'invalid'}`} name="state" value={state} data={stateList} onChange={onStateChange} required />
                     </RxPlFormGroup>
                     </RxPlFormGroupWrapper>

                     <RxPlFormGroup>
                        <label>Zipcode *</label>
                        <Input name="zipCode" className={`${validZip === true ? '' : 'invalid'}`} placeholder="91800" pattern="(^\d{5}$)|(^\d{5}-\d{4}$" type="number" value={zip} onChange={onZipChanged} required />
                     </RxPlFormGroup>
                  </Card>  
                  </RxPlFormContentCenterWrapper>
                   <RxPlFormButtonWrapper>
                      <Button onClick={prevStepHandler} type="outline">Go to Previous Screen</Button>
                      <Button onClick={nextStepHandler} type="primary">Save and Continue</Button>
                   </RxPlFormButtonWrapper>
               </RxPlFormLayout>
             
            </RxPlSignupBase.Content>

         </RxPlSignupBase>
   )
}


export default Shipping