
import styled from 'styled-components'
import {color, font} from 'shared/theme'
import {RxPlLayout, device} from 'shared/layout'


export const RxPlSignupBase = styled(RxPlLayout)`


`
RxPlSignupBase.Content = styled(RxPlLayout.Content)`
      padding: 18px 0;
      .access-code-clipboard {
         position: fixed;
         top: -200px;
         left: 0;
      }

      p,
      h4 {
         text-align: center;
      }
   @media only screen and ${device.sm} {
      padding: 48px;

   }
`
export const RxPlSignupHeader = styled(RxPlLayout)`
   position: fixed;
   border-bottom: 1px solid ${color.grayLight};
   background: ${color.backgroundDefault};
   z-index: 2;
   top: 80px;

   @media only screen and ${device.sm} {
      top: 100px;
      min-height: unset;
   }
`

RxPlSignupHeader.Content = styled(RxPlLayout.Content)`
   padding-top: 40px;
   padding-bottom: 24px;
`
export const RxPlSignupLayout = styled(RxPlLayout)`
   padding-top: 120px; 
`
export const RxPlFormLayout = styled.form`
   padding: 8px 0 !important;
   margin-top: 60px;
   .error-message {
      font-size: 16px;
      padding: 0 16px;
      ${font.bold};
      color: ${color.error};
      margin-bottom: 8px;
   }

   .error-message-auth {
      font-size: 16px;
      padding: 0 16px;
      ${font.bold};
      color: ${color.error};
      max-width: 620px;
      padding: 0 48px;
      margin: 0 auto 16px;
      text-align: center;
   }

   .success-message-auth {
      font-size: 16px;
      padding: 0 16px;
      ${font.bold};
      color: ${color.success};
      max-width: 620px;
      padding: 0 48px;
      margin: 0 auto 16px;
      text-align: center;
   }

   .text-align-left {
      margin-top: 8px;
      text-align: left !important;
   }

   .resend-btn {
      color: ${color.sky};
   }

   @media only screen and ${device.sm} {
      padding: 48px;
   }
`
export const RxPlFormContentWrapper = styled.div`
   display: block;
   > div {
      width: 100%;
      margin-bottom: 32px;
   }

   h3 { 
    text-align: center;
    font-size: 24px;
   }

   .access-code {
      text-align: center !important;
      span {
        display: inline-block ;
        color: ${color.sky} !important;
        margin: 0 6px;
        font-size: 64px;
        line-height: 1em;
        border-bottom: 2px solid ${color.grayLight};
        min-width: 48px;
        min-height: 70px;
      }
   }

   @media only screen and ${device.sm} {
      display: flex;
      margin: 0 -38px;

      > div {
         flex-basis: 0;
         flex-grow: 1;
         margin: 0 38px;
         padding: 24px 48px;
         max-width: 920px;
         p:first-of-type {
            text-align: right;
         }
      }

      &.max-w620 {
         max-width: 620px !important;
         margin: 0 auto;

         p {
            text-align: center;

            &.text-align-right-reset {
               text-align: center !important;
            }
         }
      }

   }
`

export const RxPlFormGroupWrapper = styled.div`
   display: block;
   margin: 0 -30px 16px;


   > div {
         flex-basis: 0;
         flex-grow: 1;
         margin: 0 30px 16px;
      }

     @media only screen and ${device.sm} {
      display: flex;
      > div {
         margin: 0 30px;
      }
   }
`
export const RxPlFormButtonWrapper = styled.div`
   width: fit-content;
   margin: 48px auto 0;


   button {
      margin-bottom: 16px;

      &.access-code-button {
         width: 100%;
         max-width: unset;
      }
   }
   @media only screen and ${device.sm} {
      margin: 24px auto 0;
      button {
         width: 276px;
         margin: 0;
      }

      
      &.max-w620 {
         max-width: 620px !important;
         width: 100%;
         padding-left: 48px;
         padding-right: 48px;
         display: flex;
         gap: 24px;
         p {
            text-align: center;

            &.text-align-right-reset {
               text-align: center !important;
            }
         }
      }
   }

`

export const RxPlFormGroup = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 16px;

   label {
      margin-bottom: 16px;
      ${font.bold}

      span {
         ${font.regular}
      }
   }
   input {
      margin: 0;
      max-width: unset;
   }

  button {
    margin:  60px auto 16px;
    max-width: 276px;
    width: 100%;
  }

  .footnote {
    text-align: right !important;
    margin-top: 32px;
  }

  &.separator {
    padding-bottom: 64px;
    margin-bottom: 54px;
    border-bottom: 1px solid ${color.grayLight};
  }


`