
import {useContext} from 'react'
import Button from 'shared/components/Button'
import {RxPlCloseButton} from 'shared/layout'
import {RxPlSurvey} from './style'
import {PageContext} from 'shared/contexts/PageContext'
import closeIcon from 'assets/icons/close.svg'

const Survey = props => {
    const {
        setModal,
     }  = useContext(PageContext)

     const takeSurvey = () => {
        window.open("https://dvl7rbo7044.typeform.com/to/V3o9mDrs", "_blank")
     }
     
    return (
    <RxPlSurvey>
        <RxPlCloseButton onClick={ () => setModal({type: ""})}>
           <img src={closeIcon} alt="close icon" />
        </RxPlCloseButton>
        <h5>TAKE OUR SURVEY</h5>
        <p>Take this two-minute survey so we can help you find the products you're looking for.</p>
        <Button type="primary" onClick={takeSurvey}>LET US KNOW</Button>
     </RxPlSurvey>
    )
}


export default Survey