
import {useContext} from 'react'
import {RxPlUtility} from './style'
import {PageContext} from 'shared/contexts/PageContext'
import {RxPlCloseButton} from 'shared/layout'
import closeIcon from 'assets/icons/close.svg'

const TermsOfUse = () => {
    const {
        setModal,
     }  = useContext(PageContext)

    return (
        <RxPlUtility>
            <RxPlCloseButton onClick={ () => setModal({type: ""})}>
            <img src={closeIcon} alt="close icon" />
            </RxPlCloseButton>
        <div className="wrapper">
            <h3>Terms of Use</h3>
            <p><strong>Last Updated: April 1, 2021</strong></p>
            <h4>Acceptance of the Terms</h4>
            <p>The following terms of use, (these “<strong>Terms</strong>y and between you and RxPlace, Inc. (“<strong>RxPlace</strong>,” “<strong>Company</strong>,” “<strong>we</strong>,” “<strong>us</strong>” or “<strong>our</strong>”).  These Terms govern your access to and use of our website, myrxplace.com (the “<strong>Site</strong>”) along with any other products or services offered by us, whether through the Site or otherwise (collectively and together with the Site, the “<strong>Services</strong>”).  </p>
            <p>Please read these Terms carefully. By accessing, browsing or otherwise using the Services or by clicking to accept or agree to the Terms when this option is made available to you, you (1) acknowledge that you have read and understood these Terms, (2) represent and warrant that you meet all of our eligibility requirements for using the Services as described in these Terms, and (3) accept and agree to be bound by these Terms, including any other terms applicable to the Services that are incorporated herein by reference. If you are using the Services on behalf of an entity, you are agreeing to these Terms for that entity and are representing to us that you have the authority to bind that entity to these Terms (in which case “<strong>you</strong>” will refer to that entity). If you do not accept these Terms or do not satisfy the eligibility requirements set forth below, you may not access or use the Services. </p>
            <h4>Privacy Policy</h4>
            <p>Your use of, and participation in, the Services offered by the Company is subject to the terms set forth in our privacy policy located at [website address for Privacy Policy] (the “Privacy Policy”). Our Privacy Policy details how we collect and use your information.</p>
            <h4>Changes to the Terms of Use</h4>
            <p>We reserve the right to update and revise these Terms at any time. We’ll make sure to also change the “Last Updated” date at the top of this page so you can tell if these Terms have changed since your last visit. Any such changes are effective immediately when we post them and apply to all access to and use of the Services thereafter. Please review these Terms regularly because once we post any changes, your continued use of the Services constitutes your acceptance of the revised Terms.</p>
            <h4>Access to Services; Account Registration</h4>
            <p>By accessing the Services, you warrant that: </p>
            <ol type="i">
                <li>You are legally capable of entering into binding contracts;</li>
                <li>All registration information you submit is truthful and accurate;</li>
                <li>You will maintain the accuracy of such information; and</li>
                <li>Your use of the Services does not violate any applicable law or regulation.</li>
            </ol>
            <p>To access our Services, you may be required to register with the Site and create a user account (“<strong>Account</strong>”). If you create an Account, you are solely responsible for any activity that occurs through your Account. In order for us to provide you the best possible service, you agree to provide us with complete, accurate, and updated information for your Account at all times. You agree that all information that you submit upon creation of your Account is accurate and truthful and you have the right to post the content on the Service and grant a license to RxPlace. If any information is incorrect or outdated, it can lead to errors or delays, for which we will not be responsible.</p>
            <p>You should not share your Account information. You should not use another person’s Account or registration information for the Services without permission. Similarly, no one else should be able to use your Account without permission. You are solely responsible for keeping your Account and Account password secure and for any consequence resulting from your failure to do so. You should never publish, distribute, or post login information for your Account.</p>
            <p>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms. You can always delete your Account by emailing us at <a href="mailto:info@myrxplace.com">info@myrxplace.com</a>.</p>
            <h4>Payment Processor; Third Party Service Provider</h4>
            <p>In the course of your use of the Services, third party payment service providers may receive and implement updated credit card information from your credit card issuer in order to prevent your payment or subscription from being interrupted by an outdated or invalid card. This disbursement of the updated credit card information is provided to third party payment service providers at the sole election of your credit card issuer. Your credit card issuer may give you the right to opt-out of the update service. Should you desire to do so, please contact your credit card issuer. </p>
            <p>Our obligation to provide the Services only comes into being when we take receipt of your purchase of the Services. Prices include local taxes. You agree not to hold us responsible for banking charges incurred due to payments on your account. You agree that you are not permitted to resell any Services for commercial purposes.</p>
            <h4>Payment Cancellation</h4>
            <p>All purchases are non-refundable. You may cancel your subscription at any time by contacting us using the contact information provided below. Your cancellation will take effect at the end of the current paid subscription term. </p>
            <h4>Changing Fees and Charges</h4>
            <p>We may at any time and from time to time, in our sole discretion, change the fees and charges, or add new fees and charges, in relation to any of the Services. We may also at any time and from time to time, in our sole discretion, change or remove any of the pricing models in place. </p>
            <h4>Cancellations by RxPlace</h4>
            <p>We may suspend or terminate your use of the Services as a result of your fraud or breach of any obligation under these Terms. Such termination or suspension may be immediate and without notice. A breach of these Terms includes, without limitation, the unauthorized copying or download of content from the Services. </p>
            <h4>Eligibility; User Restrictions</h4>
            <p>We only permit individuals who are at least 18 years old, or the age of majority in your province, territory or country, and who can form legally binding contracts with us to use the Services. Individuals under the age of 18, or the applicable age of majority, (“Minors”) may utilize the Services only with the consent and support of a parent, legal guardian or other qualified adult. If you are a parent or guardian and you allow your Minor to use the Services, you agree to be bound by the Minor’s use of the Services and by these Terms. If you are a Minor and do not have the requisite parent or guardian consent, please do not attempt to access or use the Services. </p>
            <p>You can only use or receive the Services to the extent the laws of your jurisdiction or the United States do not bar you from doing so. Please make sure these Terms are in compliance with all laws, rules and regulations that apply to you. You are solely responsible for ensuring compliance with the laws of your specific jurisdiction.</p>
            <h4>Use of the Services; Restrictions on Use</h4>
            <p>We may from time to time in our sole discretion develop and provide updates to the Services, change the Services, restrict access to the Services (including to registered users) or withdraw or terminate the Services entirely, and we reserve the right to do so in our sole discretion without notice.  Any such updates or changes will be deemed part of the Services and subject to all terms and conditions of these Terms. We will not be liable to you or any third party for any modification, suspension or discontinuance of the Services. In the event of modification or termination, you will still be bound by your obligations under these Terms, including the warranties made by you, and by the disclaimers and limitations of liability.</p>
            <p>You may only use the Services as explicitly authorized and in compliance with any policies made available to you within the Services.  You may not use such proprietary information or materials in any way whatsoever except for permitted use of the Services. No portion of the Services may be reproduced in any form or by any means. Without limiting the foregoing, you may not do any of the following while accessing or using the Services: </p>
            <ul>
                <li>Use the Services for any revenue generating endeavor, commercial enterprise, or other purpose other than for personal, non-commercial use, without our express written consent;</li>
                <li>Express or imply that any statements you make are endorsed by RxPlace;</li>
                <li>Scrape the Services or use other automated or manual means to take our content without our express prior written consent;</li>
                <li>Modify, adapt, translate, reverse engineer, decompile, disassemble or convert into human readable form any of the contents of the Services not intended to be so read;</li>
                <li>Take any action that imposes or may impose (as determined by us in our sole discretion) an unreasonable or disproportionately large load on our or our third party providers’ infrastructure;</li>
                <li>Interfere or attempt to interfere with the proper working of the Services or any activities conducted on the Services;</li>
                <li>Bypass, circumvent, or attempt to bypass or circumvent any measures we may use to prevent or restrict access to the Services, including without limitation other accounts, computer systems or networks connected to the Services;</li>
                <li>Run any form of auto-responder or “spam” on the Services; </li>
                <li>Access or use the Services for any illegal or unauthorized purpose, including to harass, abuse, defame or otherwise infringe or violate the rights of any other party; or</li>
                <li>Otherwise take any action in violation of these Terms.</li>
            </ul>
            <h4>Links to Third Party Websites</h4>
            <p>We may provide links to third party websites or services for you to access. You acknowledge that any access is at your sole discretion and for your information only. We do not review or endorse any of those websites or services. We are not responsible in any way for: (a) the availability of, (b) the privacy practices of, (c) the content, advertising, products, goods or other materials or resources on or available from, or (d) the use to which others make of these other websites and services. We are also not responsible for any damage, loss or offense caused or alleged to be caused by, or in connection with, the use of or reliance on such websites or services. </p>
            <h4>Intellectual Property</h4>
            <p>Except as otherwise expressly granted to you in these Terms, we reserve and retain all right, title and interest in the Services, including without limitation, all technology and processes, enhancements or modifications thereto, trademarks, service marks, site design, text, video, graphics, logos, images and icons, as well as the arrangement thereof. You acknowledge that the Services contain proprietary content, information and material protected by applicable intellectual property and other laws, including but not limited to copyright and trademark laws, and you agree that, except with our prior written consent or as explicitly provided in these Terms, using the Services does not (1) give you any ownership of any intellectual property rights in our Services or (2) grant you the right to display, modify, reproduce, distribute, create derivative works of, download, store, transmit or otherwise use any of our intellectual property.  Any unauthorized use of any content or materials on the Services is strictly prohibited and violates copyright, trademark, and/or other intellectual property laws, and/or the laws of privacy, publicity, and/or communications regulations and statutes.</p>
            <p>In particular, audio or video content from RxPlace not explicitly indicated as downloadable may not be downloaded or copied from the Services. You may not otherwise download, display, copy, reproduce, distribute, modify, perform, transfer, create derivative works from, sell or otherwise exploit any content, code, data or materials in the Services. If you make other use of the Services, or the content, code, data or materials thereon, except as otherwise provided, you may violate copyright and other laws of the United States, other countries, as well as applicable state laws and may be subject to liability for such unauthorized use. </p>
            <p>You may not access or use for any commercial purposes any part of the Site or any services or materials available through the Site. You acknowledge and agree that you do not acquire any ownership interest in the Services under these Terms, or any other rights thereto other than to use the Services in accordance with the license granted. Appropriate legal action may be taken for any illegal or unauthorized use of the Services. </p>
            <p>To inquire about obtaining authorization to use the materials or content other than as permitted in these Terms, please contact us at info@myrxplace.com.</p>
            <h4>Availability of the Services</h4>
            <p>Although we aim to offer you the best service possible, we make no promise that the Services will meet your requirements and we cannot guarantee that the Services will be fault free. If a fault occurs in our Services, please report it to us at info@myrxplace.com and we will review your complaint and, where we determine it appropriate to do so, correct the fault. We will not be liable to you if the Services are unavailable from time to time.</p>
            <p>Your access to the Services may be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or Services. We will restore the Services as soon as we reasonably can. </p>
            <h4>Third Party Materials and Content</h4>
            <p>You understand that the Services may display, include, or make available content, data, information, applications or materials from third parties or provide links to certain third party web sites (“<strong>Third Party Materials</strong>”).  In consideration for RxPlace allowing you to use the Services, you agree that we, our affiliates, and third party partners may place advertising on the Services. </p>
            <p>You acknowledge and agree that the Company is not responsible for examining or evaluating the content, accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect of such Third Party Materials. We expressly disclaim any responsibility for all aspects of the Third Party Materials and you further acknowledge and agree that Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, goods or services in connection with any Third Party Materials.</p>
            <p>Use of any third party trademarks or third party content on or in connection with the Services does not constitute affiliation with or endorsement of these third parties. Nothing in these Terms grants you any license to third party trademarks or content, which shall remain the property of their respective owners.</p>
            <h4>Warranty Disclaimers; Limitation of Liability</h4>
            <p>THE SERVICES ARE PROVIDED “AS IS” AND WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE COMPANY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, RELATING TO THE SERVICES OR ANY CONTENT ON THE SERVICES, WHETHER PROVIDED OR OWNED BY THE COMPANY OR BY ANY THIRD PARTY, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND ANY IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE IN TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. IN ADDITION, YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SERVICES AND THE COMPANY DOES NOT MAKE ANY REPRESENTATION OR WARRANTY THAT ANY OF THE SERVICES OR ANY CONTENT AVAILABLE THROUGH ANY OF THE SERVICES IS ACCURATE, COMPLETE, AVAILABLE, CURRENT, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR DEFECTS, OR THAT THE SERVICES WILL MEET YOUR REQUIREMENTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM COMPANY SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. </p>
            <p>IN NO EVENT WHATSOEVER SHALL THE COMPANY, ITS AFFILIATES, OR SUPPLIERS, OR THEIR RESPECTIVE OFFICERS, EMPLOYEES, SHAREHOLDERS, AGENTS, OR REPRESENTATIVES, BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, OR FOR ANY LOSS OF PROFITS OR REVENUE, INCLUDING BUT NOT LIMITED TO LOSS OF SALES, PROFIT, REVENUE, GOODWILL, OR DOWNTIME, (ARISING UNDER TORT, CONTRACT, OR OTHER LAW) REGARDLESS OF SUCH PARTY’S NEGLIGENCE OR WHETHER SUCH PARTY KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. YOU UNDERSTAND AND AGREE THAT THE DOWNLOAD OF ANY MATERIALS IN CONNECTION WITH THE SERVICES IS DONE AT YOUR DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY LOSS OR DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT MAY RESULT FROM THE DOWNLOAD OR UPLOAD OF ANY MATERIAL. COMPANY NEITHER ASSUMES, NOR DOES IT AUTHORIZE ANY OTHER PERSON TO ASSUME ON ITS BEHALF, ANY OTHER LIABILITY IN CONNECTION WITH THE PROVISION OF THE SERVICES. IF, NOTWITHSTANDING THE OTHER PROVISIONS OF THESE TERMS, COMPANY IS FOUND TO BE LIABLE TO YOU FOR ANY DAMAGE OR LOSS WHICH ARISES OUT OF OR IS IN ANY WAY CONNECTED WITH YOUR USE OF ANY SERVICES, COMPANY’S LIABILITY SHALL IN NO EVENT EXCEED THE GREATER OF (1) THE TOTAL OF ANY FEES PAID BY YOU TO COMPANY IN THE SIX (6) MONTHS PRIOR TO THE DATE THE CLAIM IS ASSERTED FOR ANY OF THE SERVICES OR FEATURE RELEVANT TO THE CLAIM, OR (2) US$500.00.</p>
            <p>THESE DISCLAIMERS AND LIMITATIONS OF LIABILITY ARE MADE TO THE FULLEST EXTENT PERMITTED BY LAW.</p>
            <h4>Indemnification</h4>
            <p>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Services or your use of any information obtained through the Services.</p>
            <h4>Governing Law</h4>
            <p>No matter where you’re located, the laws of the state of New York will govern these Terms and the relationship between you and the Company as if you signed these Terms in New York, without regard to New York state’s conflicts of laws rules. If any provisions of these Terms are inconsistent with any applicable law, those provisions will be superseded and/or modified only to the extent such provisions are inconsistent. The parties agree to submit to the federal or state courts in New York for exclusive jurisdiction of any dispute arising out of or related to your use of the Services or your breach of these Terms.</p>
            <h4>Arbitration</h4>
            <p>At our sole discretion, we may require you to submit any disputes arising from these Terms, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association.  The arbitration shall be seated in New York, New York. </p>
            <h4>Severability</h4>
            <p>If it turns out that any part of these Terms is invalid, void, or for any reason unenforceable, that term will be deemed severable and limited or eliminated to the minimum extent necessary. The limitation or elimination of the term will not affect any other terms.</p>
            <h4>Entire Agreement</h4>
            <p>These Terms constitute the entire agreement between you and the Company and supersede all prior or contemporaneous communications and proposals, whether electronic, oral, or written with respect to these Services. Any rights not expressly granted herein are reserved.</p>
            <h4>Force Majeure</h4>
            <p>We take our commitment to customers seriously, and we’ll do what we can for you. However, sometimes things may come up that are outside of our control. We will not be liable for any failure to perform any of our obligations stated in these Terms if the failure results from a cause beyond our reasonable control, including—without limitation—mechanical, electronic or communications failure or degradation, strikes or other labor disputes (whether or not relating to our workforce), restraints or delays affecting carriers, or our inability or delay in obtaining supplies of adequate or suitable materials.</p>
            <h4>Assignment</h4>
            <p>You cannot assign, transfer or sublicense these Terms without first obtaining our consent. We may assign, transfer, or delegate any of our rights and obligations without consent. These Terms do not create any agency, partnership, joint venture, or employment relationship, and neither party has any authority to bind the other in any respect.</p>
            <h4>Waiver</h4>
            <p>If we do not enforce any part of these Terms, it does not mean we give up the right to later enforce that or any other part of these Terms. In order for any waiver of compliance with these Terms to be binding, we must provide you with written notice of such waiver through one of our authorized representatives.</p>
            <h4>Release</h4>
            <p>You release the Company and our successors from all losses, damages, rights, and demands and actions of any kind, including personal injuries, death, and property damage, that are directly or indirectly related to or arise from your use of the Services (collectively, “Claims”). If you are a California resident, you hereby waive California Civil Code Section 1542, which states, “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his favor at the time of executing the release, which, if known by him would have materially affected his settlement with the debtor.” This release does not apply to any Claims for unconscionable commercial practice by the Company or fraud, deception, false, promise, misrepresentation or concealment, or suppression or omission of any material fact in connection with the Services.</p>
            <h4>Comments, Concerns and Complaints</h4>
            <p>All feedback, comments, requests for technical support and other communications relating to the Services should be directed to: <a href="mailto:info@myrxplace.com">info@myrxplace.com</a>.</p>
        </div>
    </RxPlUtility>
    )


}

export default TermsOfUse