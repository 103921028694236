import styled, {css} from 'styled-components'
import {color, font} from 'shared/theme'
import magnifierIcon from 'assets/icons/magnifier.svg'
import {device} from 'shared/layout'

export const RxPlInput = styled.input`
   color: ${color.grayPrimary};
   padding: 16px;
   border-radius: 4px;
   max-width: 784px;
   margin: 0 38px;
   width: ${props => props.width ? props.width : 'auto' };
   margin-left: ${props => props.alignRight ? 'auto' : '38px'};
   max-height: 52px;
   background: ${color.backgroundSearch};
   border: 1px solid ${color.grayLight};

   &:focus {
      outline: none;
   }

   &:invalid {
      border: 1px solid ${color.error};
   }

   &.valid {
      border: 1px solid ${color.success};
   }

   .error {
      color: ${color.error};
   }

   &.invalid {
      border: 1px solid ${color.error};

   }

   &::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

   ${ props =>
   props.isSearchInput &&
   css`
      background: url(${magnifierIcon}) no-repeat, ${color.backgroundSearch} ;
      background-size: 25px 25px;
      background-position: calc(100% - 16px) 50%;
      padding: 16px 48px 16px 16px;

      @media only screen and ${device.sm} {
         padding: 16px 48px 16px 32px;
    }
  `}

  @media only screen and ${device.sm} {
    padding: 16px 32px;


   }

`

export const RxPlInputFile = styled(RxPlInput)`
   position: relative;
   -webkit-appearance: none;
   text-align: left;
   -webkit-rtl-ordering:  left;
   padding: 16px 32px 16px 100px;
   min-height: 52px;
   &::-webkit-file-upload-button {
      visibility: hidden;
      position: absolute;
		-webkit-appearance: none;
      top: 0;
      right: 0;
		margin: 0 0 0 10px;
		border: 0;
		border-radius: 4px;
		background-image: -webkit-gradient(linear, left bottom, left top, from(#d2d0d0), to(#f0f0f0));
		background-image: -moz-linear-gradient(90deg, #d2d0d0 0%, #f0f0f0 100%);
	}

   &::before {
      content: 'Browse';
      min-width: 92px;
      position: absolute;
      display: flex;
      align-items: center;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 16px;
      min-height: 40px;
      color: ${color.white};
      background: ${color.sky};
      ${font.bold}
      border-radius: 4px;
   }
`
