import React from 'react'
import Icon from 'shared/components/Icon'
import Badge from 'shared/components/Badge'
import {Icon32} from 'shared/constants/icons'
import {RxPlToolbar} from './style'
import { useHistory} from 'react-router-dom';

const Toolbar = props => {
   const history = useHistory()

   return (
      <RxPlToolbar>
         <li onClick={() => history.push("/cart")} >
            <Icon type={Icon32.CART} />
            {
              props.badgeCount > 0 && <Badge count={props.badgeCount}/>
            }
         </li>
         <li onClick={() => history.push("/account")}> <Icon type={Icon32.PROFILE} /></li>
      </RxPlToolbar>
   )

}
export default Toolbar
