import styled from 'styled-components'
import Card from 'shared/components/Card'
import {color, font} from 'shared/theme'
import {RxPlLayout, device} from 'shared/layout'

export const RxPlSRLayout = styled(RxPlLayout)`
   background: ${color.backgroundDefault};
   h4 {
      margin: 32px 0 24px;
      color: #1d1d1d;
   }

   .align-right {
      display: block;
      margin-left: auto;
   }

   .empty-message {
      color: ${color.sky};
      text-align: center;
      margin-top: 32px;
      margin-bottom: 100px;
   }

   .other-results-header {
      display: flex;
      margin-top: 104px;
      margin-bottom: 32px;
      h4 {
         margin: 0px;
      }
      button {
         margin-left: auto;
         font-size: 18px;
         line-height: 24px;
         ${font.bold}
         padding: 8px 16px;
         box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
         border-radius: 4px;
      }
   }


`

export const RxPlResultCard = styled(Card)`
   position: relative;
   width: 100%;


   .sky-text {
      ${font.bold};
      color: ${color.sky};
   }
`

RxPlResultCard.Wrapper  = styled.div`
   display: block;
   > * {
      margin: 0 8px;
   }
 
   @media only screen and ${device.sm} {
      display: flex;
      margin: 0 -8px;
      align-items: center;
      padding: 32px 16px;      
   }
`

RxPlResultCard.Item  = styled.div`
   position: relative;
   flex-basis: 0;
   flex-grow: 1;
   color: #979797;
   height: max-content;
   h6 {
      color: black;
      margin-bottom: 0;
   }
   
   select {
      ${font.bold}
      padding: 8px 16px;
      background: ${color.backgroundDropdown};
      border: 0;
      margin: 0 auto;
      display: block;
      &:first-child {
         margin-bottom: 60px;
      }
      &.with-shadow {
         background-color: white;
         box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
         border: 1px solid #C1BBBB;
      }
   }  

   button {
      min-width: 160px;
      cursor: pointer;
      font-size: 16px;

      &.more-details {
         min-width: unset;
         font-size: 14px;
         line-height: 18px;
         border: 0;
         background: none;
         padding: 4px 0;
         color: #979797;

         i {
            margin-left: 8px;
            transform: translateY(2px);
         }
      }
      &:focus {
         outline: 0;
      }
   }

   &.flex {
      display: flex;


   }

   &.align-middle {
      align-items: center;
   }

   &.align-center {
      justify-content: center;
   }

   &.text-center {
      text-align: center;
   }
   &.product-image {
      flex-grow: 0;
      width: 240px;
      min-width: 240px;
      height: 160px;
      border: 1px solid black;
      margin-bottom: 16px;
      overflow: hidden;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         overflow: hidden;
      }

      @media only screen and ${device.sm} {
         margin-bottom: 0;
      }
   }

   &.product-info {
      flex-grow: 1;
      padding-left: 0;
      margin-bottom: 24px;
      h6 {
         font-size: 20px;
      }

      p {
         line-height: 24px;
         font-weight: bold;
      }

      @media only screen and ${device.sm} {
         padding-left: 18px;
         h6 {
         font-size: 20px;
      }
      }
   }


   &.product-price {
      h6 {
         text-align: center;
         margin-bottom: 10px;
         font-size: 24px;
         color: #12A35D;

      }

      select {
         border: 1px solid #C1BBBB;
         padding: 8px 60px 8px 16px;
         margin-bottom: 24px;
         option {
            text-align: center;
         }

         @media only screen and ${device.sm} {
            margin-bottom: 0;

         }
      }
   }
`

RxPlResultCard.Toast = styled.div`
   position: absolute;
   font-size: 14px;
   ${font.bold}
   line-height: 18px;
   width: 100%;
   top: 0;
   right: 0;
   max-width: 284px;
   border-radius: 4px;
   text-align: center;
   color: white;

   &.warm {
      background: ${color.warm};
   }

   &.warning {
      background: ${color.warning};
   }
`

export const RxPlFilters = styled.div`
   position: fixed;
   min-width: 300px;
   width: 50%;
   height: 100%;
   top: 0;
   right: 0;
   background: white;
   padding: 40px 80px;
   z-index: 1000;
   color: ${color.grayPrimary};
   transform: translateX(100%);
   transition: transform 0.25s;

   .cancel-btn {
      position: absolute;
      top: 40px;
      right: 40px;
   }

   hr {
      height: 1px;
      border: none;
      background: #e5e5e5;
      margin-top: 100px;
   }

   h4 {
      margin-top: 0;
      padding: 16px 0;
      border-bottom: 1px solid ${color.backgroundDropdown}
   }


   input[type='text'] {
      width: 380px;
      margin: 16px 0;
   }
   &.show {
      transform: translateX(0);

   }
`

RxPlFilters.Wrapper = styled.div`
   overflow: auto;
   height: 100%;
`

export const RxPlResultList = styled.div`

   .result-card {
      margin-bottom: 24px;

      &:last-child {
         margin-bottom: 0;
      }


   }
   &.collapse {
      display: none;
   }
`