
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'


const RxPlToast = styled.div`
   position: absolute;
   padding: 10px 48px;
   width: auto;
   font-size: 16px;
   background: #1F479E;
   color: white;
   border-radius: 6px;

   display: none;

   &.show {
       display: block;
       z-index: 1000;
   }


   &.center {
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
   }
`

const propTypes = {
    label: PropTypes.string,
    hidden: PropTypes.bool,
    position: PropTypes.string
 }
 
  const defaultProps = {
    label: "NDC copied",
    hidden: true,
    position: 'center'
  }

  
const Toast = props => {
    return (
        <RxPlToast className={`toast ${props.hidden ? '' : ' show'} ${props.position ? props.position : ''}`}>{props.label}</RxPlToast>
    )
}

Toast.propTypes = propTypes
Toast.defaultProps = defaultProps
export default Toast
 