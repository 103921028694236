import React, {useState, useReducer}  from 'react'
import { useHistory} from 'react-router-dom';
import { gaModal } from 'shared/utils/ga';
import { SHOW_MODAL, AUTHENTICATE, AUTHENTICATE_ADMIN, GOTO_PAGE } from './actions';
import pageReducer, {pageDefault} from './reducer'

export const PageContext = React.createContext({})

export const PageContextProvider = ({ children }) => {
   const history = useHistory()
   const [page, dispatch] = useReducer(pageReducer, pageDefault)
   const [debug, setDebug] = useState(false)
   const [customer, setCustomer] = useState({})
   const [resultTerm, setResultTerm] = useState("")
    const setModal = (modal) => {
      gaModal(modal.type)
      dispatch({
         type: SHOW_MODAL,
         modal
       })
    }

    const setAuthentication = (authenticated) => {
       dispatch({
          type: AUTHENTICATE,
          authenticated
       })
    }

    const setAdminAuthentication = (isAdmin) => {
      dispatch({
         type: AUTHENTICATE_ADMIN,
         isAdmin
      })
    }

    const setPage = (page) => {
      window.scrollTo(0, 0)

      dispatch({
         type: GOTO_PAGE,
         page
      }) 
      history.push("/" + page)

    }


   return (
      <PageContext.Provider value={
         {...page, 
            setModal, 
            setPage, 
            setAuthentication, 
            setAdminAuthentication,
            customer, setCustomer, 
            debug, setDebug,
            resultTerm, setResultTerm
         }
         }>{children}</PageContext.Provider>
    );
}
export default PageContextProvider