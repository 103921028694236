import {useState, useContext, useEffect} from 'react'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import {RxPlCloseButton} from 'shared/layout'
import {PageContext} from 'shared/contexts/PageContext'

import {RxPlProductDetail} from './style'
import {ShippingOptions, QuantityOptions} from './mock'
import closeIcon from 'assets/icons/close.svg'
import productDetailImg from 'assets/images/product-detail.jpg'
import { useCart } from 'shared/hooks/useCart'

const ProductDetail = props => {

    const {
        add
     } = useCart()

     
    const {
        setModal
     }  = useContext(PageContext)

    const [data, setData] = useState({...props.data})

    const onPlaceHold = product => {
        add(product)
     }
  
     const onChangedQuantity = (e) => {
        let copiedData = Object.assign({}, data)
        copiedData.count = e.target.value
        setData(copiedData)
     }


     useEffect(()=> {
        setData(props.data)
     }, [props.data])
  
   //   {
   //    productId: product.productId,
   //    brandName: product.productMaster.brandName,
   //    ndc: product.productMaster.productCode,
   //    description: product.productMaster.productDescription.replace(/\s+/g, ' ').trim(),
   //    labeler: product.productMaster.labelerName,
   //    genericName: product.productMaster.genericName,
   //    shortName: product.provider.shortName,
   //    quantity: product.quantity,
   //    price: Math.round((product.price + Number.EPSILON) * 100) / 100,
   //    shippingOptions: product.shippingOptions
   //   }
    return (
        <RxPlProductDetail>
        <RxPlCloseButton onClick={ () => { setModal({type: ""})}}>
           <img src={closeIcon} alt="close icon" />
        </RxPlCloseButton>
        <RxPlProductDetail.Wrapper>
           <RxPlProductDetail.Info>
              <RxPlProductDetail.Header>
                 <h5>{data.shortName || "name"}</h5>
                 <h5>${data.price || "cost"}</h5>
              </RxPlProductDetail.Header>
              <h6>{data.labeler || "manufacturer"}</h6>
              <p>NDC: {data.ndc || "ndc number"}</p>
              <p>QTY: {data.quantity || "qty"}</p>
              <h6>Description</h6>
              <p>{data.description || "description"}</p>
           
           </RxPlProductDetail.Info>
           <RxPlProductDetail.Image>
              <img src={productDetailImg} alt="product detail" />
           </RxPlProductDetail.Image>

        </RxPlProductDetail.Wrapper>
        <RxPlProductDetail.ControlsWrapper>
           <Select onChange={onChangedQuantity} data={QuantityOptions} />
           <Select data={ShippingOptions} />
           <Button onClick={() => onPlaceHold(data)} type="orange">Place Hold</Button>
        </RxPlProductDetail.ControlsWrapper>
     </RxPlProductDetail>

    )
}

export default ProductDetail