import React, {useContext, useState, useEffect, useRef} from 'react'
import Card from 'shared/components/Card'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import axios from 'axios'
import {RxPlSearch, RxPlResults} from './styles'
import useService from 'shared/hooks/useService'
import {PageContext} from 'shared/contexts/PageContext'
import { useHistory} from 'react-router-dom'
import { isNDC } from 'shared/utils/'
import { ShippingOptions } from './mock'
import PropTypes from 'prop-types'
import { gaSearch } from 'shared/utils/ga'

const propTypes = {
   enableShippingOptions: PropTypes.bool,
}

const defaultProps = {
   enableShippingOptions: false
}

let request = null
  // /api/product/header/code/00004-0039-09/1/10
 // /api/product/header/search/SYRINGE  3/1/10
const Search = props => {
   const history = useHistory()
   const {setResultTerm } = useContext(PageContext)
   const [results, setResults] = useState([])
   const [noresult, setNoResult] = useState(false)
   const [isLoadMore, setIsLoadMore] = useState(false)
   const [isSearchString, setIsSearchString] = useState(false)
   const [searchTerm, setSearchTerm] = useState(props.term || "")
   const {get, sessionIsAvailable} = useService()
   const [isSearching, setSearching] = useState(false)
   const searchBtn = useRef(null)
   const searchInput = useRef(null)
   const clearBtn = useRef(null)
   const endpoint = useRef(null)
   const pageResultIndex = useRef(1)


   const search = (value) => {
      setIsLoadMore(false)
      if (isSearching) return
      setNoResult(false)

      if (request) {
         request.cancel("cancel pending request")
      }
      request = axios.CancelToken.source()

   
      if (value.length > 0) {
         endpoint.current = isNDC(value) ? '/product/header/code/' : '/product/header/search/brand/'
         setIsSearchString(endpoint.current === '/product/header/search/brand/')
         setSearching(true)

         const strippedDashValue = isNDC(value) ? value.replace(/-/g, '') : value

         get(endpoint.current + strippedDashValue + `/${pageResultIndex.current}/101`, 
            result => {
               if(result.data.length > 0) {
                  console.log(result.data)
                  const allResult = result.data.map (element => {
                     return {
                        productId: element.productId, 
                        productUID: element.productUID,
                        productCode: element.productCode, //|| element.genericCode, 
                        genericName: element.genericName,
                        labelerName: element.labelerName,
                        brandName: element.brandName ? element.brandName : element.genericName,
                       productDescription: `${element.productCode} - ${element.brandName || element.genericName} - ${element.quantity} - ${element.labelerName} - ${element.dosageForm} - ${element.dose}`,
                        text: isNDC(value) ? `${element.productCode} - ${element.brandName || element.genericName} - ${element.quantity} - ${element.labelerName} - ${element.dosageForm} - ${element.dose}` : `${element.completeBrand}`,
                        resultType: element.resultType || 0
                     } })

                  setResults(allResult)
                  setSearching(false)
                  if (result.data.length > 100) {
                     pageResultIndex.current = pageResultIndex.current + 1
                     setIsLoadMore(true)
                  } else {
                     setIsLoadMore(false)
                     pageResultIndex.current = 1
                  }

               } else {
                  setResults([])
                  setNoResult(true)
                  setSearching(false)
               }
            },
            error => { 
               console.log(error)

               if (request) {
                  request.cancel("cancel pending request")
               }
            },
            request.token
         )
         setSearching(true)

      }else {
         // source.cancel()
         setResults([])
         setSearching(false)
         setIsLoadMore(false)
         pageResultIndex.current = 0
      }
   }

   const cancelSearch = () => {

      if (request) {
         request.cancel("cancel pending request")
      }

      setResults([])
      setSearchTerm("")
      setSearching(false)
      setNoResult(false)

   }

   const loadMore = () => {
      if (isSearching) return
      setNoResult(false)

      if (request) {
         request.cancel("cancel pending request")
      }
      request = axios.CancelToken.source()

   
      if (searchTerm.length > 0) {
         setSearching(true)
         
         get(endpoint.current + searchTerm + `/${pageResultIndex.current}/101`, 
            result => {
               if(result.data.length > 0) {
                  const allResult = result.data.map (element => {
                     return {
                        productId: element.productId, 
                        productUID: element.productUID,
                        productCode: element.productCode, //|| element.genericCode, 
                        genericName: element.genericName,
                        labelerName: element.labelerName,
                        brandName: element.brandName ? element.brandName : element.genericName,
                       productDescription: `${element.productCode} - ${element.brandName || element.genericName} - ${element.quantity} - ${element.labelerName} - ${element.dosageForm} ${element.dose}`,
                        text: isNDC(searchTerm) ? `${element.productCode} - ${element.brandName || element.genericName} - ${element.quantity} - ${element.labelerName} - ${element.dosageForm} ${element.dose}` : `${element.completeBrand}`,
                        resultType: element.resultType || 0
                     } })


                  var [first, ...rest] = results

                  setResults([...rest, ...allResult])
                  setSearching(false)


                  if (result.data.length > 100) {
                     pageResultIndex.current = pageResultIndex.current + 1
                     setIsLoadMore(true)
                  } else {
                     setIsLoadMore(false)
                     pageResultIndex.current = 1
                  }


               } else {
                  setResults([])
                  setNoResult(true)
                  setSearching(false)
               }
            },
            error => { 
               console.log(error)

               if (request) {
                  request.cancel("cancel pending request")
               }
            },
            request.token
         )
         setSearching(true)

      }else {
         // source.cancel()
         setResults([])
         setSearching(false)
         setIsLoadMore(false)
         pageResultIndex.current = 0
      }
   }

   const handleSearchSubmit = e => {
      setResults([])
      if (searchTerm.length === 0) return
      search(searchTerm)
   }

   const handleSearchEnter = e => {
      setResults([])

      if (searchTerm.length === 0) return
      if (e.code === "Enter" || e.code === "NumpadEnter" || e.keyCode === 13) {
         search(searchTerm)
      }

   }

   const handleSearchChange = e => {
      setNoResult(false)
      if (e.target.value === "" || e.target.value.length === 0) {
         cancelSearch()
      }
      setSearchTerm(e.target.value)
   }


   const gotoSearchResults = (res) => {
      if (!res.productCode) return
      window.scrollTo(0,0)

      setSearchTerm(res.text)
      setResultTerm(res.text)
      setResults([])


      // setPage('search-results')
      gaSearch(res.productCode)
      history.push({
         pathname: '/results',
         search: `${isSearchString ? 'gencode=' : '?code='}${res.productCode}${isSearchString ? '&term=' + searchTerm : ''}` 
      });
   }
   const clearSearchTerm = () => {
      setResults([])
      setSearchTerm("")
      setSearching(false)
      setNoResult(false)
   }

   useEffect(() => {
      if (!sessionIsAvailable()) {
         history.push("/")
      }
   }, []) 


   return (

   <RxPlSearch className="search-input">
      {
         searchTerm.length > 0 && <Button ref={clearBtn} onClick={clearSearchTerm} type='clear' className="clear-btn" />

      }
         <Button ref={searchBtn} type="search" onClick={handleSearchSubmit} className="search-btn" disabled={searchTerm.length === 0 || isSearching ? 'disabled' : null} />
         <>
         <Input 
            ref={searchInput}
            type="text"
            value={searchTerm}
            placeholder={`${props.placeholder || 'Search'}`} 
            onChange={handleSearchChange}
            onKeyDown={handleSearchEnter}
            />
            {
               props.enableShippingOptions && 
               <Select className="shipping-options" data={ShippingOptions}/>
            }
         </>

            {
             isSearching ? 
            <RxPlResults>
            <Card className="results flex">
               <p>Searching...</p>
               <Button className="align-right" onClick={cancelSearch}>Cancel</Button>
            </Card>
            </RxPlResults>
         : 
         null
         }

         {
             noresult ? 
            <RxPlResults>
            <Card className="results flex">
            <p className="no-result">We’re sorry. No products found, please broaden your search.</p>
            </Card>
            </RxPlResults>
         : 
         null
         }


         {
            results.length > 0 && 
            <RxPlResults>
            <Card className="results">
               <ul>
               {
                  results.map( (res, index) => (
                     <li className={`${res.resultType === 2 ? 'not-priority' : '' }`} key={index} onClick={()=> gotoSearchResults(res)}>{res.text}</li>
                  ))
               }

               {
                   isLoadMore && 
                  <li className='load-more-results'>
                     {
                        isSearching ? 
                           <>
                             <p>Loading...</p> 
                             <Button className="align-right" onClick={cancelSearch}>Cancel</Button>

                           </>
                        : 
                        <Button onClick={() => loadMore()}>Load more results</Button>

                     }
                  </li>
               }

               </ul>

            </Card>
          </RxPlResults>
      }

   </RxPlSearch>


)}


Search.propTypes = propTypes
Search.defaultProps = defaultProps
export default Search
