import React, {useState} from 'react'
import Button from '../Button'
import {RxPlPagination} from './styles'

const Pagination = props => {
  const size = props.set ? Math.ceil(props.size/props.set) : props.size
  const list = [...Array(size)].map(x => 0)
  const [currentIndex, setCurrentIndex] = useState(0)

  const onIndexUpdate = (index) => {
    setCurrentIndex(index)
    props.onIndexUpdate(index)
  }

  const onPrev = () => {
    setCurrentIndex(currentIndex - 1)
    props.onIndexUpdate(currentIndex)
  }

  const onNext = () => {
    setCurrentIndex(currentIndex + 1)
    props.onIndexUpdate(currentIndex)

  }

  return (
    <RxPlPagination>
      <Button type="pagination_prev" onClick={onPrev} disabled={currentIndex === 0}></Button>

      {
         list && list.length >= 2 && list.map ( (item, index ) => <li className={`${currentIndex === index ? 'active' : ''}`} key={index} onClick={() => onIndexUpdate(index)}></li>)
      }


       <Button type="pagination_next" onClick={onNext} disabled={currentIndex >= size - 1}></Button>
    </RxPlPagination>
  )
  
}
export default Pagination
