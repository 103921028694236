import { useState } from 'react'
import Cookies from 'js-cookie'

export default function useAdminToken() {
  const getToken = () => {
    const tokenString = Cookies.get('admin_token') || null;
    return tokenString
  }
  const [token, setToken] = useState(getToken())


  const saveToken = (userToken, expiresIn) => {
    Cookies.set('admin_token', userToken, {expires: expiresIn})
    setToken(userToken.token)
  }

  return {
    setToken: saveToken,
    token
  }
}