import { GOTO_PAGE, SHOW_MODAL, AUTHENTICATE, AUTHENTICATE_ADMIN } from "./actions";


export const pageDefault = {
   modal: {type: ''},
   page: '',
   authenticated: false,
   isAdmin: false,
 };
 
 const pageReducer = (state, action) => {
   switch (action.type) {
      case GOTO_PAGE:
         return {
         ...state,
         page: action.page
         }
      case SHOW_MODAL:
         return {
            ...state,
            modal: action.modal,
         }
      case AUTHENTICATE: 
         return {
            ...state,
            authenticated: action.authenticated
         }
      case AUTHENTICATE_ADMIN:
         return {
            ...state,
            isAdmin: action.isAdmin
         }
     default:
       return state
   }
 };
 
 export default pageReducer;
 