

// ShippingOption.PropTypes = {
//   id: PropTypes.number,
//   description: PropTypes.string
// }

// Cart.PropTypes = {
//   fullName: PropTypes.string,
//   grossAmount: PropTypes.string,
//   list: PropTypes.arrayOf({
//     brandName: PropTypes.string,
//     cartDetailId: PropTypes.number,
//     cartId: PropTypes.number,
//     categoryCode: PropTypes.oneOf(["INV_REGULAR"]),
//     dose: PropTypes.string,
//     expirationDate: PropTypes.string,
//     genericName: PropTypes.string,
//     grossAmountStr: PropTypes.string,
//     inventoryId: PropTypes.number,
//     isLocked: PropTypes.bool,
//     labeler: PropTypes.string,
//     netAmount: PropTypes.string,
//     productCode: PropTypes.string,
//     productDescription: PropTypes.string,
//     productId: PropTypes.number,
//     productQuantity: PropTypes.string,
//     productUID: PropTypes.string,
//     quantity: PropTypes.number,
//     shortName: PropTypes.string,
//     wholesaler: PropTypes.string
//   }),
//   notes: PropTypes.string,
//   providerId: PropTypes.number,
//   providerUID: PropTypes.string,
//   shippingCost: PropTypes.string,
//   shippingOption: PropTypes.object
// }

export const defaulCartStates = {
  cart: {},
  shipping: {},
  totalItemsInCart: 0
}