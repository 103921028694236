
import {useContext, useState, useRef, useEffect, useCallback} from 'react'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import {RxPlCloseButton} from 'shared/layout'
import {RxPlSignin} from './style'
import {PageContext} from 'shared/contexts/PageContext'
import closeIcon from 'assets/icons/close.svg'
import Spinner from 'shared/components/Spinner'
import useToken from 'shared/hooks/useToken'
import useService from 'shared/hooks/useService'
import {AuthenticationType} from 'shared/constants/authentication'
import PropTypes from 'prop-types'
import { useHistory} from 'react-router-dom'
import { ModalType } from 'shared/constants/modal'
import { gaSignUp } from 'shared/utils/ga'


const propTypes = {
    type: PropTypes.oneOf([AuthenticationType.SIGNIN, AuthenticationType.LOADING]),
    data: PropTypes.object,
 }

 const defaultProps = {
    type: AuthenticationType.LOADING,
  }

  
const Authentication = props => {
    const history = useHistory()

    const [errorMessage, setErrorMessage] = useState("")
    const [progress, setProgress] = useState(AuthenticationType.SIGNIN)

    const {
        setAuthentication, 
        setModal,
     }  = useContext(PageContext)

     const form = useRef(null)
     const {api, getCustomer} = useService()
     const {setToken} = useToken()

    
     const signIn = useCallback( async ()=>{
        setErrorMessage("")
        const formData = new FormData(form.current)
        const serializeFormData = Object.fromEntries(formData)
        setProgress(AuthenticationType.LOADING)

        try {
           let response = await api().post("/auth/signin", serializeFormData)
           if (response.data.registrationIncomplete) {
            setModal({type: ""})
            const registrationState = response.data.registrationState === 0 ?  'contact' : response.data.registrationState
            localStorage.setItem('onboarding', registrationState) 

            setToken(response.data.idToken)
            if (response.data.customerId) {
                localStorage.setItem("customerId", response.data.customerId)         
            }

            localStorage.setItem("token", response.data.idToken)         
            history.push("/signup")
            return
           } 


           localStorage.clear()
           setToken(response.data.idToken, 1)


           getCustomer( 
           () => {
            setAuthentication(true)
            setModal({type: ""})
            gaSignUp(response.data.customerId)
           },
           error => {
           // setErrorMessage(error)
           setModal({type: ""})

            history.push("/signup")

           }
           )
        } catch (error) {
            const {response} = error
            setProgress(AuthenticationType.SIGNIN)

            if (response) {
                setErrorMessage((response.data && response.data.messages && response.data.messages.toString()) || response.data.title)
            } else {
                setErrorMessage("Network Error")

            }
        }
     },[api, history, getCustomer, setAuthentication, setModal, setToken])


     const onInputChange = e => {
        setErrorMessage("")
     }


     const onSignIn = e => {
        e.preventDefault()
        signIn()
     }

     const forgotPassword = () => {
        setModal({type: ModalType.FORGOT_PASSWORD})
     }

     useEffect(()=> {
        let mounted = true 
        const listener = e => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.preventDefault()
                if (mounted)
                {
                    signIn()

                }
            }
          };
          document.addEventListener("keydown", listener)
          return () => {
            document.removeEventListener("keydown", listener);
            mounted = false
          }
    

     }, [signIn]) // eslint-disable-next-line react-hooks/exhaustive-deps
    return (
        <form ref={form}>

            <RxPlSignin>
                {
                    progress === AuthenticationType.SIGNIN && 
                    <>
                    <RxPlCloseButton onClick={ () => setModal({type: ""})}>
                    <img src={closeIcon} alt="close icon" />
                </RxPlCloseButton>
                <p>Sign in</p>
                {
                    errorMessage !== "" && 
                    <div className="error-message"><p>{errorMessage}</p></div>
                }

                <>
                    <RxPlSignin.FormGroup>
                        <p>Email address</p>
                        <Input name="emailaddress" type="email" placeholder="Email" onChange={onInputChange} required />
                        </RxPlSignin.FormGroup>

                        <RxPlSignin.FormGroup>
                            <p>Password</p>
                            <Input name="password" type="password" placeholder="Password" onChange={onInputChange} pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required />
                    </RxPlSignin.FormGroup>
                </>

                


            <RxPlSignin.ButtonGroup>
            <Button onClick={onSignIn} type="primary">Sign in</Button>
            <Button className="sky-text" onClick={forgotPassword}>Forgot Password?</Button>


            </RxPlSignin.ButtonGroup>
             <p>By clicking Sign in, you agree to RxPlace’s Terms of Use and Privacy Policy. RxPlace may send you communications.</p>


                    </>

                    
                }

                {
                    progress === AuthenticationType.LOADING && 
                    <Spinner />
                }

            </RxPlSignin>
        </form>
    )
}

Authentication.propTypes = propTypes
Authentication.defaultProps = defaultProps


export default Authentication