import React from 'react'
import heroSrc from './hero.jpg'
import styled from 'styled-components'
import {device} from 'shared/layout'
import {font} from 'shared/theme'
const RxPlHero = styled.div`
  width: 100%;
  min-height: 220px;
  padding: 40px 0;

  background: url(${heroSrc}) no-repeat;
  background-size: cover;
  background-position: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
  h1 {
   font-size:  40px;
   line-height: 50px;
   color: white;
  }

  button {
   align-self: start;  
   min-width: 210px;
   font-weight: 600;
  }

  .hero-wrapper {
   max-width: 1440px;
   width: 100%;
   margin: 0 auto;
   padding: 0 24px;
  }

  a {
   align-self: start;  
   color: #317EFF;
   min-width: 210px;
   font-weight: 600px;
   padding: 10px 16px;
   background: white;
   border-radius: 6px;
   text-align: center;
   font-size: 28px;
   text-decoration: none;
   ${font.medium}
   margin-top: 16px;
   display: inline-block;
  }
  
  @media only screen and ${device.sm} {
      height: 420px;
      background-size: cover;
      background-position: center;

      h1 {
         font-size: 60px;
         line-height: 80px;
         color: white;
      }

      padding: 80px 100px;


      .hero-wrapper {
         padding: 0 48px;
      }
   }

   @media only screen and ${device.lg} {
      height: 600px;
   }
`
const Hero = () => {

   return (
      <RxPlHero>
         <div className='hero-wrapper'>
            <h1>Save time. <br/>
               Save money. <br/>
               Do less work.</h1>
            <a href="#signup">Join Waitlist</a>
         </div>

      </RxPlHero>
   )
}

export default Hero
