import styled from 'styled-components'
import {font, color} from 'shared/theme'
import {device} from 'shared/layout'

export const RxPlCartItem = styled.div`
   width: 100%;
   margin-bottom: 80px;
   position: relative;
   > * {
      margin: 0;
      flex-grow: 1;
      flex-basis: 0;
   }

   h6 {
      font-size: 20px;
   }

   button {
      padding: 0;
      position: absolute;
      top: -80px;
      right: 0;
   }

`

export const RxPlProductThumbnail = styled.figure`
   position: relative;
  width: 10px;
  min-width: 190px;
  height: 140px;
  margin: 0 0 16px 0;
  border: 1px solid black;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden ;
  img {
     width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
  }

  @media only screen and ${device.sm} {
    width: 190px;
    height: 140px;
    margin: 0 16px 0 0;

   }
`

RxPlProductThumbnail.Badge = styled.p`
   position: absolute;
   display: block;
   background: ${color.orange};
   color: white;
   width: 100%;
   bottom: 0;
   text-align: center;
   padding: 4px 0;
`



export const RxPlProductDescription = styled.div`
   color: ${color.grayMedium};
   position: relative;
   width: 100%;
   padding-right: 28px;
  h6 {
     color: ${color.primary};
     span {
        font-size: 16px;
        color: ${color.grayMedium};

     }
  }
   p {
      font-size: 16px;
      font-weight: bold;
   }

   i {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
   }

`
export const RxPlRow = styled.div`
   display: block;

   &.flex {
      display: flex !important;
   }
   @media only screen and ${device.sm} {
      display: flex;
   }
`
export const RxPlCartItemColumn = styled.div`
   margin-right: 32px;

   p {
      font-size: 16px;
   }
   input {
      min-width: unset;
      max-height: unset;
      padding: 8px;
      width: unset;
      margin: 0;
      width: 80px;
      text-align: center;
      ${font.bold}
   }
`
