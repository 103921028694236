import styled from 'styled-components'
import {device} from 'shared/layout'

export const RxPlToolbar = styled.ul`
   display: flex;
   padding-left: 8px;
   list-style-type: none;
   li {
      position: relative;
      margin: 0 4px;

   }

   @media only screen and ${device.sm} {
      padding-left: 16px;

      li {
         display: inline-block;
         cursor: pointer;
         margin: 0 10px;
      }
  }
`;