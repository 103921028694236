import styled, {css} from 'styled-components'
import {color, font} from 'shared/theme'
import {device} from 'shared/layout'
import magnifierIcon from 'assets/icons/magnifier.svg'
import magnifierIconActive from 'assets/icons/magnifier_active.svg'
import xIcon from 'assets/icons/x.svg';
import prevButton from "assets/icons/carousel_prev_arrow.svg"
import nextButton from "assets/icons/carousel_next_arrow.svg"

export const RxPlButton = styled.button`
  font-weight: normal;
  ${font.size(16)}
  outline: 0;
  border: 0;
  color: ${color.grayPrimary};
  padding: 8px;
  appearance: none;
  background: none;
  margin: 0 10px;
  cursor: pointer;

  ${
    props => props.type === 'secondary' && 
    css`
      font-weight: bold;
      outline: 0;
      border: 0;
      color: white;
      background: ${color.grayLight};
      padding: 16px;
      min-width: unset;
      width: 100%;
      border-radius: 6px;
      appearance: none;
      margin: 0 10px;

      &:hover {
      background: ${color.hover};
      }
    `
  }

  ${
    props => props.type === 'primary' && 
    css`
      font-weight: bold;
      outline: 0;
      border: 0;
      color: white;
      background: ${color.sky};
      padding: 16px;
      min-width: unset;
      width: 100%;
      border-radius: 6px;
      appearance: none;
      margin: 0 10px;

      &:hover {
      background: ${color.hover};
  }

  &:disabled {
    background: ${color.grayLight};
    cursor: default;
  }

  &:active {
    background: ${color.active};
  }

   @media only screen and ${device.sm} {
        padding: 16px;
        width: auto;
        min-width: 108px;
      }
    ` 
  }


  ${
    props => props.type === 'outline' && 
    css`
      padding: 16px;
      min-width: unset;
      border: 1px solid ${color.sky};
      color: ${color.sky};
      background: ${color.white};
      border-radius: 6px;
      width: 100%;
      &:hover {
        background: ${color.outlineHover};
      }
    
      &:disabled {
        color: ${color.grayLight};
        background: ${color.white};
        border: 1px solid ${color.grayLight};

      }
    
      &:active {
        background: ${color.outlineActive};
      }

      @media only screen and ${device.sm} {       
          width: auto;
         min-width: 108px;
      }
    ` 
  }

${
    props => props.type === 'orange' && 
    css`
      padding: 16px;
      min-width: 108px;
      border: none;
      color: ${color.white};
      background: ${color.orange};
      border-radius: 6px;

      &:hover {
        background: ${color.orange};
      }
    
    
      &:active {
        background: ${color.orange};
      }
    ` 
  }

${
    props => props.type === 'filter' && 
    css`
      border: none;
      ${font.bold}
      color: ${color.grayPrimary};
      background: ${color.backgroundDropdown};
      border-radius: 6px;
      padding: 12px 40px;
      &:hover {
        background: ${color.grayPrimary};
        color: ${color.white};
      }
    
    
      &:active {
        background: ${color.backgroundDropdown};
      }
    ` 
  }

${
    props => props.type === 'cancel' && 
    css`
      border: none;
      background: none;
      border-radius: 0;
      padding: 0;
      margin: 0;
      min-width: unset;
      &:hover {
        background: none;
      }
    
    
      &:active {
        background: none;
      }

      
    ` 
  }

${

    props => props.type === 'search' && 
    css`
        margin: 0;
        background: url(${magnifierIconActive}) no-repeat ;
        background-size: 24px 24px;
      padding: 16px;

      &:disabled {
        background: url(${magnifierIcon}) no-repeat;
        background-size: 24px 24px;
        padding: 16px;
        display: none;
      }
      &:active {
        background: url(${magnifierIconActive}) no-repeat ;
        background-size: 24px 24px;
        padding: 16px;
        display: block;
      }

      @media only screen and ${device.sm} {
        &:disabled {
          background: url(${magnifierIcon}) no-repeat;
          background-size: 24px 24px;
          padding: 16px;
          display: block;
        }

      }
    ` 
  }


${
  props => props.type === 'clear' && 
    css`
      background: url(${xIcon}) no-repeat;
      background-size: 24px 24px;
      padding: 16px;
      display: block;
      color: #BDBDBD;
    ` 
  }

${
  props => props.type === 'pagination_prev' && 
  css`
    width: 20px;
    height: 20px;
    background: url(${prevButton}) no-repeat ;

    &:disabled {
      visibility: hidden;
    }
  ` 
}

${
  props => props.type === 'pagination_next' && 
  css`
    width: 20px;
    height: 20px;
    background: url(${nextButton}) no-repeat ;

    &:disabled {
      visibility: hidden;
    }
  ` 
}

`
