
import {useState, useRef, useCallback, useEffect} from 'react'
import Button from 'shared/components/Button'
import Card from 'shared/components/Card'
import Input from 'shared/components/Input'
import Topbar from 'shared/components/Topbar'
import Footer from 'shared/components/Footer'
import Toast from 'shared/components/Toast'
import { usePage } from 'shared/hooks/usePage'
import { ModalType } from 'shared/constants/modal'
// import Spinner from 'shared/components/Spinner'
import useAdminToken from 'shared/hooks/useAdminToken'
import useService from 'shared/hooks/useService'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import {RxPlSignupBase, RxPlFormLayout, RxPlFormGroup, RxPlFormContentWrapper, RxPlFormButtonWrapper} from '../styles'
import {RxPlContent} from 'shared/layout'


const propTypes = {
    data: PropTypes.object,
 }

 const defaultProps = {
}

  
const AccessCode = props => {
    const history = useHistory()

    const {adminAPI} = useService()
    const {setModal} = usePage()
    const {token} = useAdminToken()
    const form = useRef(null)
    const textAreaRef = useRef(null)
    const [email, setEmail] = useState("")
    const [activationUID, setActivationUID] = useState("")
    const [accessCode, setAccessCode] = useState("     ")
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [alert, setAlert] = useState({hidden: true, label: ''})
    
    const onEmailChange = e => {
        setErrorMessage("")
        setEmail(e.target.value)
    }

    const generateAccessCode = useCallback ( async () => {
        const formData = new FormData(form.current)
        const serializeFormData = Object.fromEntries(formData)
        try {
           let response = await adminAPI().post("/ActivationCode", serializeFormData)
            setAccessCode(response.data.data.activationCode)
            setActivationUID(response.data.data.activationUID)
        } catch (error) {
            const {response} = error
            
            if (response && response.status && response.status === 401) {
              setModal({type: ModalType.ERROR , message: "Your session has expired."})
              history.push("/admin")
              return
           }

            if (response && response.data && response.data.messages) {
                setErrorMessage(response.data.messages.toString() || response.data.validations[9002] || "Something went wrong.")

            } else {
                setErrorMessage("Network Error")

            }
        }
    }, [])


    const sendEmail = useCallback ( async () => {
      const formData = new FormData(form.current)
      const serializeFormData = Object.fromEntries(formData)
      try {
          await adminAPI().post("/ActivationCode/notify", serializeFormData)
          setAlert({hidden: false, label: "Email Sent"})
          setTimeout(()=>{
            setAlert({hidden: true, label: ""})
          }, 1500 )
      } catch (error) {
          const {response} = error

          if (response && response.status && response.status === 401) {
            setModal({type: ModalType.ERROR , message: "Your session has expired."})
            history.push("/admin")
            return
         }
         
          if (response.status === 400) {
            setErrorMessage(response.data.title)
            return
          }

          if (response) {
              setErrorMessage(response.data.messages[0] || response.data.validations[9002] || "Something went wrong.")

          } else {
              setErrorMessage("Network Error")

          }
      }
  }, [])
    

    const getAccessCode = e => {
        e.preventDefault()
        generateAccessCode()
    }

    const onSendEmail = e => {
      e.preventDefault()
      sendEmail()
    }


    const accessCodeToList = () => {
      return accessCode.split("") || null
    }

    function copyTextToClipboard(e) {
      e.preventDefault()
      textAreaRef.current.focus();
      textAreaRef.current.select();
    
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
        setAlert({hidden: false, label: "Copied"})
        setTimeout(()=>{
          setAlert({hidden: true, label: ""})
        }, 1500 )
      } catch (err) {
        console.log('Oops, unable to copy');
      }
    
    }
    

    useEffect(()=>{

      if (token == null) {
          history.push('/admin')
      }


     },[email, activationUID])

    return (
      <>
        <Topbar type='admin'  />
        <Toast position="center" {...alert}/>
        <RxPlContent>
        <RxPlSignupBase>
            <RxPlSignupBase.Content>
            <h4>Pharmacy Onboarding</h4>
            <p>Access code request</p>
              <RxPlFormLayout ref={form}>

                  {
                    errorMessage !== "" && <p className="error-message-auth">Error: {errorMessage}</p>
                  }

                  {
                      successMessage !== "" && <p className="success-message-auth">{successMessage}</p>
                  }
                  <RxPlFormContentWrapper className="max-w620">

                    <Card>
                      <RxPlFormGroup className='separator'>
                      <label>Enter Pharmacy Email Address*</label>
                      <Input name="emailaddress" type="email" placeholder="Email" onChange={onEmailChange} required />
                      <Input name="activationUID" type="hidden" value={activationUID} />

                      </RxPlFormGroup>
                      <RxPlFormGroup>
                    <label>Pharmacy Access Code</label>

                    {
                      accessCodeToList().length && 
                      <>
                      <p className='access-code'>
                        {
                          accessCodeToList().map( (code, index) => <span key={index}>{code}</span> )
                        }
                      </p>
                      <p className='footnote'>* will be used as your account name</p>
                      </>
                    }
                    {/* <label>Password</label>
                    <Input name="password" type="password" placeholder="Password" onChange={onPasswordChange} pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required />
                    <Button onClick={onRegister} type="primary">Sign in</Button>
                    <p className="text-align-left">By clicking Sign in, you agree to RxPlace’s Terms of Use and Privacy Policy. RxPlace may send you communications.</p> */}

                    </RxPlFormGroup>


                    </Card> 

                      
                  </RxPlFormContentWrapper>
                  <RxPlFormButtonWrapper className='max-w620'>
                  <Button className="access-code-button" onClick={getAccessCode} type="primary">Get Access Code</Button>

                  </RxPlFormButtonWrapper>

                  <RxPlFormButtonWrapper className='max-w620'>
                  <Button className="copy-code" onClick={copyTextToClipboard} type="primary" disabled={accessCode.trim() === ""}>Copy Code</Button>
                  <Button className="send-code" onClick={onSendEmail} type="primary" disabled={accessCode.trim() === ""}>Send Email</Button>

                  </RxPlFormButtonWrapper>
              </RxPlFormLayout>
              <textarea readOnly ref={textAreaRef} className="access-code-clipboard" value={accessCode} />
            </RxPlSignupBase.Content>
        </RxPlSignupBase>
        </RxPlContent>
        <Footer logIn={true} />

      </>
    )
}

AccessCode.propTypes = propTypes
AccessCode.defaultProps = defaultProps


export default AccessCode