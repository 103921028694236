import React, {forwardRef} from 'react'
import {RxPlInput, RxPlInputFile} from './styles'
const Input = forwardRef( (props, ref) =>  {
   return (
      <>
      {
         props.type === 'file' ? <RxPlInputFile {...props} /> : <RxPlInput {...props} readOnly={props.readonly} ref={ref} />
      }
   </>
   )
})

export default Input
