import {useState, useEffect, useCallback} from 'react'
import Footer from 'shared/components/Footer'
import {RxPlHomeScreenLayout} from './style'
import Search from 'shared/components/Search'
import PropTypes from 'prop-types'
import Spinner from 'shared/components/Spinner'
import { Carousel } from 'shared/components/Carousel'
import { useCart } from 'shared/hooks/useCart'
import { usePage } from 'shared/hooks/usePage'
import useService from 'shared/hooks/useService'
import { setTotalCartItems } from 'shared/contexts/CartContext/reducer'
import { dataFormatter } from 'shared/utils'
import { ModalType } from 'shared/constants/modal'
import { gaAddToCart, gaPageview } from 'shared/utils/ga'
import axios from 'axios'

const propTypes = {
   withPicks: PropTypes.bool,
   profile: PropTypes.object
}

 const defaultProps = {
   withPicks: false,
   profile: {fullName: ""}
 }

 let request = null

const HomeScreen = props => {
   const {customer, setModal} = usePage()
   const {dispatch} = useCart()
   const [recentSearch, setRecentSearch] = useState({})
   const [picks, setPicks ] = useState({})
   const [loading, setLoading] = useState(true)
   const {api} = useService()

   const getUserViewByCategory = useCallback( async(category, callback)=> {
      setModal({type: ''})
      try {

         if (request) {
            request.cancel("cancel pending request")
         }

         request = axios.CancelToken.source()

         let response = await api().get("/UserViewCategory/" + category + "/15")


         category === 'RECENTLY_SEARCH' && setRecentSearch( response.data.data)
         category === 'OUR_PICKS' && setPicks( response.data.data)

         if (callback) {
            callback()
         }

      } catch ({response}) {
         const errorMessage = (response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Network Error"
         setModal({type: ModalType.ERROR, errorMessage})
      }
   },[recentSearch, picks])



   const addToCart = useCallback ( async (product) => {
    
      try {
          let response = await api().post("/cart", product)
          // get itemCountInCart
          gaAddToCart(product)
          dispatch(setTotalCartItems(response.data.data.itemCountInCart))
          
       } catch (error) {
           const {response} = error
         
           if (response && response.data) {
            console.log("ERROR" ,(response.data.messages && response.data.messages.toString()) || response.data.title || "Something went wrong")
             
           } else {
              console.log("network error")
              props.showError("Network or CORS errors")
    
           }
       }
    
    }, [])


   useEffect(() => {

      getUserViewByCategory('RECENTLY_SEARCH', () => {
         setLoading(false)
         getUserViewByCategory('OUR_PICKS')

      })
      
      gaPageview("/", "Home - Search")
      return () => {
         if (request) {
            request.cancel("cancel pending request")
         }
      }
   }, []) 

   return (
      <>
         <RxPlHomeScreenLayout>
            <RxPlHomeScreenLayout.Content>
            <h3>Welcome {customer.profile ? customer.profile.contactName : "Jimbo"}!</h3>
            <Search placeholder="Search by NDC, Product Name or Manufacturer" />

            {
            
               loading ? <Spinner className="spinner" /> : 
               recentSearch.length > 0 && 
               <Carousel 
                  key={0}
                  className="carousel-recently-search"
                  theme='blue'
                  title="Your Recently Search Products"
                  data={dataFormatter(recentSearch)} 
                  addToCart={addToCart}
                  />

            }

            {
               picks.length > 0 && 
               <Carousel 
                  key={1}
                  className="carousel-recently-search"
                  title="Our Picks For You"
                  addToCart={addToCart}
                  data={dataFormatter(picks)} />
            }



            </RxPlHomeScreenLayout.Content>

         </RxPlHomeScreenLayout>
         <Footer logIn={true} />

      </>
   )
}


 
HomeScreen.propTypes = propTypes
HomeScreen.defaultProps = defaultProps


export default HomeScreen