import React, {useState} from 'react'
import {RxPlSidebar} from './styles'
import Icon from 'shared/components/Icon'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { useHistory} from 'react-router-dom'
import { IconRegular } from 'shared/constants/icons'

const propTypes = {
   data: PropTypes.array,
   active: PropTypes.number,
}

 const defaultProps = {
   data: [],
   active: 0
 }

const Sidebar = props => {
   const [menuIsOpen, setMenuIsOpen] = useState(false)
   const history = useHistory()

   const handleClick = ({index, type}) => {
      if (type === 'logout') {
         Cookies.remove('token')
         Cookies.remove('profile')
         history.push('/')
         return
      }
      setMenuIsOpen(false)
      props.handleClick(index)
   }

   return(
      <RxPlSidebar {...props}>
      <RxPlSidebar.Header>
         <h5>Account</h5>

         {
            menuIsOpen && <Icon type={IconRegular.CLOSE} onClick={()=> setMenuIsOpen(prev => !prev)} />
         }

         {
            !menuIsOpen && <Icon type={IconRegular.MENU} onClick={()=> setMenuIsOpen(prev => !prev)} />
         }

      </RxPlSidebar.Header>

      <RxPlSidebar.Menu className={menuIsOpen ? 'open' : ''}>
         {
            props.data.map ( (item,index) => (
               <RxPlSidebar.MenuItem key={index} onClick={ () => handleClick({index, type: item.icon})} className={`${props.active === index ? 'active' : ''}`}>
                  <Icon type={item.icon} /> {item.title}
               </RxPlSidebar.MenuItem>
            ) )
         }

      </RxPlSidebar.Menu>
   </RxPlSidebar>
   )
}





Sidebar.propTypes = propTypes
Sidebar.defaultProps = defaultProps

export default Sidebar
