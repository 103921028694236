
import styled from 'styled-components'
import {color, font} from 'shared/theme'
import {RxPlLayout, device} from 'shared/layout'

export const RxPlHomeLayout = styled(RxPlLayout)`

   color: #1F479E;
   h3 {
      ${font.regular}
      margin-bottom: 32px;
   }

   ul li div {
      h4 {
         margin-top: 16px;
      }

      p {
         text-align: left;

      }
   }

   form {
      margin-top: 32px;
      input[type="email"] {
         background: none;
         border: none;
         padding-left: 0;
         border-radius: 0;
         width: 100%;
         max-width: 530px;
         margin-bottom: 16px;
         border-bottom: 1px solid ${color.grayPrimary};
      }

      input[type='submit'] {
         min-width: 160px;
         color: ${color.white};
         background: ${color.sky};
         border: 0;
      }

      input {
         margin: 0;
      }
   }

   
   [type="signup"] {
      position: absolute;
      padding: 48px !important;
      max-width: 94%;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
      min-height: 420px;
      input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
         color: #ccc !important;
         opacity: 1; /* Firefox */
       }

       input:-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: #ccc !important;
      }

      input::-ms-input-placeholder { /* Microsoft Edge */
         color: #ccc !important;
      }


      input {
         border: 0;
         appearance: none;
         border-radius: 0;
         background: none;
         border-bottom: 1px solid white;
         color: white;
         padding-left: 0;
         padding-right: 0;

         &:focus,
         &:focus-visible {
            appearance: none;
            outline: none;
            border-bottom: 1px solid white;

         }
      }

      button {
         width: 100%;
         margin-top: 60px;
         font-size: 28px;
      }

      .grecaptcha-badge {
         position: relative !important;
         bottom: auto !important;
         right: auto !important;
         transition: none !important;
         box-shadow: unset !important;
         border-radius: unset !important;
         iframe {
            width: 100% !important;
         }
      }

      @media only screen and ${device.sm} {
         max-width: 67%;
      }
   }


   &.footer {
      background: ${color.indigo};
      color: ${color.white};
      text-align: center;
      button {
         padding: 0;
         margin: 0;
         color: white;
         font-size: inherit;
    
      }

      p {
         font-size: 16px;
         text-align: center;
      }
   }

   &.survey {
      p {
         font-size: 20px;
         margin-bottom: 24px;
      }
     button {
        font-size: 22px;
        margin: 0;
         min-width: 220px;
     }
   }

   .rxplace-difference {
      padding-top: 600px;

      > h2,
      > p {
         width: 100%;
      }
   }


   @media only screen and ${device.sm} {
      h2 {
         margin-bottom: 56px;
      }

      .rxplace-difference {
         padding-top: 400px;

         > h2,
         > p {
         width: 60%;
      }
      }


      ul li div {
         h4 {
            margin-top: 0;
         }

         p {
            text-align: center;

         }
      }

      form {
         input[type="email"] {
            margin-left: 0;
            width: 100%;
            max-width: 530px;
            margin-right: 38px;
         }

         input[type='submit'] {
            margin-right: 0;

            min-width: 160px;
            color: ${color.white};
            background: ${color.sky};
            border: 0;
         }
      }

   }


`

export const RxPlBlueThemeLayout = styled(RxPlHomeLayout)`
   background: #2F80ED;
   color: ${color.white};
   min-height: unset;

   ul {
      gap: 48px;
      li {
         padding: 0;
         h5 {
            margin-bottom: 32px;
         }
         img {
            margin-bottom: 32px;
            width: 100%;
         }
      }
   }

   @media only screen and ${device.sm} {
      ul {
         li {
            flex-grow: 1;
            flex-basis: 0;
         }
      }
   }

`

export const RxPlHomeScreenLayout = styled(RxPlLayout)`
   background: ${color.backgroundDefault};

   h3 {
      color: ${color.sky};
      font-size:  40px;
      line-height: 48px;
      font-weight: 900px;
      text-align: center;
   }
   h4 {
      margin-bottom: 36px;
      font-weight: 500;
      text-align: center;

   }

   .search-input {
      margin: 0 auto;
   }

   .carousel-recently-search {
      margin-top: 90px;
   }
   .spinner {
      margin-top: 100px;
   }


   @media only screen and ${device.sm} {
      h3:first-of-type {
      font-size: 40px;
      line-height: 48px;
      font-weight: 500;
      color: ${color.sky};
   }
   }
`

RxPlHomeScreenLayout.Content = styled(RxPlHomeScreenLayout.Content)`
   min-height: calc(100vh - 210px);
   position: relative;


`


export const RxPlFormFieldWrapper = styled.div`
   display: block;
   margin: 0 -8px;
   > div {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 8px;
   }

   @media only screen and ${device.sm} {
      display: flex;
   }
`
   