export const QuantityOptions = [
   {
      label: "Quantity",
      value: ""
   },
   {
      label: "1",
      value: "1"
   },
   {
      label: "2",
      value: "2"
   },
   {
      label: "3",
      value: "3"
   },
   {
      label: "4",
      value: "4"
   },
   {
      label: "5",
      value: "5"
   },
   {
      label: "6",
      value: "6"
   },
   {
      label: "7",
      value: "7"
   },
   {
      label: "8",
      value: "8"
   },
   {
      label: "9",
      value: "9"
   },
   {
      label: "10",
      value: "10"
   }
]

export const ShippingOptions = [
   {
      label: "Select Shipping",
      value: ""
   },
   {
      label: "Option 1",
      value: "option1"
   },
   {
      label: "Option 2",
      value: "option2"
   }
]