import React, {forwardRef} from 'react'
import {RxPlTextarea} from './styles'
import PropTypes from 'prop-types'

const propTypes = {
   className: PropTypes.string,
   invalid: PropTypes.bool,
   rows: PropTypes.number,
   value: PropTypes.string,
   onChange: PropTypes.func,
 }

 const defaultProps = {
   className: undefined,
   invalid: false,
   rows: 4,
   value: undefined,
   onChange: () => {},
 }


const Textarea = forwardRef( (props, ref) => {

   return (
      <RxPlTextarea>
         <textarea {...props} ref={ref}></textarea>
      </RxPlTextarea>
   )
})




Textarea.propTypes = propTypes;
Textarea.defaultProps = defaultProps;
export default Textarea
