import { useContext } from 'react';
import { CartContext } from 'shared/contexts/CartContext';

export const useCart = () => {
   
    const ctx = useContext(CartContext)

    return {
        ...ctx
    }
}