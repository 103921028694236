import React from 'react'

import {RxPlResultList} from './styles'
import PropTypes from 'prop-types'

const propTypes = {
   collapse: PropTypes.bool,
}

 const defaultProps = {
    collapse: false
 }


const ResultList = props => {

   return (
       <RxPlResultList className={`${props.collapse === true ? 'collapse' : ''}`}>
           {props.children}
       </RxPlResultList>
   )

}

ResultList.propTypes = propTypes
ResultList.defaultProps = defaultProps


export default ResultList
