
import styled from 'styled-components'
import {RxPlLayout} from 'shared/layout'


export const RxPlUploaderLayout = styled(RxPlLayout)`
    min-height: calc(100vh - 210px);
`


RxPlUploaderLayout.Content = styled(RxPlLayout.Content)`
    display: flex;
    padding-top: 60px;
    .item-wrapper {
        flex-grow: 1;
        flex-basis: 0;
        form {
            select,
            input {
                margin: 0 0 32px;
                display: block;
            }

            select {
                min-width: 280px;
            }
            p {
                font-size: 16px;
                margin-bottom: 8px;
            }

            button {
                margin: 0;
            }
        }
    }


`