

import styled from 'styled-components'
import uploadIcon from 'assets/icons/icon_upload.png'

const RxUploading = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  text-align: center;
  min-width: 380px;
  color: #1F479E;

  h5,
  img {
    margin-bottom: 16px;
  }

  h5, 
  p {
    color: inherit;

  }
  p {
    margin-bottom : 0;
  }

`

const Uploading = props => {

    return (
    <RxUploading>
        <h5>Uploading Documents</h5>
        <img src={uploadIcon} alt="Upload Icon" />
        <p>Please wait while we securely upload your documents</p>
     </RxUploading>
    )
}


export default Uploading