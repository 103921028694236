import React, {useReducer, useRef}  from 'react'
import cartReducer from './reducer'
import {defaulCartStates} from './states'
import { ModalType } from 'shared/constants/modal'
import useService from 'shared/hooks/useService'
import axios from 'axios'
import moment from 'moment'
import {loadOriginal, 
   loadOptimized, 
   loadShippingOption, 
   reset,
   setTotalCartItems} from 'shared/contexts/CartContext/reducer'
import { usePage } from 'shared/hooks/usePage'

export const CartContext = React.createContext({
   state: defaulCartStates,
   dispatch: () => undefined
})

export const CartContextProvider = ({ children }) => {
   const {api, logout} = useService()
   const {setModal} = usePage()
   const [state, dispatch] = useReducer(cartReducer, defaulCartStates)
   const cancelTokenSource = useRef()

   const cartSummaryMapper = data => {
      const {totalGrossAmountStr, totalItemsInCart, totalShippingCostStr, totalNetAmountStr, savingsStr, cartUID } = data
      return {
         totalGrossAmountStr,
         totalNetAmountStr,
         totalShippingCostStr: totalShippingCostStr === "0.00" ? "FREE" : "$"+totalShippingCostStr,
         totalItemsInCart,
         savingsStr: savingsStr === "0.00" || savingsStr === "0" ? "0.00" : savingsStr.replace("-", ""),
         cartUID
      }
   }

   const cartItemsMapper = (data) => {
      const {cartDetails, cartProviderDetails, cartUID} = data
      const items =  cartProviderDetails.map ( (item) =>  (
         {
            fullName: item.provider.fullName,
            providerId: item.providerId,
            grossAmount: item.netAmountStr || "0",
            providerTotalGross: item.grossAmountStr || "0",
            providerUID: item.providerUID,
            shippingCost: item.shippingCostStr === "0.00" ? "FREE" : "$"+ item.shippingCostStr,
            notes: item.notes,
            shippingOption: {
               id: item.shippingOption.shippingId,
               description: item.shippingOption.shippingDescription,
            },
            list: []
         }
      )) 

      cartDetails.forEach( (item) => {
         
         items.forEach( wholesaler => {
            if (item.providerId === wholesaler.providerId) {

              wholesaler.list.push({
                  wholesaler: wholesaler.fullName,
                  cartId: item.cartId,
                  cartDetailId: item.cartDetailId,
                  categoryCode: item.categoryCode || null,
                  expirationDate: item.expirationDate ? moment(item.expirationDate).format("L") : null,
                  inventoryId: item.inventoryId,
                  brandName: item.productMaster.brandName || "",
                  genericName: item.productMaster.genericName || "Generic Name",
                  labeler: item.productMaster.labelerName || "Labaler",
                  dose: item.productMaster.dose,
                  imageFilename: item.productMaster.imageFilename ? "https://d1fvel84160jt0.cloudfront.net/" + item.productMaster.imageFilename + ".JPG" : null,
                  productQuantity: item.productMaster.quantity,
                  productDescription: item.productMaster.productDescription.replace(/\s+/g, ' ').trim(),
                  productCode: item.productCode,
                  productId: item.productId,
                  productUID: item.productUID,
                  grossAmountStr: item.grossAmountStr,
                  price: item.price,
                  priceStr: item.priceStr,
                  netAmount: item.netAmount,
                  shortName: item.provider.shortName || "",
                  isLocked: item.isLocked || false,
                  quantity: item.quantity
              })
            }
         })
      })

      return {
         cartUID,
         items
      }
   }


   const loadCart = async (onSuccess, onError, endpoint) => {

      const currentEndpoint = endpoint || ""
      try {
         cancelRequest()
         cancelTokenSource.current = axios.CancelToken.source();
         const cart = await api().get("/cart" + currentEndpoint,  {cancelToken: cancelTokenSource.current.token})
         if (typeof onSuccess === 'function') {
            const data = cart.data.data
            const cartData = cartItemsMapper(data)
            const cartSummary = cartSummaryMapper(data)
            if (currentEndpoint === "") {
               dispatch(loadOriginal({
                  cartUID: cartData.cartUID,
                  items: cartData.items || [],
                  summary: cartSummary
               }))
               dispatch(setTotalCartItems(data.totalItemsInCart))
               dispatch(loadShippingOption(data.shippingOption.shippingId))
            } else {
               dispatch(loadOptimized({
                  cartUID: cartData.cartUID,
                  items: cartData.items || [],
                  summary: cartSummary
               }))
            }
            onSuccess()

         }

         setModal({type: ""})
      } catch (error) {
        dispatch(reset())
         setModal({type: ""})
        if (error && error.response && error.response.status && error.response.status === 401) {
          setModal({type: ModalType.ERROR , message: "Your session has expired."})
          logout()
          return
        }

         if (typeof error.response === 'undefined') {
            cancelRequest()
         } 

        if (typeof onError === 'function' && error) {
          onError(error)
        }
      }
   }

   const savedNotes = async (data, onSuccess, onError) => {
      const {cartId, providerId, notes} = data
      try {
         cancelRequest()
         cancelTokenSource.current = axios.CancelToken.source();
         const response = await api().post("/cart/note", {
            cartId,
            providerId,
            notes
         }, {cancelToken: cancelTokenSource.current.token})

         if (typeof onSuccess === 'function') {
            onSuccess(response.data.data)

         }

         setModal({type: ""})
      } catch (error) {
        dispatch(reset())
         setModal({type: ""})
        if (error && error.response && error.response.status && error.response.status === 401) {
          setModal({type: ModalType.ERROR , message: "Your session has expired."})
          logout()
          return
        }

         if (typeof error.response === 'undefined') {
            cancelRequest()
         } 

        if (typeof onError === 'function' && error) {
          onError(error)
        }
      }
   }

   const cancelRequest = () => {
      if (cancelRequest.current) {
         cancelTokenSource.current.cancel();
      }
   }

  
   return (
      <CartContext.Provider value={{state, dispatch, 
         cartSummaryMapper, cartItemsMapper,
         cancelRequest, loadCart, savedNotes}}>
         {children}
      </CartContext.Provider>
    );
}
export default CartContextProvider