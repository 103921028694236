import React from 'react'
import Authentication from './Authentication'
import ProductDetail from './ProductDetail'
import UserAgreement from './UserAgreement'
import TermsOfUse from './TermsOfUse'
import PrivacyPolicy from './PrivacyPolicy'
import ForgotPassword from './ForgotPassword'
import Survey from './Survey'
import Error from './Error'
import Loading from './Loading'
import {RxPlModal} from 'shared/layout'
import {ModalType} from 'shared/constants/modal'
import PropTypes from 'prop-types'
import Uploading from './Uploading'
import TermsOfUseAuthenticated from './TermsOfUseAuthenticated'
import LicensorInformation from './LicensorInformation'

const propTypes = {
   type: PropTypes.oneOf(['', ModalType.PRODUCT_DETAIL, ModalType.LICENSOR_INFORMATION, ModalType.TERMS_OF_USE_AUTHENTICATED, ModalType.AUTHENTICATION, ModalType.USER_AGREEMENT, ModalType.TERMS_OF_USE, ModalType.PRIVACY_POLICY, ModalType.SURVEY, ModalType.ERROR, ModalType.LOADING, ModalType.FORGOT_PASSWORD, ModalType.RESET_PASSWORD]),
   data: PropTypes.object,

}

 const defaultProps = {
   type: '',
   data: undefined
 }

const Modal = props => {

return (
   <>

         <RxPlModal>
            {
               props.type === ModalType.AUTHENTICATION &&  <Authentication type={props.data.authenticationType} />
            }

            {
               props.type === ModalType.FORGOT_PASSWORD && <ForgotPassword onClose={props.onClose} />
            }


            {
               props.type === ModalType.PRODUCT_DETAIL && <ProductDetail data={props.data} />
  
            }

            {
               props.type === ModalType.USER_AGREEMENT && <UserAgreement onSubmit={props.data.onSubmit} onAgreementChange={props.data.onAgreementChange} agreement={props.data.agreement}/>

            }

            {
               props.type === ModalType.TERMS_OF_USE && <TermsOfUse />
            }

{
               props.type === ModalType.LICENSOR_INFORMATION && <LicensorInformation />
            }

            {
               props.type === ModalType.TERMS_OF_USE_AUTHENTICATED && <TermsOfUseAuthenticated />
            }

            {
               props.type === ModalType.PRIVACY_POLICY && <PrivacyPolicy />
            }

            {
               props.type === ModalType.SURVEY && <Survey />
            }

            {
               props.type === ModalType.ERROR && <Error message={props.message} onClose={props.onClose} />
            }

            {
               props.type === ModalType.LOADING && <Loading />
            }

            {
               props.type === ModalType.UPLOADING && <Uploading />
            }

         
         </RxPlModal>
         
   </>

)

}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
