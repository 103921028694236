
import Information from './Information'
import Licenses from './Licenses'
import {Icon24} from 'shared/constants/icons'

export const data = [
   {
      id: 0,
      title: 'Account Information',
      path: "information",
      icon: Icon24.USER,
      view: Information
   },
   // {
   //    id: 1,
   //    title: 'Order History',
   //    path: "order",
   //    icon: Icon24.BOX,
   //    view: Order
   // },
   {
      id: 1,
      title: 'Licenses',
      path: "licenses",
      icon: Icon24.DOC,
      view: Licenses
   },

   {
      id: 2,
      title: 'Logout',
      path: "",
      icon: Icon24.LOGOUT,
      view: null
   }

]


export const orderHistoryFilter = [
   {
      label: "Last 30 days",
      value: ""
   },
   {
      label: "Last 20 days",
      value: "option1"
   },
   {
      label: "Last 10 days",
      value: "option2"
   }
]