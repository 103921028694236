export const shippingOptions = [
   {
      label: "Shipping options",
      value: ""
   },
   {
      label: "2 day shipping",
      value: "option1"
   },
   {
      label: "Ground",
      value: "option2"
   }
]

export const qtyOptions = [
   {
      label: "0",
      value: ""
   },
   {
      label: "1",
      value: "1"
   },
   {
      label: "2",
      value: "2"
   },
   {
      label: "3",
      value: "3"
   }
]

export const productResults = {
      productId: "12345667",
      productUID: "",
      providerId: "",
      brandName: "Penicillin",
      ndc: "873957394",
      description: "test",
      labeler: "Hermz",
      genericName: "Hermz",
      shortName: "HERMS",
      quantity: 10,
      price: 89.93,
      shippingOptions:[
         {
            shippingCode: 'ff',
            shippingId: 'ff'
         }
      ]
}
