import {useContext, useEffect, useState} from 'react'
import Button from 'shared/components/Button'
import {RxPlSignupBase, RxPlFormButtonWrapper, RxPlReviewCard} from './style'
import profilePic from 'assets/images/profile_pic.png'
import {PageContext} from 'shared/contexts/PageContext'
import {formatPhoneNumber} from 'shared/utils'
import useService from 'shared/hooks/useService'
import moment from 'moment'
import Cookies from 'js-cookie'
import { gaPageview, gaSignin, gaSignUp } from 'shared/utils/ga'

const Review = props => {
   const {setAuthentication, setPage} = useContext(PageContext)
   const {api} = useService()
   const [customer, setCustomer] = useState({})

   const signInHandler = () => {
      setPage("")
      if (!Cookies.get('token') && localStorage.getItem("token")) {
         Cookies.set('token', localStorage.getItem("token"), {expires: 1})
      }

      const customerId = localStorage.getItem("customerId") 
      gaSignUp(customerId)

      localStorage.removeItem("contact")
      localStorage.removeItem("shipping")
      localStorage.removeItem("billing")
      localStorage.removeItem('deaLicense')
      localStorage.removeItem('stateLicense')
      localStorage.removeItem('onboarding')
      localStorage.removeItem('token')
      localStorage.removeItem("agreement")

      setAuthentication(true)
      gaSignin(customerId)
   }

   const prevStepHandler = e => {
      props.handleNextStep("license")
   }

   const downloadLicense = async (fileName) => {

      try {
         let response = await api().post("/DownloadLicense",  
            { fileName },
            {
               responseType: 'arraybuffer',
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/pdf',
                  'Authorization': `Bearer ${Cookies.get('token')}`,
               }
            }
         )

            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

      } catch (error) {
         console.log("error " ,error)
         // setErrorMessage(response.data.detail || response.data.title)

      }
   }

   

   const downloadHandler = (filename) => {
      downloadLicense(filename)
   }

   const getCustomerBilling = async (callback) => {
      

      try {
         const savedToken = localStorage.getItem("token")
         const customerId = localStorage.getItem("customerId") || JSON.parse(localStorage.getItem("contact")).customerId || (customer.profile && customer.profile.customerId) 

         let response = await api(savedToken).get("/customermailing/customer/" + customerId)
 
        if (typeof callback === 'function') {
         callback(response.data.data)
        }

      } catch ({response}) {

         const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         console.log(errorMessage)
         // setErrorMessage(errorMessage)
         // setFetching(false)


      }
   }

   const getCustomer = async(callback) => {
      try {
         const savedToken = localStorage.getItem("token")

         let response = await api(savedToken).get("/customer/profile")
         const {customerId, fullName, shortName, uid, contactName, dba, title, officeNumber, mobileNumber} = response.data.data
         const profile = {
            customerId,
            fullName,
            shortName,
            uid,
            contactName,
            dba,
            title,
            mobileNumber,
            officeNumber
         }

         if (typeof callback !== 'undefined') {
            callback(profile)
         }


      } catch ({response}) {
         const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         // setErrorMessage(errorMessage)
         console.log(errorMessage)
      }
   }

   const getCustomerShipping = async(callback) => {
      const savedToken = localStorage.getItem("token")
      const customerId = localStorage.getItem("customerId") || JSON.parse(localStorage.getItem("contact")).customerId || (customer.profile && customer.profile.customerId) 
      try {

         let response = await api(savedToken).get("/customershipping/customer/" + customerId)

         if (typeof callback === 'function') {
            callback(response.data.data)
         }

      } catch ({response}) {
         const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         console.log(errorMessage)
         // if (response.status !== 400 && response.status !== 401 && response.status !== 404) {
         //    setErrorMessage(errorMessage)
         // }

      }
   }

   const getLicenses = async(callback) => {

      const savedToken = localStorage.getItem("token")
      const customerId = localStorage.getItem("customerId") 
      try {
         const response = await api(savedToken).get("/customerlicense/customer/" + customerId)
         const deaLicense = response.data.data.filter ( item => item.licenseType === 'DEA' )[0]
         const stateLicense = response.data.data.filter ( item => item.licenseType === 'STATE')[0]

         if (typeof callback === 'function') callback({
            deaLicense,
            stateLicense
         })

      } catch ({response}) {
         const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         // setErrorMessage(errorMessage)
         console.log(errorMessage)

      }
   }


   useEffect(() => {

      getCustomer( data => {


         getCustomerBilling( billing => {
            const {address1, city, state, zipCode} = billing
            const billingObj = {
               address1,
               state,
               city,
               zipCode
            }
 

            getCustomerShipping( shipping => {
               const {address1, city, state, zipCode} = shipping
               const shippingObj = {
                  address1,
                  state,
                  city,
                  zipCode
               }

               getLicenses( (license) => {
                  setCustomer({
                     profile: {
                        ...data
                     },
                     mailing: {
                        ...billingObj
                     },
                     shipping: {
                        ...shippingObj
                     },
                     deaLicense: license.deaLicense,
                     stateLicense: license.stateLicense

                  })
               })




            })


         })

      })

      gaPageview("/review", "Sign up - Review")


   }, [])

   return (
         <RxPlSignupBase>
            <RxPlSignupBase.Content>
               <RxPlReviewCard>
                  <RxPlReviewCard.Item>
                     <RxPlReviewCard.Avatar>
                        <img src={profilePic} alt="Profile" />
                     </RxPlReviewCard.Avatar>
                     <RxPlReviewCard.ItemHeader>
                        Contact Information
                     </RxPlReviewCard.ItemHeader>
                     <p> {customer.profile ? customer.profile.fullName : "fullName"}
                        <br/>{customer.profile ? customer.profile.dba : "dba"}</p>

                     <p>Main Contact:  {customer.profile ? customer.profile.contactName : "contactName"} <br/>
                     Title: {customer.profile ? customer.profile.title : "title"} <br/>
                     Phone: {customer.profile ? formatPhoneNumber(customer.profile.officeNumber) : "officeNumber"} <br/>
                     {
                        customer.profile && customer.profile.faxNumber && 
                        <>
                           Fax: {customer.profile ? formatPhoneNumber(customer.profile.faxNumber) : "faxNumber"} <br/>
                        </>
                     }
                     Email: {customer.profile ? localStorage.getItem("email") || customer.profile.email : "email"}
                     </p>
                  </RxPlReviewCard.Item>

                  <RxPlReviewCard.Item>
                     <RxPlReviewCard.ItemHeader>
                        Shipping Address
                     </RxPlReviewCard.ItemHeader>
                     <p>{customer.shipping ? customer.shipping.address1 : "address1"} <br/>
                     {customer.shipping ? customer.shipping.city : "city"} {customer.shipping ? customer.shipping.state : "state"} {customer.shipping ? customer.shipping.zipCode : "zipcode"}
                     </p>

                     <RxPlReviewCard.ItemHeader>
                        Billing Address
                     </RxPlReviewCard.ItemHeader>
                     <p>{customer.mailing ? customer.mailing.address1 : "address"}<br/>
                     {customer.mailing ? customer.mailing.city : "city"} {customer.mailing ? customer.mailing.state : "state"} {customer.mailing ? customer.mailing.zipCode : "zipcode"}
                     </p>

                     <RxPlReviewCard.ItemHeader>
                     Licensing Numbers
                     </RxPlReviewCard.ItemHeader>
                     <p>DEA License Number <br/>

                     
                     <span className="license-text">{customer.deaLicense ? customer.deaLicense.licenseNumber : "licenseNumber"}</span> <span className="license-text">{customer.deaLicense ? moment(customer.deaLicense.expirationDate).format("L") : "expirationDate"}</span> {customer.deaLicense ? <Button className="download-license-btn" onClick={()=> { downloadHandler(customer.deaLicense.newFileName)}}>{customer.deaLicense.originalFileName}</Button> : 'DEA_licensenumber.pdf'}
                     </p>
                     <p>State Board of Pharmacy License Number <br/>
                     <span className="license-text">{customer.stateLicense ? customer.stateLicense.licenseNumber : "licenseNumber"}</span> <span className="license-text">{customer.stateLicense ? moment(customer.stateLicense.expirationDate).format("L") : "expirationDate"}</span> {customer.stateLicense ? <Button className="download-license-btn" onClick={()=> { downloadHandler(customer.stateLicense.newFileName)}}>{customer.stateLicense.originalFileName}</Button> : 'DEA_licensenumber.pdf'}
                     </p>
                  </RxPlReviewCard.Item>
               </RxPlReviewCard>
               <RxPlFormButtonWrapper>
                      <Button type="outline" onClick={prevStepHandler}>Go to Previous Screen</Button>
                      <Button onClick={signInHandler} type="primary">Finish Profile Setup</Button>
                   </RxPlFormButtonWrapper>        
            </RxPlSignupBase.Content>

         </RxPlSignupBase>
   )
}


export default Review