import styled from 'styled-components'
import Card from 'shared/components/Card'
import {color, font} from 'shared/theme'


export const RxCarouselItem = styled.div`
   width: 100%;
  padding: 0 11px;
  flex-shrink: 0;

  @media only screen and (min-width: 768px) {
      width: 33%;
  }

  @media only screen and (min-width: 960px) {
      width: 20%;

  }
  
`
RxCarouselItem.Card =  styled(Card)`
   width: 100%;
   height: 100%;
   position: relative;
   

   img {
      width: 100%;
      height: 100%;
      min-height: 100%;
      object-fit: cover;
      border: 1px solid #000;

   }

   h6 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0;
   }

   p {
      font-size: 16px;
      margin-bottom: 16px;
      line-height: 20px;
      padding-right: 32px;
   }

   .sky-text {
      ${font.bold};
      color: ${color.sky};
   
   }

   .product-info {
      position: relative;
   }

   .add-to-cart {
      font-size: 12px;
      margin: 16px auto 0;
      padding: 12px;
   }

`


RxCarouselItem.Thumbnail = styled.div`
   position: relative;
   margin-bottom: 16px;

`


RxCarouselItem.Badge = styled.p`
   position: absolute;
   display: block;
   background: ${color.orange};
   color: white;
   width: 100%;
   bottom: 0;
   text-align: center;
   padding: 4px 0;
`
