export const ActionType = {
  ADD: "ADD_CART",
  REMOVE: "REMOVE_CART",
  UPDATE: "UPDATE_CART",
  SAVE_NOTES: 'SAVE_NOTES',
  LOAD_ORIGINAL: "LOAD_ORGINAL_CART",
  LOAD_OPTIMIZED: "LOAD_OPTIMIZED_CART",
  LOAD_SHIPPING_LIST: "LOAD_SHIPPING_LIST",
  LOAD_SHIPPING_OPTION: "LOAD_SHIPPING_OPTION",
  SET_TOTAL_CART_ITEMS: "SET_TOTAL_CART_ITEMS",
  LOCK: "LOCK_CART",
  RESET: "RESET"
}