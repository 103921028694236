import { ActionType } from "./actions";



 export const cartReducer = (state, action) => {

   switch (action.type) {
      case ActionType.LOAD_ORIGINAL:
         return {...state, 
         cart: {
            ...state.cart,
            original: action.payload,
         }
      }
      case ActionType.LOAD_OPTIMIZED:
         return {...state, cart: {
            ...state.cart,
            optimized: action.payload
         }}
      case ActionType.LOAD_SHIPPING_LIST:
         return {
            ...state,
            shipping: {
               list: action.list
            }
         }
      case ActionType.SAVE_NOTES: 
         return {
            ...state,
            saved: action.saved
         }
      case ActionType.LOAD_SHIPPING_OPTION:
         return {
            ...state,
            shipping: {
               ...state.shipping,
               option: action.option
            }
         }
      case ActionType.SET_TOTAL_CART_ITEMS:
         return {...state, totalItemsInCart: action.total}
      case ActionType.ADD:
         return {
            data: [...state.data, action.product]
         }
      case ActionType.REMOVE:
         return state.data.filter((_, index) => index !== action.index)
      case ActionType.UPDATE:
         const index = state.data.findIndex( product => product.id === action.product.id)
         return state
      case ActionType.RESET:
         return {cart: {}, shipping: {}, totalItemsInCart: 0}
     default:
       return state
   }
 }

 export const loadOriginal = (payload) => ({
   type: ActionType.LOAD_ORIGINAL,
   payload
 })

 export const loadOptimized = (cart) => ({
   type: ActionType.LOAD_OPTIMIZED,
   payload: cart
 })

 export const loadShippingList = (list) => ({
    type: ActionType.LOAD_SHIPPING_LIST,
    list
 })

 export const loadShippingOption = (option) => ({
   type: ActionType.LOAD_SHIPPING_OPTION,
   option
})

export const setNotes = (saved) => ({
   type: ActionType.LOAD_SHIPPING_OPTION,
   saved
})


export const setTotalCartItems = (total) => ({
   type: ActionType.SET_TOTAL_CART_ITEMS,
   total
})

export const reset = () => ({
   type: ActionType.RESET
})


 export const add = (product) => ({
   type: ActionType.ADD,
   payload: product
 })

 export const remove = (product) => ({
   type: ActionType.REMOVE,
   payload: product
 })


 
 export default cartReducer
 