import { useState, useContext, useEffect} from 'react'
import Stepper from 'shared/components/Stepper'
import Topbar from 'shared/components/Topbar'
import Footer from 'shared/components/Footer'
import Modal from 'shared/components/Modal'
import {RxPlSignupHeader, RxPlSignupLayout} from './style'
import { useHistory} from 'react-router-dom';
import {RxPlContent} from 'shared/layout'
import {data, stepperData} from './mock'
import {PageContext} from 'shared/contexts/PageContext'
import { gaPageview } from 'shared/utils/ga'

const Signup = ({match}) => {
   const history = useHistory()
   const [step, setStep] = useState(data[match.params.step] || data["account"])
   const StepView = step.view
   const {modal} = useContext(PageContext)

   const gotoSignUpStep = (param) => {
      window.scrollTo(0, 0)
      history.push("/signup/" + param)
      setStep(data[param])
   }

   useEffect(()=>{

      if (localStorage.getItem("onboarding")) {
         const lastOnboardingState = localStorage.getItem("onboarding") === 'agreement' ? 'license' : localStorage.getItem("onboarding")
         gotoSignUpStep(lastOnboardingState)
      }

      gaPageview("/signup", "Signup")

   }, [])

   return (
      <>
         { modal.type !== "" && <Modal {...modal} /> }
         <Topbar type='onboarding'  />
         <RxPlContent>
            <RxPlSignupHeader>
               <RxPlSignupHeader.Content>
                  <Stepper data={stepperData} step={step.id} /> 
               </RxPlSignupHeader.Content>
            </RxPlSignupHeader>

            <RxPlSignupLayout>
               <RxPlSignupLayout.Content textAlign="center">
                  <h4>{step.title}</h4>
                  <p>{step.p}</p>
                  <StepView handleNextStep={gotoSignUpStep}  />
               </RxPlSignupLayout.Content>
            </RxPlSignupLayout>
         </RxPlContent>
         <Footer />

      </>

   )
}


export default Signup