import React, {useContext} from 'react'
import Button from 'shared/components/Button'
import {PageContext} from 'shared/contexts/PageContext'
import {ModalType} from 'shared/constants/modal'
import styled from 'styled-components'
import {color} from 'shared/theme'
import {device} from 'shared/layout'

const RxPlFooter = styled.div`
   width: 100%;
   height: 100px;
   background: ${color.indigo};
   color: ${color.white};
   display: flex;
   align-items: center;
   padding: 0 24px;
   a {
      color: white;
      text-decoration: none;
   }

   p { 
      line-height: 24px;
      margin: 0;
      span {
         font-size: 14px;
         line-height: 14px;
         &:nth-child(1),
         &:nth-child(2) {
            display: none;
         }
      }
   }

   button {
      margin: 0;
      padding: 0;
      color: white;
      text-decoration: none;

   }

   br {display: inline-block;}

   @media only screen and ${device.sm} {
      padding: 0 48px;
      height: 122px;
      justify-content: center;
         font-size: 16px;

      br {display: none;}

      p { 
      span {

         &:nth-child(1),
         &:nth-child(2) {
            display: inline-block;
         }
      }
   }
   }
`

const Footer = props => {
   const {setModal} = useContext(PageContext)

   const openModal = type => {
      setModal({type: type})
   }

   // useEffect( () => {
   //    setModal({type: ModalType.USER_AGREEMENT})

   // }, [])

   return (
      <RxPlFooter>
         <p>Copyright © 2023 RxPlace <span>-</span> All Rights Reserved. <span>|&nbsp;</span><Button onClick={()=>openModal(props.logIn ? ModalType.TERMS_OF_USE_AUTHENTICATED : ModalType.TERMS_OF_USE)} >Terms of Use</Button> <span>|</span> <Button onClick={()=>openModal(ModalType.PRIVACY_POLICY)}>Privacy Policy</Button>
            {
               props.logIn && 
               <>
                  &nbsp;<span>|</span> <Button onClick={()=>openModal(ModalType.LICENSOR_INFORMATION)}>Licensor Information</Button>

               </>
            }
         </p>
      </RxPlFooter>
   )
}



export default Footer