import styled from 'styled-components'
import {color} from 'shared/theme'
import {device} from 'shared/layout'

export const RxPlCard = styled.div`
   position: relative;
   width: fit-content;
   border-radius: 8px;
   padding: 16px;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
   background: white;
   
   p {
      font-size: unset;
      margin-bottom: 10px;
      &:last-child {
         margin-bottom:0;
      }
   }
`

export const RxPlCardSignup = styled(RxPlCard)`
   width: 100%;
   background: ${color.blue};
   color: white;
   border-radius: 20px;

   h3 {
      text-align: center;
   }


   @media only screen and ${device.sm} {
      padding-left: 0 !important;


   }

`

export const RxPlProductCard = styled(RxPlCard)`
   max-width: 308px;
`

RxPlProductCard.Manufacturer = styled.p`
   margin-top: 8px;
   font-size: 12px !important;
   line-height: 16px !important;
`

RxPlProductCard.Title = styled.p`
   font-size: 20px !important;
   color: ${color.grayMedium};
`

RxPlProductCard.Qty = styled.p`
   font-size: 14px !important;
   line-height: 18px !important;
`

RxPlProductCard.Price = styled.p`
   font-size: 18px !important;
`
