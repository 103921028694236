import Textarea from 'shared/components/Textarea'
import Button from 'shared/components/Button'
import {CartItem} from 'shared/components/Cart'
import {CheckoutProgress} from 'shared/constants/cart'
import PropTypes from 'prop-types';
 import { useEffect, useState } from 'react';
import {
      RxPlGroupCard,
      RxPlRow
   } from './styles'


   

const propTypes = {
   progress: PropTypes.oneOf([CheckoutProgress.REVIEW, CheckoutProgress.CONFIRMATION]),
   lockItems: PropTypes.bool
}

const defaultProps = {
   progress: CheckoutProgress.REVIEW,
   lockItems: false
}



const Group = props => {
   const [notes, setNotes] = useState(props.data.notes || "")

   const onUpdateQuantityHandler = (data) => {
      props.onUpdateQuantity({
         ...data,
         providerId: props.data.providerId,
         providerUID: props.data.providerUID,
         notes: props.data.notes
      })
    }

    const onCartLockHandler = data => {
      props.onCartLock({
         ...data,
         providerId: props.data.providerId,
         providerUID: props.data.providerUID,
         notes: props.data.notes
      })
    }

    const saveNotes = data => {
       if (notes.length === 0) return
      props.onSaveNotes({
         providerId: data.providerId,
         cartId: data.cartId,
         notes
       })
    }

    const onSaveNotesHandler = (e) => {
       e.preventDefault()
       if (!props.data.list || !props.data.list[0].cartId) return
       saveNotes({
         providerId: props.data.providerId,
         cartId: props.data.list[0].cartId,
         notes: props.data.notes
       })
    }

    const onKeyDown = e => {
      const key = e.code || e.keyCode || e.charCode 

      if (key === 8 || key === 46 ||  key === 67 || key === 'Backspace' || key === 'Delete') {
         setNotes ( prev => prev && typeof prev.slice === 'function' && prev.slice(0, -1))
      }

   }

    const onChange = e => {
      if (e.target.value.trim() === '' || e.target.value.length === 0 || e.target.value === '0') return
      setNotes(e.target.value)
   }

   useEffect(() => {
      setNotes(props.data.notes || "")
   }, [props.data.notes]) 
   return (
      <>
      {
         props.data.list.length && 
         <RxPlGroupCard className="group-cart">
         <h4>{props.data && props.data.fullName ? props.data.fullName : "Title"}</h4>
         {
            props.data.list.map ( (product, index) => 
               <CartItem key={index} 
                  data={product} 
                  isReset={props.isReset}
                  onUpdateQuantity={onUpdateQuantityHandler}
                  onDelete={props.onDelete} 
                  onCartLock={props.onCartLock && onCartLockHandler}
                  lock={props.lockItems} />
            )
         }

         {
            props.progress === CheckoutProgress.REVIEW && 
            <RxPlRow>
            <h6>Shipping method</h6>
            <p>{props.data && props.data.shippingOption && props.data.shippingOption.description ? props.data.shippingOption.description.toUpperCase() : ""}</p>
            <p className="price">{props.data.shippingCost}</p>
         </RxPlRow> 

         }

         {
            props.progress === CheckoutProgress.CONFIRMATION && 
            <RxPlRow>
               <div>
                  <h6>Order Note to  {props.data && props.data.fullName ? props.data.fullName : "Title"}</h6>
                  <p>{notes}</p>
               </div>
               <div className="align-right text-align-center">
                  <p>Wholesaler Total:</p>
                  <p className="wholesaler-text-total">${props.data.grossAmount ? props.data.grossAmount : "Amount"}</p>
               </div>
            </RxPlRow> 
         }

         {
            props.progress === CheckoutProgress.REVIEW && 
            <RxPlRow>
               <h6>Wholesaler Total:</h6>
               <p className="align-right wholesaler-text-total">${props.data.grossAmount ? props.data.grossAmount : "Amount"}</p>
            </RxPlRow> 
         }

         {
            props.progress === CheckoutProgress.REVIEW &&
            <>
               <h6>Add a note for {props.data && props.data.fullName ? props.data.fullName : "Title"}</h6>
               <Textarea name="note" onChange={onChange} onKeyDown={onKeyDown} value={notes}/>
               <Button className="save-btn" type="primary" onClick={onSaveNotesHandler} disabled={notes.length === 0}>Save</Button>
            </>
         }
      </RxPlGroupCard>
      }
      </>
   )
}
Group.propTypes = propTypes
Group.defaultProps = defaultProps

export default Group