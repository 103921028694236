import React from 'react' 
import {RxPlCheckbox} from './styles'
const Checkbox = props => {
   const {id, name, onChange, checked} = props
   const inputProps = {
      id,
      name,
      checked,
      onChange
   }
   return (
      <RxPlCheckbox className="checkbox">
         <input type="checkbox" {...inputProps} checked={checked} />
         <label htmlFor={props.id}>
            <div className="control">
               <div className="checkmark"></div>
            </div>
            <span>{props.label}</span>

         </label> 
      </RxPlCheckbox>

   )
}

export default Checkbox
