import React, {useEffect} from 'react'
import styled from 'styled-components'
import NormalizeStyles from './NormalizeStyles'
import BaseStyles from './BaseStyles'
import PageContextProvider from 'shared/contexts/PageContext'
import CartContextProvider from 'shared/contexts/CartContext'

// We're importing .css because @font-face in styled-components causes font files
// to be constantly re-requested from the server (which causes screen flicker)
// https://github.com/styled-components/styled-components/issues/1593
import './fonts.css';

const RxPlWrapper = styled.div`
   min-height: 100%;
`

const PageWrapper = props => {
   useEffect(() => {
      const handleResize = () => {
         let vh = window.innerHeight * 0.01;
         document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
      window.addEventListener("resize", handleResize);
 
   },[])
   return(
      <>
         <NormalizeStyles />
         <BaseStyles />
         <PageContextProvider>
            <RxPlWrapper id="main-wrapper">
               <CartContextProvider>
                  {props.children}
               </CartContextProvider>
            </RxPlWrapper>
         </PageContextProvider>


      </>
   )
}


export default PageWrapper