import { useState } from 'react'
import Cookies from 'js-cookie'

export default function useToken() {
  const getToken = () => {
    const tokenString = Cookies.get('token');
    return tokenString
  }
  const [token, setToken] = useState(getToken())


  const saveToken = (userToken, expiresIn) => {
    if (expiresIn) {
      Cookies.set('token', userToken, {expires: expiresIn})
    }
    setToken(userToken)
  }


  return {
    setToken: saveToken,
    token
  }
}