
import {RxPlUtility} from './style'
import {useContext} from 'react'
import {PageContext} from 'shared/contexts/PageContext'
import {RxPlCloseButton} from 'shared/layout'
import closeIcon from 'assets/icons/close.svg'

const PrivacyPolicy = () => {
    const {
        setModal,
     }  = useContext(PageContext)
    return (
        <RxPlUtility>
            <RxPlCloseButton onClick={ () => setModal({type: ""})}>
                <img src={closeIcon} alt="close icon" />
            </RxPlCloseButton>
            <div className="wrapper">
                <h3>Privacy Policy</h3>
                <p><strong>Last Updated: April 1, 2021</strong></p>
                <ul>
                    <li>Introduction</li>
                    <li>Information We Collect and How We Collect Information</li>
                    <li>Third-Party Information Collection</li>
                    <li>How We Use Your Information</li>
                    <li>Choices About How We Collect, Use and Disclose Your Information</li>
                    <li>Disclosure of Your Information</li>
                    <li>Social Networking Services</li>
                    <li>Links to Third-Party Sites and Services </li>
                    <li>Do Not Track Signals</li>
                    <li>Data Retention</li>
                    <li>Accessing and Correcting Your Information</li>
                    <li>Security</li>
                    <li>Children Under the Age of 13</li>
                    <li>International Users</li>
                    <li>Contact Information</li>
                </ul>

                <h4>Introduction</h4>
                <p>RxPlace, Inc. (“<strong>RxPlace</strong>,” “<strong>Company</strong>,” “<strong>we</strong>” or “<strong>us</strong>”) is committed to protecting your privacy. The following policy (“<strong>Privacy Policy</strong>”) describes our practices for collecting, using, maintaining, and disclosing information that we collect from you or that you provide to us when you visit our website (our “<strong>Site</strong>”), or use any other products and services made available by us (collectively with the Site, the “<strong>Services</strong>”).</p>
                <p>We ask that you read this Privacy Policy carefully to understand our policies and practices regarding your information and how we will treat it. The use of information collected through our Services will be limited to the purposes described under this Privacy Policy and our Terms of Service (or other relevant customer contracts). By accessing or using the Services, you agree to this Privacy Policy. Please be aware that if you are using the Services on behalf of an entity, you are agreeing to this Privacy Policy for that entity and are representing to us that you have the authority to do so (in which case “<strong>you</strong>” will refer to that entity). If you do not agree with our policies and practices, your choice is not to use the Services.</p>
                <p>This Privacy Policy may change from time to time. If we make any changes, we will post the changes on this page and indicate at the top of this page the date the policy was last revised. We will also notify you of any material changes either through a pop-up notice, email or through other reasonable means. Any such changes are effective immediately when we post them, and apply to all access to and use of the Services thereafter. Your continued use of the Services after the date any such changes become effective constitutes your acceptance of the new policy.</p>
                <h4>Information We Collect and How We Collect Information</h4>
                <h5>A. Information You Provide.</h5>
                <p>We may collect information you provide directly via the Services. We may ask you for some or all of the following types of information when you register with our Services, submit comments and other content, order products, or directly contact us with questions or feedback:</p>
                <ul>
                    <li>[Your name</li>
                    <li>Your telephone number</li>
                    <li>Your address</li>
                    <li>Your email address</li>
                    <li>Your company name</li>
                    <li>Your company address</li>
                    <li>Company fax number</li>
                    <li>User Name</li>
                    <li>Password</li>
                    <li>Credit card information?</li>
                    <li>Other contact or identifying information you choose to provide</li>
                    <li>Company certifications</li>
                    <li>Company Licenses</li>
                    <li>Price Files</li>
                    <li>Offers</li>
                    <li>Production information</li>
                    <li>Transaction information</li>
                    <li>Sales Data</li>
                    <li>Other information about your business and transactions you engage in or attempt to engage in on our site</li>
                </ul>
                <p>The information you provide may include Personal Information.  “<strong>Personal Information</strong>” is information that identifies you personally (whether alone or in combination). </p>
                <p>If you purchase our Services, you may be required to provide financial information, such as your credit card or bank account number, to a third-party payment processor. We do not collect or store such financial information and we are not responsible for the collection or storage of such information by third parties.  We will, however, have access to certain information associated with your account should you purchase the Services, including the amount and date of the purchase.</p>
                <p>If you purchase our Services, you may be required to provide financial information, such as your credit card or bank account number, to a third-party payment processor. We do not collect or store such financial information and we are not responsible for the collection or storage of such information by third parties.  We will, however, have access to certain information associated with your account should you purchase the Services, including the amount and date of the purchase.</p>
                <h5>B. Information We Automatically Collect</h5>
                <p>We may also collect certain information automatically when you visit the Services, including:</p>
                <ul>
                    <li>[Your browser type and operating system</li>
                    <li>Your Internet Protocol (IP) address, which is the number automatically assigned to your computer whenever you access the Internet and that can sometimes be used to derive your general geographic area</li>
                    <li>Geolocation information</li>
                    <li>Other unique identifiers, including mobile device identification numbers</li>
                    <li>Sites you visited before and after visiting the Services</li>
                    <li>Pages you view and links you click on within the Services</li>
                    <li>Information collected through cookies, web beacons, and other technologies</li>
                    <li>Information about your interactions with e-mail messages, such as the links clicked on and whether the messages were opened or forwarded</li>
                    <li>Standard Server Log Information</li>
                </ul>
                <p>We may collect the above information in a variety of ways, including by using “pixel tags,” “web beacons,” and “cookies.”</p>
                <ul>
                    <li>Cookies are small bits of information that are stored by your computer’s browser. You can decide if and how your computer will accept a cookie by configuring your preferences or options in your browser. However, if you choose to reject cookies, you may not be able to use certain online products, services or features on the Services.</li>
                    <li>Pixel tags are very small images or small pieces of data embedded in images, also known as “web beacons” or “clear GIFs,” that can recognize cookies, the time and date a page is viewed, a description of the page where the pixel tag is placed, and similar information from your computer or device.</li>
                </ul>
                <h4>Third-Party Information Collection</h4>
                <p>We may allow third parties to place and read their own cookies, web beacons, and other technologies to collect information through the Services.  For example, our third-party service providers may use these technologies to collect information (i) that helps us with traffic measurement, research, and analytics, and (ii) that is used to serve advertisements on our Services and across the Internet tailored to your apparent interests.</p>
                <p>The information collected and stored by third parties remains subject to their policies and practices. We do not control these third parties’ tracking technologies or how they may be used. </p>
                <p>We currently use the services of the following third-party vendors, who may collect your information.  You can click on the hyperlinks below to view their privacy policies.</p>
                <p><a href="https://aws.amazon.com/privacy/?nc1=f_pr">https://aws.amazon.com/privacy/?nc1=f_pr</a></p>
                <h4>How We Use Your Information</h4>
                <p>We may use information that we collect about you or that you provide to us, including Personal Information, to measure usage of the Services, to analyze the effectiveness of our Services, to conduct research, to improve and enhance functionality of the Services, to provide support for the Services and respond to requests and inquiries, to provide you with notices, such as updates or changes that we may make to the Services, and to market and advertise the Services, including through promotional e-mail messages, directly or in partnership with other parties, and by displaying our advertisements on other websites that you visit or use. We may also use this information to offer you targeted advertising in order to personalize your experience by showing you advertisements for products and services that are more likely to appeal to you (a practice known as behavioral advertising).</p>
                <p>We may from time to time aggregate information in certain data analysis, reports, or other interpretations of trends for both internal and external purposes.</p>
                <h4>Choices About How We Collect, Use and Disclose Your Information</h4>
                <p>We strive to provide you with choices regarding our collection, use and disclosure of the information you provide to us. The mechanisms listed below aim to provide you with control over such collection, use and disclosure:</p>
                <ul>
                    <li><em>Marketing Communications</em>. If you do not want us to send you marketing or promotional communications, you can opt-out by clicking the “unsubscribe” link in any such promotional emails, checking the relevant box located on the form on which we collect your data or emailing us at <a href="mailto:info@myrxplace.com">info@myrxplace.com</a>.</li>
                    <li><em>Cookies</em>. You have the option to accept or disable cookies at any time through your browser. You may refuse to accept browser cookies by activating the appropriate setting on your browser. If you choose to disable your cookies, your user experience may be limited.</li>
                    <li><em>Google Analytics</em>. You can opt out of tracking by Google Analytics by using Google’s Ads Settings or going to <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>. You may also opt out of Google marketing products, but please note that this does not opt you out of being served generic ads.</li>
                    <li><em>Third-Party Advertising</em>. If you are interested in more information about tailored advertising and your choices to prevent third parties from delivering tailored web advertisements, you may visit the following websites: <a href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a> or <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>. These opt-out tools are provided by third parties. We do not control or operate these tools or the choices that advertisers and others provide through these tools.</li>
                </ul>
                <h4>Disclosure of Your Information</h4>
                <p>We want you to understand when and with whom we may share the information we collect.  We may disclose information that we collect through the Services with third parties as follows:</p>
                <ul>
                    <li><em>Service Providers</em>. We may share your information with our agents and service providers that perform certain functions or services on our behalf, such as to host the Services, manage databases, host a store or other e-commerce platform, perform analyses, or conduct surveys.</li>
                    <li><em>Select Business Partners</em>. We may share your information with select business partners so that they can provide you with special offers, promotional materials, advertisements and other materials that may be of interest to you. However, we are not responsible for the privacy practices of these business partners, which may use your information for their own purposes.</li>
                    <li><em>Other Parties When Required By Law or as Necessary to Protect the Services</em>. We may disclose your information to third parties in order to protect the legal rights, safety, and security of RxPlace and the users of our Services; enforce our Terms of Service; prevent fraud; and comply with or respond to law enforcement or a legal process or a request for cooperation by a government or other entity, whether or not legally required.</li>
                    <li><em>In Connection With a Transfer of Assets</em>. If we sell all or part of our business, or make a sale or transfer of assets, or are otherwise involved in a merger or business transfer, or in the event of bankruptcy, we may transfer your information to one or more third parties as part of that transaction.</li>
                    <li><em>Other Parties With Your Consent</em>. We may share information about you with other third parties when you consent to such sharing.</li>
                    <li><em>Aggregate Information</em>. We may disclose to third parties information that does not describe or identify individual users, such as aggregate website usage data or demographic reports.</li>
                </ul>
                <p>You will be notified via email and/or a prominent notice on our Site of any change in ownership or uses of your Personal Information, as well as any choices you may have regarding your Personal Information.</p>
                <h4>Social Networking Services</h4>
                <p>The Services may allow you to post information to various third-party services or platforms, such as social networking services like Instagram, Twitter and Facebook.  You acknowledge that if you choose to use this feature, your friends, followers and subscribers on these third-party services or platforms will be able to view such activity.  If you choose to access or make use of third-party social networking services, we may receive information about you that you have made available to those social networking services, including information about your contacts on those social networking services.  The use of the information by such social networking websites will be governed by their privacy policies, and we do not control their use of the shared data.</p>
                <h4>Links to Third-Party Sites and Services </h4>
                <p>The Services may contain links to other websites or online services that are operated and maintained by third parties and that are not under the control of or maintained by RxPlace.  Such links do not constitute an endorsement by RxPlace of those other websites, the content displayed therein, or the persons or entities associated therewith. This Privacy Policy does not apply to this third-party content. We encourage you to review the privacy policies of these third-party websites or services.</p>
                <h4>Do Not Track Signals</h4>
                <p>Third parties may keep track of your browsing activities across third party websites. California Business & Professions Code Section 22575(b) provides that California residents are entitled to know whether we respond to “Do Not Track” browser signals. Certain web browsers enable users to activate a “Do Not Track” signal but we do not currently respond to the “Do Not Track” signal or other similar mechanisms.</p>
                <h4>Data Retention</h4>
                <p>We strive to keep your information only as long as we need it for legitimate business purposes and as permitted by applicable legal requirements. If you close your account, we will retain certain data for analytical purposes and recordkeeping integrity, as well as to prevent fraud, enforce our Terms of Use, take actions we deem necessary to protect the integrity of our Services or our users, or take other actions otherwise permitted by law. In addition, if certain information has already been provided to third parties as described in this Privacy Policy, retention of that information will be subject to those third parties’ policies.</p>
                <p>You acknowledge that RxPlace may establish general practices and limits concerning use of the Services, including without limitation the maximum period of time that data or other content will be retained by the Services and the maximum storage space that will be allotted on RxPlace’s servers on your behalf. You further acknowledge that RxPlace reserves the right to change these general practices and limits at any time, in its sole discretion. We will endeavor to give you notice of any such change where required by applicable law.</p>
                <h4>Accessing and Correcting Your Information</h4>
                <p>If you wish to access, update, correct or delete any Personal Information in our possession that you have previously submitted via the Services, you may log into your account and make updates. If you wish to make changes not available through your account settings, please contact us at <a href="mailto:info@myrxplace.com">info@myrxplace.com</a>.</p>
                <h4>Security</h4>
                <p>We take reasonable steps to protect the Personal Information provided to us from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or e-mail transmission is ever fully secure or error free. In particular, e-mail sent to or from the Services may not be secure. Therefore, you should take special care in deciding what information you send to us via e-mail. Please keep this in mind when disclosing any Personal Information to us via the Internet. In the event that personal information is compromised as a breach of security, RxPlace will promptly notify our customers in compliance with applicable law.</p>
                <h4>Notice to California Residents. </h4>
                <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a calendar year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please send an email to <a href="mailto:info@myrxplace.com">info@myrxplace.com</a>.</p>
                <p>If you are under 18 years of age, reside in California, and have a registered account with the Site, you have the right to request removal of unwanted data that you publicly post on the Site. To request removal of such data, please contact us using the contact information provided and include the email address associated with your account and a statement that you reside in California. We will make sure that the data is not publicly displayed on the Site, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>
                <p>To request any other changes or information about our collection, use or disclosure of your information, please email us at <a href="mailto:info@myrxplace.com">info@myrxplace.com</a>.</p>
                <h4>Children Under the Age of 13</h4>
                <p>Our Services are intended for users who are 13 years of age and older. If you are under the age of 13, you are not permitted to submit any Personal Information to us. If you believe we might have any information from or about a child under 13, please contact us at <a href="mailto:info@myrxplace.com">info@myrxplace.com</a>.</p>
                <h4>International Users</h4>
                <p>Please note that the Services are directed towards users who reside in the United States. By using the Services, you consent to the collection, storage, processing, and transfer of your information in and to the United States, or other countries and territories, pursuant to the laws of the United States. Some of these countries may not offer the same level of privacy protection as your own. </p>
                <h4>Contact Information</h4>
                <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@myrxplace.com">info@myrxplace.com</a>].</p>
            </div>

    </RxPlUtility>
    )

}

export default PrivacyPolicy