import styled from 'styled-components'
import {color} from 'shared/theme'
import {RxPlLayout} from 'shared/layout'
import solidIcon from 'assets/icons/select_down_icon.svg'
import {device} from 'shared/layout'

export const RxPlCartLayout = styled(RxPlLayout)`

   h5 {
      text-align: center;
   }
   .shipping-information {
      display: block;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 42px;
      h6 {
         font-size: 26px;
         margin: 8px 16px 16px 0;
      }

      >div {

         p {
            font-size: 14px;
         }
         select {
            padding: 10px 54px 10px 24px;
            width: 100%;
            text-align-last: center;
            text-align: center;
            -ms-text-align-last: center;
            -moz-text-align-last: center;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
            background: ${color.white} url(${solidIcon}) no-repeat !important;
            background-position: 95% center !important;
         }

      }

      @media only screen and ${device.sm} {
         display: flex;
         h6 {
         font-size: 26px;
         margin: 8px 16px 0 0;
      }
      }
   }
`

export const RxPlCartContainer = styled.div`
   display: block;
   margin: 0;
   position: relative;

   .wholesaler-text-total {
      font-size: 20px;
   }

   &:after {
      display: none;
   }

   &.no-savings {
      display: block;
 
   }

   @media only screen and ${device.sm} {
      display: flex;
      margin: 0 -40px;


      &:after {
         content: "";
         width: 1px;
         height: 100%;
         display: block;
         background: ${color.primary};
         position: absolute;
         top: 0;
         left: 50%;
         transform: translate(50%, 0%);
      }
   }
`

export const RxPlCartList = styled.div`
    width: 100%;
   margin: 0 0 90px;

   h6 {
      font-size: 20px;
      font-weight: 300;

      &.c-white {
         color: white;
      }
   }
    div {
       &:last-child {
         margin-bottom: 0;
       }
    }

    &.optimized-cart {
      padding: 20px;
      border-radius: 12px;
      background: ${color.lightSky};
    }

    &.no-savings {
      width: calc(50% - 80px) ;
      margin: 0 40px;
      display: inline-block ;
 
   }

    @media only screen and ${device.sm} {
      flex-basis: 50%;
      margin: 0 40px;

   }
`
