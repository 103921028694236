import styled, {css} from 'styled-components'
import {IconRegular, Icon24, Icon32} from 'shared/constants/icons'
import magnifierIcon from 'assets/icons/magnifier.svg'
import cartIcon from 'assets/icons/cart.svg'
// import notificationIcon from 'assets/icons/notification.svg'
import boxIcon from 'assets/icons/box.svg'
import userIcon from 'assets/icons/user.svg'
import docIcon from 'assets/icons/doc.svg'
import forwardIcon from 'assets/icons/forward.svg'
import cancelIcon from 'assets/icons/cancel.svg'
import editIcon from 'assets/icons/edit.svg'
import licenseIcon from 'assets/icons/license.svg'
import profileIcon from 'assets/icons/profile.svg'
import lockIcon from 'assets/icons/lock.svg'
import unlockIcon from 'assets/icons/unlock.svg'
import shopIcon from 'assets/icons/shop.svg'
import messageIcon from 'assets/icons/message.svg'
import barchartIcon from 'assets/icons/barchart.svg'
import orderIcon from 'assets/icons/order.svg'
import inventoryIcon from 'assets/icons/inventory.svg'
import homeIcon from 'assets/icons/home.svg'
import logoutIcon from 'assets/icons/logout.svg'
import menuIcon from 'assets/icons/menu-icon.svg'
import closeIcon from 'assets/icons/x.svg'

export const RxPlIcon = styled.i`
  display: inline-block;
  ${
    props => props.type === 'magnifier' && 
    css`
      width: 32px;
      height: 32px;
      background: url(${magnifierIcon}) no-repeat;
    ` 
  }

  ${
    props => props.type === Icon32.CART && 
    css`
      width: 32px;
      height: 32px;
      background: url(${cartIcon}) no-repeat;
    ` 
  }

${
    props => props.type === Icon32.CART && 
    css`
      width: 32px;
      height: 32px;
      background: url(${cartIcon}) no-repeat;
    ` 
  }

  ${
    props => props.type === Icon32.PROFILE && 
    css`
        width: 32px;
      height: 32px;
      background: url(${profileIcon}) no-repeat;
   ` 
  }

${
    props => props.type === Icon24.BOX && 
    css`
      width: 24px;
      height: 24px;
      background: url(${boxIcon}) no-repeat;
   ` 
  }

${
    props => props.type === Icon24.USER && 
    css`
      width: 24px;
      height: 24px;
      background: url(${userIcon}) no-repeat;
   ` 
  }

${
    props => props.type === Icon24.DOC && 
    css`
      width: 24px;
      height: 24px;
      background: url(${docIcon}) no-repeat;
   ` 
  }

${
    props => props.type === Icon24.LOGOUT && 
    css`
      width: 24px;
      height: 24px;
      background: url(${logoutIcon}) no-repeat;
   ` 
  }

${
    props => props.type === IconRegular.FORWARD && 
    css`
      width: 8px;
      height: 14px;
      background: url(${forwardIcon}) no-repeat;
   ` 
  }

${
    props => props.type === IconRegular.MENU && 
    css`
      width: 24px;
      height: 24px;
      background: url(${menuIcon}) no-repeat;
   ` 
  }

${
    props => props.type === IconRegular.CLOSE && 
    css`
      width: 24px;
      height: 24px;
      background: url(${closeIcon}) no-repeat;
   ` 
  }

${
    props => props.type === IconRegular.CANCEL && 
    css`
      width: 32px;
      height: 32px;
      background: url(${cancelIcon}) no-repeat;
   ` 
  }

  ${
    props => props.type === IconRegular.EDIT && 
    css`
      width: 18px;
      height: 18px;
      background: url(${editIcon}) no-repeat;
   ` 
  }

  ${
    props => props.type === IconRegular.LICENSE && 
    css`
      width: 50px;
      height: 58px;
      background: url(${licenseIcon}) no-repeat;
      background-size: 100%;
   ` 
  }

${
    props => props.type === IconRegular.LOCK && 
    css`
      width: 24px;
      height: 24px;
      background: url(${lockIcon}) no-repeat;
    ` 
  }

${
    props => props.type === IconRegular.UNLOCK && 
    css`
      width: 24px;
      height: 24px;
      background: url(${unlockIcon}) no-repeat;
    ` 
  }

${
    props => props.type === IconRegular.SHOP && 
    css`
      width: 28px;
      height: 28px;
      background: url(${shopIcon}) no-repeat;
   ` 
  }

${
    props => props.type === IconRegular.MESSAGE && 
    css`
      width: 28px;
      height: 28px;
      background: url(${messageIcon}) no-repeat;
   ` 
  }

${
    props => props.type === IconRegular.HOME && 
    css`
      width: 28px;
      height: 28px;
      background: url(${homeIcon}) no-repeat;
   ` 
  }

${
    props => props.type === IconRegular.INVENTORY && 
    css`
      width: 28px;
      height: 28px;
      background: url(${inventoryIcon}) no-repeat;
   ` 
  }

${
    props => props.type === IconRegular.BAR && 
    css`
      width: 28px;
      height: 28px;
      background: url(${barchartIcon}) no-repeat;
   ` 
  }

${
    props => props.type === Icon32.PROFILE && 
    css`
        width: 32px;
      height: 32px;
      background: url(${profileIcon}) no-repeat;
   ` 
  }


${
    props => props.type === IconRegular.ORDER && 
    css`
      width: 28px;
      height: 28px;
      background: url(${orderIcon}) no-repeat;
   ` 
  }


  background-position: center;

`