import {useContext, useState,useEffect, useRef} from 'react'
import Button from 'shared/components/Button'
import Icon from 'shared/components/Icon'
import { IconRegular } from 'shared/constants/icons'
import Form from 'shared/components/Form'

import {RxPlInformationCard, RxPlLisenceItem} from './styles'
import useService from 'shared/hooks/useService'
import {PageContext} from 'shared/contexts/PageContext'
import moment from 'moment'
import Cookies from 'js-cookie'
import isEqual from 'lodash/isEqual';
import { ModalType } from 'shared/constants/modal'
import { gaPageview } from 'shared/utils/ga'
import Spinner from 'shared/components/Spinner'
import { RxPlFormGroup } from 'pages/Signup/style'
import Input from 'shared/components/Input'

const Licenses = () => {
   const {get, api, uploadAPI, logout} = useService()
   const {customer, setModal} = useContext(PageContext)
   const [deaLicense, setDEALicense] = useState(null)
   const [stateLicense, setStateLicense] = useState(null)
   const [editDEA, setEditDEA] = useState(false)
   const [editState, setEditState] = useState(false)
   const [isFetching, setIsFetching] = useState(false)
   const [deaFilename, setDEAFilename] = useState(null)
   const [stateFilename, setSTATEFilename] = useState(null)
   const downloadLicense = async (fileName) => {
      if (fileName == null) return
      try {
         let response = await api().post("/DownloadLicense",  
            { fileName },
            {
               responseType: 'arraybuffer',
               headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/pdf',
                  'Authorization': `Bearer ${Cookies.get('token')}`,
               }
            }
         )

            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

      } catch (error) {

         if (error && error.response && error.response.status && error.response.status === 401) {
            setModal({type: ModalType.ERROR , message: "Your session has expired."})
            logout()
            return
         }
         console.log("error " ,error)
         // setErrorMessage(response.data.title)

      }
   }

   const onDEALicenseInputChanged = (e) => {
      setDEALicense(prevState => ({
         ...prevState,
         [e.target.name]: e.target.value
       
       }))
   }
   

   const updateDEALicense = async (e) => {
      e.preventDefault()

      setIsFetching(true)
      let deaFormData = new FormData()
      deaFormData.append("CustomerId", deaLicense.customerId)
      deaFormData.append("LicenseDocType" , 'document')
      deaFormData.append("LicenseNumber" , deaLicense.licenseNumber)
      deaFormData.append("LicenseType" , 'DEA')
      deaFormData.append("ExpirationDate", deaLicense.expirationDate)
      deaFormData.append('UploadedForm', deaFilename)
      deaFormData.append('LicenseId', deaLicense.licenseId)

 
      const result = deaLicense && deaLicense.licenseId && await uploadAPI(deaFormData._boundary).put("/CustomerLicense/" + deaLicense.licenseId, deaFormData)
      if (result.data && result.data.data) {
         setDEALicense(result.data.data)
         setEditDEA(false)
      }
      setIsFetching(false)
   }

   const updateSTATELicense = async (e) => {
      e.preventDefault()
      setIsFetching(true)

      let stateFormData = new FormData()
      stateFormData.append("CustomerId", stateLicense.customerId)
      stateFormData.append("LicenseDocType" , 'document')
      stateFormData.append("LicenseNumber" , stateLicense.licenseNumber)
      stateFormData.append("LicenseType" , 'STATE')
      stateFormData.append("ExpirationDate", stateLicense.expirationDate)
      stateFormData.append('UploadedForm', stateFilename)
      stateFormData.append('LicenseId', stateLicense.licenseId)

 
      const result = stateLicense && stateLicense.licenseId && await uploadAPI(stateFormData._boundary).put("/CustomerLicense/" + stateLicense.licenseId, stateFormData)

      if (result.data && result.data.data) {
         setEditState(false)
         setStateLicense(result.data.data)

      }
      setIsFetching(false)

   }

   const onSTATELicenseInputChanged = (e) => {
      setStateLicense(prevState => ({
         ...prevState,
         [e.target.name]: e.target.value
       
       }))
   }
   

   const getLicenses = ()  => {
      if (isFetching) return

      setIsFetching(true)
      get("/CustomerLicense/customer/" + customer.profile.customerId, ({data}) => {
         setDEALicense(data.filter( license => license.licenseType === "DEA")[0])
         setStateLicense(data.filter (license => license.licenseType === "STATE")[0])
         setIsFetching(false)

      },
      () => setIsFetching(false)
      )

   }
   const onDEAFilenameChanged = (e) => {
      setDEAFilename(e.target.files[0])
   }

   const onSTATEFilenameChanged = (e) => {
      setSTATEFilename(e.target.files[0])
   }


   useEffect(()=> {
      let mounted = true

      if (Object.keys(customer).length === 0 && !customer.profile) return
      if (isEqual(previousDeaLicense.current, deaLicense) && isEqual(previousStateLicense, stateLicense)) {
         return
      }
      if (mounted) {
         getLicenses()

      }

      gaPageview("/account", "Account - Licenses")

      return () => mounted = false

   },[]) // eslint-disable-next-line react-hooks/exhaustive-deps

   const previousDeaLicense = useRef()
   const previousStateLicense = useRef()
   useEffect(() => {
      previousDeaLicense.current = deaLicense
      previousStateLicense.current = stateLicense
   })
   return (
      <>
         {
            deaLicense && stateLicense ?
            <RxPlInformationCard>
            <RxPlInformationCard.ItemWrapper>
               <RxPlInformationCard.Item>

                  <RxPlInformationCard.ItemHeader>
                  DEA       
                  {
                  !editDEA &&   <Button className="edit-button" onClick={ () => setEditDEA(true)}>
                     <Icon type={IconRegular.EDIT} />Edit
                  </Button>
                  }              

                  </RxPlInformationCard.ItemHeader>


                  {
                     editDEA ? 
                     
                     <RxPlLisenceItem className={`${editDEA ? 'edit' : ''}`}>
                        <Form>
                        <RxPlFormGroup>
                              <label>License Number*</label>
                              <Input name="licenseNumber" pattern="[a-zA-Z0-9-]+" placeholder="License Number" required defaultValue={deaLicense.licenseNumber} onChange={onDEALicenseInputChanged}/>
                           </RxPlFormGroup>

                           <RxPlFormGroup>
                              <label>Expiration Date *</label>
                              <Input name="expirationDate" placeholder="MM/DD/YYYY" type="date" defaultValue={moment(deaLicense.expirationDate).format("YYYY-MM-DD")}  onChange={onDEALicenseInputChanged}  required />
                           </RxPlFormGroup>

                           <RxPlFormGroup>
                              <label>Upload License * <span>(max file size 3 MB)</span></label>
                              <Input name="DEAUploadedForm" type="file" required onChange={onDEAFilenameChanged} />
                           </RxPlFormGroup>
                           <RxPlFormGroup className='stack'> 
                           <Button className="dea-submit-btn" type="outline" onClick={() => setEditDEA(false)}>cancel</Button>

                           <Button className="dea-submit-btn" type="primary" onClick={updateDEALicense}>Save</Button>
                            </RxPlFormGroup>
                        </Form>

                     </RxPlLisenceItem> 
                     
                  :

                  <RxPlLisenceItem>
                     <Icon type={IconRegular.LICENSE} />
                     <div>
                        {
                           deaLicense && deaLicense.licenseNumber && deaLicense.newFileName && deaLicense.expirationDate && 
                           <>
                              <p className="file-name" onClick={() =>  downloadLicense(deaLicense.newFileName)}>{deaLicense.originalFileName}</p>
                              <p><span>{deaLicense.licenseNumber}</span> <span>Exp: {moment(deaLicense.expirationDate).format("L")}</span></p>
                           </>
                        }

                     </div>
                  </RxPlLisenceItem>
                  }





                  {/* <RxPlInformationCard.ItemHeader>
                  Tax Exempt/Resale                     
                  <Button className="edit-button" onClick={editHandler}>
                     <Icon type={IconRegular.EDIT} />Upload
                  </Button>
                  </RxPlInformationCard.ItemHeader> */}


               </RxPlInformationCard.Item>

               <RxPlInformationCard.Item>


                  <RxPlInformationCard.ItemHeader>
                     State Board of Pharmacy

                     {
                     !editState &&
                     <Button className="edit-button" onClick={() => setEditState(true)}>
                        <Icon type={IconRegular.EDIT} />Edit
                     </Button>
                     }

                  </RxPlInformationCard.ItemHeader>

                  {
                     editState ? 

                     <RxPlLisenceItem className={`${editState ? 'edit' : ''}`}>
                        <Form>
                        <RxPlFormGroup>
                           <label>License Number*</label>
                           <Input name="licenseNumber" pattern="[a-zA-Z0-9-]+" placeholder="License Number" required defaultValue={stateLicense.licenseNumber} onChange={onSTATELicenseInputChanged}/>
                        </RxPlFormGroup>

                        <RxPlFormGroup>
                           <label>Expiration Date *</label>
                           <Input name="expirationDate" placeholder="MM/DD/YYYY" type="date" defaultValue={moment(stateLicense.expirationDate).format("YYYY-MM-DD")} required onChange={onSTATELicenseInputChanged}/>
                        </RxPlFormGroup>

                        <RxPlFormGroup>
                           <label>Upload License * <span>(max file size 3 MB)</span></label>
                           <Input name="StateUploadedForm" type="file" required onChange={onSTATEFilenameChanged}  />
                        </RxPlFormGroup>
                        <RxPlFormGroup className='stack'> 
                        <Button className="dea-submit-btn" type="outline" onClick={() => setEditState(false)}>cancel</Button>

                        <Button className="dea-submit-btn" type="primary" onClick={updateSTATELicense}>Save</Button>
                         </RxPlFormGroup>
                        </Form>
  
                  </RxPlLisenceItem> 
                  :
                  <RxPlLisenceItem>
                  <Icon type={IconRegular.LICENSE} />
                  <div>
                     {
                        stateLicense && stateLicense.newFileName && stateLicense.licenseNumber && stateLicense.expirationDate && 
                        <>
                           <p className="file-name" onClick={() => downloadLicense(stateLicense.newFileName)}>{stateLicense.originalFileName}</p>
                           <p><span>{stateLicense.licenseNumber}</span> <span>Exp: {moment(deaLicense.expirationDate).format("L")}</span></p>
                        </>
                     }

                  </div>
               </RxPlLisenceItem>
                  }
 
                  

                  {/* <RxPlInformationCard.ItemHeader>
                     NPI
                     <Button className="edit-button" onClick={editHandler}>
                        <Icon type={IconRegular.EDIT} />Upload
                     </Button>
                  </RxPlInformationCard.ItemHeader> */}

               </RxPlInformationCard.Item>
            </RxPlInformationCard.ItemWrapper>

         </RxPlInformationCard>
         : 
         <Spinner />
         }

      </>
   )
}


export default Licenses