import styled, {css} from 'styled-components'
import {RxPlLinkGroup, device} from 'shared/layout'
import {color} from 'shared/theme'


export const RxPlFixedLayout = styled.div`
   position: fixed;
   width: 100%;
   z-index: 1000;
   background: white;

   ${
    props => props.type === 'login' && 
    css`
      background: ${color.backgroundDefault};
    ` 
  }

${
    props => 
    (props.type === 'logout' || 
    props.type === 'results' ||
    props.type === 'onboarding' ||
    props.type === 'checkout') && 
    css`
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.17);
    ` 
  }

`

export const RxPlTopbar = styled.div`
   width: 100%;
   height: 80px;
   display: flex;
   align-items: center;


   .lock {
      margin-left: 7%;
      i {
         margin-right: 8px;
      }
   }
   width: 100%;

`

export const RxPlLinkGroupAlignRight = styled(RxPlLinkGroup)`
   margin-left: auto;
   button {
      width: auto;
      padding: 10px 16px;
   }
   @media only screen and ${device.sm} {
      button {
      padding: 16px;
   }
   }
`

export  const RxPlSearchLayout = styled.div`
   width: 100%;
   margin: 0;

   .flex {
      display: flex;
      align-items: center;

      p {
         margin: 0;
      }
   }

   .align-right {
      margin-left: auto;
   }

   button {
      color: ${color.sky};
   }

   @media only screen and ${device.sm} {
 
      width: 70%;
      margin: 0 auto;

      .search-input {
         max-width: unset;

         input {
            max-width: unset;
            padding: 16px 80px 16px 32px;
         }
      }
   }

`