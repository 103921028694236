import styled from 'styled-components'
import Card from 'shared/components/Card'
import { color} from 'shared/theme'

export const RxPlThankYouCard = styled(Card)`
   width: 100%;
   padding: 56px 0;
   margin-bottom: 24px;
   text-align: ${props => props.textAlign ? props.textAlign : 'left'};
   h1 {
      margin-top: 20px;
      margin-bottom: 40px;
   }
   hr {
      height: 1px;
      background-color:  ${color.grayLight};
      border: none;
      margin: 40px 0;
   }

   &.gutter {
      padding-left: 152px;
      padding-right: 152px;
   }

   .flex-wrapper {
      display: flex;
      margin-bottom: 16px;
      margin-top: 24px;
      img {
         margin-right: 16px;
      }

      p {

         span {
            font-size: 0.8rem;
            line-height: 0.8rem;
         }
      }
   }

   button {
      width: 276px;
      display: block;
      margin: 16px auto 0;

      &.underline {
         border-bottom: 1px solid  ${color.grayPrimary};
      }

      &.unset {
         width: auto;
         padding: 0;
      }
   }
`
