import React, {useState, forwardRef, useEffect} from 'react'
import {RxCarouselContainer} from './styles'
import CarouselItem from '../CarouselItem'
import Pagination from 'shared/components/Pagination'

const CarouselContainer = forwardRef( (props, ref) => {
   const [currentIndex, setCurrentIndex] = useState(0)
   const [screenWidth, setScreenWidth] = useState(window.innerWidth)
   const [totalSet, setTotalSet] = useState(5)
   

   const onClickHandler = (index) => {
      setCurrentIndex(index)
   }

   function handleWindowSizeChange() {
      setScreenWidth(window.innerWidth);
      if (screenWidth <= 767) setTotalSet(1) 
      if (screenWidth <= 959 && screenWidth >= 768) setTotalSet(3) 
      if (screenWidth >= 960) setTotalSet(5) 
      setCurrentIndex(0)
  }

  useEffect(() => {
   window.addEventListener('resize', handleWindowSizeChange);
   if (screenWidth <= 767) setTotalSet(1) 
   if (screenWidth <= 959 && screenWidth >= 768) setTotalSet(3) 
   if (screenWidth >= 960) setTotalSet(5) 

   return () => {
       window.removeEventListener('resize', handleWindowSizeChange);
   }
   }, []);

   return (
      <RxCarouselContainer ref={ref} className={`${props.className} ${props.theme ? 'theme-' + props.theme : ''}`}>
         <h3 className="sky-text title-text">{props.title}</h3>
         <div className='container-wrapper' style={{transform: `translateX(${-currentIndex * 100}%)`}}>
            {
                props.data.length && props.data.map( (item, index) => <CarouselItem key={index} data={item} addToCart={props.addToCart} />)
            }
         </div>
         <Pagination size={props.data.length} set={totalSet} onIndexUpdate={onClickHandler}/>
      </RxCarouselContainer>

   )
})


export default CarouselContainer

