
import Contact from './Contact'
import Shipping from './Shipping'
import Billing from './Billing'
import Licensing from './Licensing'
import Review from './Review'
import Account from './Account'

export const stepperData = [
   {
      title: "Create an Account"
   },
   {
      title: 'Contact Information',
   },
   {
      title: 'Shipping Address',
   },
   {
      title: 'Billing Address',
   },
   {
      title: 'Licensing Numbers',
   },
   {
      title: 'Review Profile',
   }
]

export const titles = [
   {
      value: "",
      label: "Select a title"
   },

   {
      value: "Owner",
      label: "Owner"
   },

   {
      value: "PIC",
      label: "PIC"
   },

   {
      value: "Pharmacist",
      label: "Pharmacist"
   },
   {
      value: "Buyer",
      label: "Buyer"
   }
]

export const data = {
   account: {
      id: 0,
      view: Account,
      title: 'Create an Account',
      p: 'Let’s get started!'
   },
   contact: {
      id: 1,
      view: Contact,
      title: 'Contact Information',
      p: 'Let’s get started! Tell us about you and your pharmacy.'
   },
   shipping: {
      id: 2,
      view: Shipping,
      title: 'Shipping Address',
      p: 'Tell us where to ship your orders you have purchased.'
   },

   billing: {
      id: 3,
      view: Billing,
      title: 'Billing Address',
      p: 'Let us know where to send the bill for your purchase.'
   },
   license: {
      id: 4,
      view: Licensing,
      title: 'Licensing Numbers',
      p: 'Let’s finish up with your licensing numbers and documents.'
   },
   review: {
      id: 5,
      view: Review,
      title: 'Review your Information',
      p: 'Tell us where to ship your orders you have purchased.'
   }
}