
import {useContext, useState, useRef, useEffect} from 'react'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import {RxPlCloseButton} from 'shared/layout'
import {RxPlSignin} from './style'
import {PageContext} from 'shared/contexts/PageContext'
import closeIcon from 'assets/icons/close.svg'
import useService from 'shared/hooks/useService'
import {AuthenticationType} from 'shared/constants/authentication'
import { ModalType } from 'shared/constants/modal'

const ForgotPasswordProgressType = {
  CODE: 'get-code',
  RESET: 'reset-passowrd'
}

// const propTypes = {
//     progress: PropTypes.oneOf([ForgotPasswordType.FORGOT, ForgotPasswordType.RESET]),
//     data: PropTypes.object,
//  }

//  const defaultProps = {
//     progress: AuthenticationType.LOADING,
//   }

  
const ForgotPassword = props => {

    const [errorMessage, setErrorMessage] = useState("")
    const [progress, setProgress] = useState(ForgotPasswordProgressType.CODE)

    const {
        setModal,
     }  = useContext(PageContext)

     const form = useRef(null)
     const {api} = useService()


     const onInputChange = e => {
        setErrorMessage("")
     }

     const forgotPassword = async () => {
        const endpoint = progress === ForgotPasswordProgressType.CODE ? '/auth/forgotpassword' : '/auth/confirmforgotpassword'

      try {
        const formData = new FormData(form.current)
        const serializeFormData = Object.fromEntries(formData)

        let response = await api().post(endpoint, serializeFormData)
        if (response.data.httpStatusCode === 200 && progress === ForgotPasswordProgressType.RESET) {
          setModal({type: ModalType.AUTHENTICATION, data: {authenticationType: AuthenticationType.SIGNIN }})
        } else {
          setProgress(ForgotPasswordProgressType.RESET)
        }
     } catch (error) {
        const {response} = error
        console.log(response)

        if (response && response.data) {
          
          setErrorMessage((response.data.messages && response.data.messages.toString()) || response.data.title )
       }
     }
     }
     const onResetPassword = e => {
        e.preventDefault()
        forgotPassword()

     }

     const resendCode = e => {
      setErrorMessage("")
      setProgress(ForgotPasswordProgressType.CODE)
     }

     const backToSignIn = () => {
      setModal({type: ModalType.AUTHENTICATION, data: {authenticationType: AuthenticationType.SIGNIN }})

     }
     useEffect(()=> {
        let mounted = true 
        const listener = e => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.preventDefault()
                if (mounted)
                {
                  forgotPassword()
                }
            }
          };
          document.addEventListener("keydown", listener)
          return () => {
            document.removeEventListener("keydown", listener);
            mounted = false
          }
    

     }, []) // eslint-disable-next-line react-hooks/exhaustive-deps
    return (
        <form ref={form}>

            <RxPlSignin className="forgot-password">

                    <RxPlCloseButton onClick={ () => setModal({type: ""})}>
                    <img src={closeIcon} alt="close icon" />
                    </RxPlCloseButton>
                  <p>{progress === ForgotPasswordProgressType.CODE ? `Forgot Password?` : 'Reset Password'}</p>
                  {
                      errorMessage !== "" && 
                      <div className="error-message"><p>{errorMessage} 
                      {
                        errorMessage.includes("Invalid verification code") && 
                        <>
                          <br/>Didn't receive a code? <Button onClick={resendCode}>Resend it</Button>
                        </>
                      }
                      </p></div>
                  }

                <>
                  <RxPlSignin.FormGroup>
                      <p>Email address</p>
                      <Input name="emailAddress" type="email" placeholder="Email" onChange={onInputChange} required />

                      {
                        progress === ForgotPasswordProgressType.RESET && 
                        <>
                          <p>New Password</p>
                          <Input name="password" type="password" placeholder="Password" onChange={onInputChange} required />
                          <p>Confirmation Code</p>
                          <Input name="confirmationCode" type="confirmationCode" placeholder="Confirmation Code" onChange={onInputChange} required />
                        </>
                      }


                  </RxPlSignin.FormGroup>
                </>

                


                    <RxPlSignin.ButtonGroup>
                      <Button onClick={onResetPassword} type="primary">{progress === ForgotPasswordProgressType.CODE ? 'Get Confirmation Code' : 'Reset Password'}</Button>
                      <Button className="sky-text" onClick={backToSignIn}>Back to Sign in</Button>
                    </RxPlSignin.ButtonGroup>
  


            </RxPlSignin>
        </form>
    )
}

// ForgotPassword.propTypes = propTypes
// ForgotPassword.defaultProps = defaultProps


export default ForgotPassword