import {useEffect,useState, useCallback, useContext} from 'react'
import Select from 'shared/components/Select'
import Button from 'shared/components/Button'
import {RxPlResultCard } from './styles'
import useService from 'shared/hooks/useService'
import { useHistory} from 'react-router-dom'
import { setTotalCartItems } from 'shared/contexts/CartContext/reducer'
import placeholder from 'assets/images/medicine_placeholder.png'
import { useCart } from 'shared/hooks/useCart'
import { gaAddToCart } from 'shared/utils/ga'

const CardResult = props => {
   const {data} = props
   const {api, sessionIsAvailable} = useService()
   const {dispatch} = useCart()
   const history = useHistory()
   const [quantityOptions, setQuantityOptions] = useState([])
   const [quantity, setQuantity] = useState(0)
   const addToCart = useCallback ( async (product) => {
      if (!sessionIsAvailable()) {
         history.push('/')
         return
      }
      try {
          let response = await api().post("/cart", product)
          // get itemCountInCart
          gaAddToCart(product)
          dispatch(setTotalCartItems(response.data.data.itemCountInCart))
         //  localStorage.setItem('cart', JSON.stringify(response.data.data));
       } catch (error) {
           const {response} = error
         
           if (response) {
            console.log("ERROR" , (response.data && response.data.messages && response.data.messages.toString()) || response.data.title || "Something went wrong")
            if (typeof props.showError === 'function' ) {
               props.showError(response.data.validations["3001"] || response.data.validations["3002"] || response.data.validations["3003"] || response.data.validations["3004"] || "Something went wrong.")
            }
             
           } else {
              console.log("network error")
              props.showError("Network or CORS errors")

           }
       }

  }, [])


   const holdItemHandler = () => {
      const {inventoryId, productId, productUID, ndc, providerId, providerUID, price } = data
      const product = {
         inventoryId,
         productId,
         productUID,
         productCode: ndc,
         providerId,
         providerUID,
         quantity,
         price
      }
     
      addToCart(product)
   }

   const onChangeQuantity = e => {
      setQuantity(parseInt(e.target.value))
   }

   useEffect(()=>{
      
      if (data.itemQuantity) {
         setQuantityOptions( 
            Array.from(Array(data.itemQuantity + 1).keys()).map ( it => { return {
               label: it,
               value: it
            }} )
         )
      }

         
   },[data])


   return (

      <>
         {
            data && Object.keys(data).length > 0 && 
            <RxPlResultCard className="result-card">
      
            
            { data.categoryCode === 'INV_SHORTDATED'  &&
               <RxPlResultCard.Toast className={`${props.toast}`}>
                  <p>Short Dated</p>
               </RxPlResultCard.Toast>
            }

   
            <RxPlResultCard.Wrapper>
   
               <RxPlResultCard.Item className="product-image flex align-center align-middle">
                  <img src={data.imageFilename ? data.imageFilename : placeholder} alt="drug shot"/>
               </RxPlResultCard.Item>
               <RxPlResultCard.Item className="product-info">
                  <h6>{data.brandName || data.genericName} {data.dose}</h6>
                  <p><span className="sky-text">by {data.labeler}</span></p>
                  <p> {data.productQuantity} <br/>
                  NDC: {data.ndc}<br/>
                  Wholesaler: {data.wholeSaler} <br/>
                  {
                     data.categoryCode === 'INV_SHORTDATED' && (data.smartSearchCategory === 'REGULAR_LIST' || data.smartSearchCategory === 'LOW_COST_SHORT') &&
                     <>
                       Expiration Date:  {data.expirationDate}
                     </>
                  }
                  </p>
               </RxPlResultCard.Item>
   
   
               <RxPlResultCard.Item className="product-price">
               <h6>${data.priceStr}</h6>
               {quantityOptions.length > 0 &&  <Select iconType='solid' data={quantityOptions} onChange={onChangeQuantity} className="with-shadow"/> }
               
               </RxPlResultCard.Item>
   
               <RxPlResultCard.Item className="flex align-center align-middle">
                  <Button type="primary" onClick={holdItemHandler} disabled={quantity===0}>ADD TO CART</Button>
               </RxPlResultCard.Item>
            </RxPlResultCard.Wrapper>
         </RxPlResultCard>

         }
      </>

   )
}


export default CardResult