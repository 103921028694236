
import {useContext} from 'react'
import {RxPlCloseButton} from 'shared/layout'
import {RxPlError} from './style'
import {PageContext} from 'shared/contexts/PageContext'
import closeIcon from 'assets/icons/close.svg'

const Error = props => {

    const {
        setModal,
     }  = useContext(PageContext)
     
    const clickHandler = () => {
        if (typeof props.onClose === 'function') {
            props.onClose()
        }
        setModal({type: ""})

    }
    return (
    <RxPlError>
        <RxPlCloseButton onClick={clickHandler}>
           <img src={closeIcon} alt="close icon" />

        </RxPlCloseButton>
         <p>{props.message}</p>
     </RxPlError>
    )
}


export default Error