import React from 'react'
import Logo from 'shared/components/Logo'
import Button from 'shared/components/Button'
import Toolbar from 'shared/components/Toolbar'
import Search from 'shared/components/Search'
import {RxPlLayout} from 'shared/layout'
import {ModalType} from 'shared/constants/modal'
import {AuthenticationType} from 'shared/constants/authentication'
import {RxPlFixedLayout, RxPlTopbar, RxPlSearchLayout, RxPlLinkGroupAlignRight} from './style'
import PropTypes from 'prop-types'
import { usePage } from 'shared/hooks/usePage'
import { useCart } from 'shared/hooks/useCart'
import Cookies from 'js-cookie'

const propTypes = {
   type: PropTypes.oneOf(['logout', 'login', 'onboarding', 'checkout', 'cart', 'results', 'account', 'admin']),

}

const defaultProps = {
   type: 'logout',
 }

const Topbar = props => {
   const {setPage, setModal, isAdmin, setAdminAuthentication} = usePage()
   const {state} = useCart()

   const handleSignup = () => {
   //   setModal({type: ModalType.AUTHENTICATION, data: {authenticationType: AuthenticationType.SIGNUP }})
      setPage("signup")

      
   }

   const handleSignin = () => {
      setModal({type: ModalType.AUTHENTICATION, data: {authenticationType: AuthenticationType.SIGNIN }})
   }

   const goHome = () => {
      setPage('')
   }

   const goHomeAdmin = () => {
      setPage('admin')
   }

   const handleAdminSignOut = () => {
      Cookies.remove('admin_token')
      localStorage.clear()
      setAdminAuthentication(false)
      setPage("admin")
   }

   return(
      <RxPlFixedLayout {...props}>
         <RxPlLayout.Content className="no-vertical-paddings">
            <RxPlTopbar>
               <Logo onClick={props.type === 'admin' ? goHomeAdmin : goHome} />


               {
                  (props.type === 'checkout' || props.type === 'results' || props.type ==='account')
                   &&  
                  <RxPlSearchLayout><Search term={props.term} placeholder={props.searchPlaceholder || "Search"}/></RxPlSearchLayout>        
               }

               {
                  (props.type === 'login' || props.type === 'results' || props.type === 'checkout' ||  props.type ==='account') &&
                  <RxPlLinkGroupAlignRight>
                     <Toolbar badgeCount={state.totalItemsInCart} />
                  </RxPlLinkGroupAlignRight>
               }

               {
                  props.type === 'logout' && 
                  <RxPlLinkGroupAlignRight>
                     <Button onClick={handleSignin} type='outline'>Log in</Button>
                     <Button onClick={handleSignup} type='primary'>Sign up</Button>
                  </RxPlLinkGroupAlignRight>

               }

{
                  props.type === 'admin' &&  isAdmin &&
                  <RxPlLinkGroupAlignRight>
                     <Button onClick={handleAdminSignOut} type='primary'>Log out</Button>
                  </RxPlLinkGroupAlignRight>

               }


            </RxPlTopbar>
         </RxPlLayout.Content>

      </RxPlFixedLayout>

   )
}
 
Topbar.propTypes = propTypes
Topbar.defaultProps = defaultProps


export default Topbar