
import React from 'react'
import styled from 'styled-components'
import {font, color} from 'shared/theme'

const RxPlBadge = styled.span`
   position: absolute;
   display: flex;
   top: 0;
   left: 5px;
   right: auto;
   border-radius: 50%;
   background: ${color.blue};
   color: #fff;
   height: 18px;
   min-width: 18px;
   width: auto;
   font-size: 10px;
   align-items: center;
   justify-content: center;
   ${font.bold}
`
const Badge = props => (
   <RxPlBadge>{props.count}</RxPlBadge>
)

export default Badge
 