import { useRef, useState } from 'react'
import Hero from 'shared/components/Hero'
import Footer from 'shared/components/Footer'
import Card from 'shared/components/Card'
import Form from 'shared/components/Form'
import Button from 'shared/components/Button'
import useService from 'shared/hooks/useService'
import ReCAPTCHA from 'react-google-recaptcha'
import {color} from 'shared/theme'
import {RxPlBlueThemeLayout, RxPlHomeLayout, RxPlFormFieldWrapper} from './style'

import saveMoney from './images/save_money.png'
import saveTime from './images/save_time.png'
import care from './images/care.png'
import work from './images/less_work.png'


const HomePage = () => {


   const {api} = useService()
   const [loading, setLoading] = useState(false)
   const [added, setAdded] = useState(false)
   const [verified, setVerified] = useState(null)
   const form = useRef(null)

   const onChange = (value) => {

      setVerified(value)

   }

   const joinWaitlist = async(e) => {

      e.preventDefault()
      if (loading) return

      if (verified == null) return

      setLoading(true)


      const formData = new FormData(form.current)
      formData.delete("g-recaptcha-response")
      const serializeFormData = Object.fromEntries(formData)

      try {

         await api().post("/waitlist",serializeFormData)
         setLoading(false)
         setAdded(true)
         setVerified(null)


      } catch (error) {
         const {response} = error

         if (response && response.data) {
            console.log("ERROR" ,(response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || error)
         }

         setLoading(false)


      }
   }

   return (
      <>

         <RxPlHomeLayout>
            <Hero />
         </RxPlHomeLayout>

         <RxPlHomeLayout background={color.white}  id="signup">
            <RxPlHomeLayout.Content textAlign="center" style={{minHeight: 500}}>
               <h3 style={{fontSize: 38, marginBottom: 20}}>Make Your Everyday Life Easier</h3>
               <p style={{marginBottom: 40, fontFamily: "InterLight"}}>At RxPlace we believe in working smarter, not harder. It shouldn’t be a hassle to find the right drugs for the right price for your patients’ needs. Now, it isn’t! With RxPlace we focus on getting you everything you need so you can focus on the people who need you the most. </p>
               <Card type="signup">

                  {
                     added ? 
                     <>
                        <h3>Join the Waitlist</h3>
                        <p>You have been added to our wait list.</p>

                     </> 
                     :
                     <>
                     <h3>Join the Waitlist</h3>
                     <Form ref={form}>
                        <RxPlFormFieldWrapper>
                           <Form.Field name="FirstName" placeholder="First Name" />
                           <Form.Field name="LastName" placeholder="Last Name" />
                        </RxPlFormFieldWrapper>
                        <RxPlFormFieldWrapper>
                        <Form.Field name="EmailAddress" placeholder="Email Address" />
   
                        </RxPlFormFieldWrapper>
   
                        <RxPlFormFieldWrapper>
                           <Form.Field name="PharmacyName" placeholder="Pharmacy Name" />
                           <Form.Field name="Phone" placeholder="Phone ( optional )" />
                        </RxPlFormFieldWrapper>
                        <RxPlFormFieldWrapper style={{paddingTop: 16}}>
                           <ReCAPTCHA
                              sitekey="6LcQP18jAAAAAJbxG2N2PahMBygcfXAji0AYYQq6"
                              onChange={onChange}
                           />
                        </RxPlFormFieldWrapper>
                        <RxPlFormFieldWrapper>
                           <Button type="outline" onClick={joinWaitlist}>Submit</Button>
                        </RxPlFormFieldWrapper>
                     </Form>
                     </>

                  }

               </Card>
            </RxPlHomeLayout.Content>
         </RxPlHomeLayout>

         <RxPlBlueThemeLayout>
            <RxPlHomeLayout.Content className="rxplace-difference">
               <h2 style={{marginBottom: 24, fontFamily: "InterRegular", fontSize: 38}}>The RxPlace Difference</h2>
               <p style={{marginBottom: 32, fontFamily: "InterLight", fontSize: 16}}>RxPlace is committed to making your life easier so that you can feel confident that you have gotten the best price for what you need so that you are able to get back to helping the people that rely on you to feel their best.</p>
               <RxPlHomeLayout.List display="flex">
                  <RxPlHomeLayout.List.Item display="block">
                     <img src={saveTime} alt="Save Time" />
                     <h5 style={{fontSize: 22}}>Save Time</h5>
                     <p style={{fontSize: 16}}>Using our propriety product search and matching process, we can save you 15+ hours a week in drug searches and countless hours of headache.</p>
                  </RxPlHomeLayout.List.Item>
                  <RxPlHomeLayout.List.Item display="block">
                      <img src={saveMoney} alt="Save Money" />
                      <h5 style={{fontSize: 22}}>Save Money</h5>
                     <p>With competative pricing we help you save 30-50% on the prescription and non-prescription drug purchases that your patients need.</p>
                  </RxPlHomeLayout.List.Item>
                  <RxPlHomeLayout.List.Item display="block">
                      <img src={work} alt="Do Less Work" />
                      <h5 style={{fontSize: 22}}>Do Less Work</h5>
                     <p style={{fontSize: 16}}>Searching multiple platforms to find the best deals are a thing of the past, we do that work for you and put it all together in one place.</p>
                  </RxPlHomeLayout.List.Item>
                  <RxPlHomeLayout.List.Item display="block">
                      <img src={care} alt="Care For You Patients" />
                      <h5 style={{fontSize: 22}}>Care For You Patients</h5>
                     <p style={{fontSize: 16}}>We know your patients rely on the high-touch care that you provide, so now with RxPlace by your side you have the power to  do that more easily.</p>
                  </RxPlHomeLayout.List.Item>
               </RxPlHomeLayout.List>
            </RxPlHomeLayout.Content>
         </RxPlBlueThemeLayout>
         <Footer />

      </>
   )
}


export default HomePage