import { createGlobalStyle } from 'styled-components';
import {font, color} from 'shared/theme'
import {device} from 'shared/layout'

export default createGlobalStyle`
   html, body, #root, #main-wrapper {
      min-height: 100%;
      min-height: calc(var(--vh, 1vh) * 100);
      overflow-x: hidden;
   }

   #main-wrapper {
      position: relative;
   }

   html {
      scroll-behavior: smooth;
   }
   #root {
      overflow-x: hidden;

      @media only screen and ${device.sm} {
         overflow-x: unset;
      }
   }

   html, body {
      background: ${color.white}
   }
   body {
      ${font.size(16)}
      ${font.regular}
   }

   input {
      ${font.size(16)}
      ${font.regular}
   }

   input[type=number] {
	   -moz-appearance: textfield;
   }

   h1,h2,h3,h4,h5,h6, p {
      margin: 0 0 16px;
      padding: 0;
   }
   h1,h2,h3,h4,h5,h6 {
      ${font.bold}
   }

   h1 {
      ${font.size(48)}
      line-height: 56px;
   }

   h2 {
      ${font.size(40)}
      line-height: 48px;
   }

   h3 {
      ${font.size(32)}
      line-height: 40px;
   }

   h4 {
      ${font.size(24)}
      line-height: 32px;
   }


   h5 {
         ${font.size(22)}
         line-height: 24px;
      }

   ul {
      padding: 0;
   }

   .no-margins {
      margin: 0;
   }

   .no-paddings {
      padding: 0;
   }

   .no-vertical-margins {
      margin-top: 0;
      margin-bottom: 0;
   }

   .no-vertical-paddings {
      padding-top: 0;
      padding-bottom: 0;
   }

   .flex-grow-0 {
      flex-grow: 0;
   }

   .unset-flex-basis {
      flex-basis: unset !important;
   }

   .unset-flex-grow {
      flex-grow: unset !important;
   }

   .text-align-center {
      text-align: center;
   }

   .no-stretch {
      align-self: flex-start;
   }

   .text-align-left {
      text-align: center;
   }


   
   @media only screen and ${device.sm} {
      h1 {
         ${font.size(56)}
         line-height: 62px;
      }

      h2 {
         ${font.size(48)}
         line-height: 52px;
      }

      h3 {
         ${font.size(40)}
         line-height: 44px;
      }

      h4 {
         ${font.size(32)}
         line-height: 35px;
      }

      h5 {
         ${font.size(24)}
         line-height: 26px;
      }

      h6 {
         ${font.size(20)}
         line-height: 22px;
      }

      p {
      
         ${font.size(16)}
         line-height: 22px;
      }
   }
`