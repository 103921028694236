import {useContext, useState, useEffect} from 'react'
import Modal from 'shared/components/Modal'
import Topbar from 'shared/components/Topbar'
import Footer from 'shared/components/Footer'
import Button from 'shared/components/Button'
import Spinner from 'shared/components/Spinner'
import ResultList from './ResultList'
import ResultCard from './ResultCard'
import {RxPlContent} from 'shared/layout'
import {PageContext} from 'shared/contexts/PageContext'
import {RxPlSRLayout} from './styles'
import { useHistory, useLocation} from 'react-router-dom'
import {ModalType} from 'shared/constants/modal'
import useService from 'shared/hooks/useService'
import {productResults} from './data/mock'
import moment from 'moment'
import { gaPageview } from 'shared/utils/ga'

// api/product/inventory/smart/code/3

 // /api/product/header/code/00004-0039-09/1/10
 // /api/product/header/search/SYRINGE  3/1/10
const SearchResults = props => {
   const {authenticated, modal, setAuthentication, setModal, debug, resultTerm} = useContext(PageContext)
   const [collapse, setCollapse] = useState(true)
   const [loading, setLoading] = useState(false)
   const [lowCost, setLowCost] = useState({})
   const [lowCostSim, setLowCostSim] = useState({})
   const [lowCostShort, setLowCostShort] = useState({})
   const [regularCostList, setRegularCostList] = useState([])
   const [filterIsOpen, openFilter] = useState(false)
   const {sessionIsAvailable, get} = useService()
   const history = useHistory()
   
  const search = useLocation().search
  const code = new URLSearchParams(search).get('code') ||  new URLSearchParams(search).get('gencode')
  const term = new URLSearchParams(search).get('term')
   // const openFilters = () => {
   //    openFilter(true)
   // }

   // const cancelHandler = () => {
   //    openFilter(false)

   // }

   const getResultBy = (data, category) => {
      if (!sessionIsAvailable()) {
         history.push("/")
         return
      }

      return data
         .filter ( product => product.smartSearchCategory === category)
         .map ( product => { return { 
               inventoryId: product.inventoryId,
               productId: product.productMaster.productId,
               productUID: product.productMaster.productUID,
               providerId: product.provider.providerId,
               providerUID: product.provider.providerUid,
               brandName: product.productMaster.brandName || "Brand name",
               imageFilename: product.productMaster.imageFilename ? "https://d1fvel84160jt0.cloudfront.net/" + product.productMaster.imageFilename + ".JPG" : null,
               // imageFilename: null,
               ndc: product.productMaster.productCode,
               description: product.productMaster.productDescription.replace(/\s+/g, ' ').trim(),
               labeler: product.productMaster.labelerName || "Labaler",
               genericName: product.productMaster.genericName,
               shortName: product.provider.shortName || "Shortname",
               wholeSaler: product.provider.fullName || "Wholesaler",
               dose: product.productMaster.dose,
               dosageForm: product.productMaster.dosageForm,
               productQuantity:  product.productMaster.quantity,
               itemQuantity: product.quantity,
               price: product.price,
               priceStr: product.priceStr,
               shippingOptions: product.shippingOptions,
               expirationDate:moment(product.expirationDate).format("L"),
               categoryCode: product.categoryCode,
               smartSearchCategory: product.smartSearchCategory,
               title: product.smartSearchCategoryDescription
            } } )
   }

   const getProduct = () => {
      if (!sessionIsAvailable()) {
         history.push("/")
         return
      }
     setLoading(true)
     setCollapse(true)
     const endpoint = new URLSearchParams(search).get('code') ? '/product/inventory/smart/code/' : '/product/inventory/smart/brand/'
       get(`${endpoint}${code}${term ? '/' + term : ''}`, 
         res => {

            setLowCost ( 
               getResultBy(res.data, "LOW_COST")[0]
            )
   
            setLowCostSim (
               getResultBy(res.data, "LOW_COST_SIML")[0]
            )
   
   
            setLowCostShort(
               getResultBy(res.data, "LOW_COST_SHORT")[0]
            )

            setRegularCostList(
               getResultBy(res.data, "REGULAR_LIST")
            )

            
            setLoading(false)

         },
         error => {
            console.log(error)
         }
      )
   }

   const mockResults = () => {
      setLowCost(productResults)
      setLoading(false)
   }

   const showError = (message) => {
      setModal({type: ModalType.ERROR, message: message})

   }

   useEffect(()=>{
      if (debug) {
         mockResults()
         return
      }

      if (!sessionIsAvailable()) {
         history.push("/")
         return
      }
      gaPageview("/results", "Search Results")
      setAuthentication(sessionIsAvailable())
      getProduct()


      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [authenticated, code])
   return (
      
      <>
         { modal.type !== "" && <Modal {...modal} /> }
         <Topbar type='results' term={resultTerm} searchPlaceholder="Search by NDC, Product Name or Manufacturer" />

         <RxPlContent>
            <RxPlSRLayout>
               <RxPlSRLayout.Content>
                  {/* <Filters show={filterIsOpen} cancel={cancelHandler}  /> */}
         
                  { loading 
                     ? 
                     <Spinner />
                     :
                     <>
                        {/* <Button type="filter" className="align-right" onClick={openFilters}>Filters</Button> */}
                        {
                           lowCost && 
                           <>
                              <h4>{lowCost.title || 'Your Lowest Cost Product Match'}</h4>
                              <ResultCard data={lowCost}  showError={showError} />

                           </>
                        }

                     {
                           lowCostSim && 
                           <>
                              <h4>{lowCostSim.title || 'Lowest Cost Similar Product Match'}</h4>
                              <ResultCard data={lowCostSim} showError={showError} />

                           </>

                        }


                        {
                           lowCostShort && 
                           <>
                              <h4>{lowCostShort.title || 'Lowest Cost Short Dated Product Match'}</h4>
                              <ResultCard data={lowCostShort} toast="warm"  showError={showError} />
                           </>
                        }


                        {
                           !lowCost && !lowCostShort && !lowCostSim && 
                              <h6 className="empty-message">We're sorry, no products where found for your search. Try new search.</h6>
                        }

                        {
                           regularCostList.length > 0 && 
                           <>
                              <div className="other-results-header">
                                 <h4>{regularCostList[0].title || 'Your Product From Other Wholesalers'}</h4>
                                 <Button onClick={ () => setCollapse(!collapse)}>{`${collapse ? 'Expand' : 'Collapse'}`}</Button>
                              </div>
                              <ResultList collapse={collapse}>
                                 {
                                    regularCostList.map ( (item, index) => <ResultCard key={index} data={item} toast="warm" showError={showError} />)
                                 }
    
                              </ResultList>
                           </>
                        }

                     </>
                  }

               </RxPlSRLayout.Content>
            </RxPlSRLayout>
            
         </RxPlContent>
         <Footer logIn={true} />

      </>
   )
}


export default SearchResults