export const searchByNDC = [
   "4498736345 - Penicillin - 500mg - 100 Tablets - Lone Star",
   "4498736349 - Penicillin - 1000mg - 50 Tablets - Lone Star",
   "4422736346 - Penicillin - 200mg - 20 Tablets - Lone Star",
   "4398736345 - Viorele - 500mg - 100 Tablets - Glenmark",
   "4394436349 - Viorele - 1000mg - 50 Tablets - Glenmark",
   "4322736346 - Viorele - 200mg - 20 Tablets - Glenmark",
   "4598736345 - Budesonide - 500mg - 100 Tablets - Perrigo Co",
   "4594436349 - Budesonide - 1000mg - 50 Tablets - Perrigo Co",
   "4522736346 - Budesonide - 200mg - 20 Tablets - Perrigo Co",
   "4598736345 - Calcitriol - 500mg - 100 Tablets - BIPI",
   "4594436349 - Calcitriol - 1000mg - 50 Tablets - BIPI",
   "4522736346 - Calcitriol - 250mg - 30 Tablets - BIPI",
   "4898736345 - Calcitriol - 500mg - 25 Tablets - Heritage",
   "4894436349 - Calcitriol - 1000mg - 50 Tablets - Heritage",
   "4822736346 - Calcitriol - 250mg - 30 Tablets - Heritage",
   
]


export const searchByProductName = [
   "Penicillin - 500mg - 100 Tablets - Lone Star",
   "Penicillin - 1000mg - 50 Tablets - Lone Star",
   "Penicillin - 200mg - 20 Tablets - Lone Star",
   "Viorele - 500mg - 100 Tablets - Glenmark",
   "Viorele - 1000mg - 50 Tablets - Glenmark",
   "Viorele - 200mg - 20 Tablets - Glenmark",
   "Budesonide - 500mg - 100 Tablets - Perrigo Co",
   "Budesonide - 1000mg - 50 Tablets - Perrigo Co",
   "Budesonide - 200mg - 20 Tablets - Perrigo Co",
   "Calcitriol - 500mg - 100 Tablets - BIPI",
   "Calcitriol - 1000mg - 50 Tablets - BIPI",
   "Calcitriol - 250mg - 30 Tablets - BIPI",
   "Calcitriol - 500mg - 25 Tablets - Heritage",
   "Calcitriol - 1000mg - 50 Tablets - Heritage",
   "Calcitriol - 250mg - 30 Tablets - Heritage",
   
]

export const ShippingOptions = [
   {
      label: "Select Shipping",
      value: ""
   },
   {
      label: "Overnight",
      value: "overnight"
   },
   {
      label: "1 Day Shipping",
      value: "1-day"
   },
   {
      label: "2 Day Shipping",
      value: "2-day"
   }
]