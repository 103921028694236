import { useState} from 'react'
import Button from 'shared/components/Button'
import PropTypes from 'prop-types';
import closeIcon from 'assets/icons/x-icon.svg'

import {
        RxPlBanner,
      
   } from './styles'


const propTypes = {
   isHidden: PropTypes.bool
}

const defaultProps = {
   isHidden: false
}

const CartBanner = props => {
   const {savingsStr} = props
   const [isHidden, setIsHidden] = useState(false)


   if (isHidden) {
      return null
   }

   return (

      <RxPlBanner>
         <Button onClick={ () => setIsHidden(true)} className="close-button"><img src={closeIcon} alt='close icon'/></Button>
          <h6>You can save an additional ${savingsStr} on this order!</h6>
          <p>We’re so excited you found all of the products you need, but guess what, we found even better deals for you for similar products to those you added to your cart.</p>
          <p>At RxPlace, we want you to focus on getting the right products, not how to save money, we’ll do that for you, really, check it out below. The items you found are on the left in “Your Selected Items” list and on the right are the comparable products we found you to save you money.</p>
          <p><span>We can save you an additional ${savingsStr} on this order</span>. Next time you shop, just add your products and we’ll take care of the rest.</p>
      </RxPlBanner>
   )
}

CartBanner.propTypes = propTypes
CartBanner.defaultProps = defaultProps

export default CartBanner