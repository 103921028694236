
import {useContext} from 'react'
import {RxPlCloseButton} from 'shared/layout'
import Spinner from 'shared/components/Spinner'
import {PageContext} from 'shared/contexts/PageContext'
import closeIcon from 'assets/icons/close.svg'

const Loading = props => {
    const {
        setModal,
     }  = useContext(PageContext)
     
    return (
    <>
        <RxPlCloseButton onClick={ () => setModal({type: ""})}>
           <img src={closeIcon} alt="close icon" />
        </RxPlCloseButton>
         <Spinner />
     </>
    )
}


export default Loading