import {useContext, useRef, useState, useEffect} from 'react'
import Card from 'shared/components/Card'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import Checkbox from 'shared/components/Checkbox'
import {RxPlSignupBase, RxPlFormLayout, RxPlFormGroup, RxPlFormContentCenterWrapper, RxPlFormButtonWrapper, RxPlFormGroupWrapper} from './style'
import {stateList} from 'shared/data/states-list'
import {PageContext} from 'shared/contexts/PageContext'
import useService from 'shared/hooks/useService'
import { gaPageview, gaSignUpProgress } from 'shared/utils/ga'

const Billing = props => {
   const {customer, setCustomer} = useContext(PageContext)
   // const [shipping] = useState(customer.shipping)
   const [errorMesage, setErrorMessage] = useState("")
   const [stateValidity, setStateValidity] = useState(false)
   const {api} = useService()
   const form = useRef(null)
   const inputAdd = useRef(null)
   const inputCity = useRef(null)
   const inputState = useRef(null)
   const inputZipcode = useRef(null)
   const [shipping, setShipping] = useState(null)
   const [zip, setZipCode] = useState("")
   const [validZip, setValidZip] = useState(false)
   const [billingId, setBillingId] = useState(null)
   const [fetching, setFetching] = useState(false)
   const [fetchingShipping, setFetchingShipping] = useState(false)

   const createCustomerBilling = async () => {
      const formData = new FormData(form.current)
      const serializeFormData = Object.fromEntries(formData)


      try {

         const savedToken = localStorage.getItem("token")
         const customerId = localStorage.getItem("customerId") || JSON.parse(localStorage.getItem("contact")).customerId || (customer.profile && customer.profile.customerId) 

         let response = billingId ? await api(savedToken).put("/customermailing/" + billingId, {...serializeFormData,
            customerId: parseInt(customerId)
            }) 
            : 
            await api(savedToken).post("/customermailing", {...serializeFormData,
         customerId: parseInt(customerId)
         })
         setCustomer({...customer,
            mailing: response.data
        })
        localStorage.setItem('shipping', JSON.stringify(serializeFormData));
        localStorage.setItem('onboarding', 'license')
        props.handleNextStep("license")
        gaSignUpProgress('billing', customerId)

        setErrorMessage("")

      } catch ({response}) {

         const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         setErrorMessage(errorMessage)

      }
   }


   const getCustomerBilling = async (callback) => {
      if (fetching) return
      setFetching(true)
 
      try {
         const savedToken = localStorage.getItem("token")
         const customerId = localStorage.getItem("customerId") || JSON.parse(localStorage.getItem("contact")).customerId || (customer.profile && customer.profile.customerId) 

         let response = await api(savedToken).get("/customermailing/customer/" + customerId)
         setCustomer({...customer,
            mailing: response.data
        })

        if (typeof callback === 'function') {
         callback(response.data.data)
        }
        setFetching(false)

      } catch ({response}) {
         setFetching(false)

         const errorMessage = (response && response.data && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         if (response.status !== 400 && response.status !== 401 && response.status !== 404) {
            setErrorMessage(errorMessage)
         }

      }
   }

   const getCustomerShipping = async(callback) => {
      if (fetchingShipping) return
      setFetchingShipping(true)
      const savedToken = localStorage.getItem("token")
      if (!savedToken) return
      const customerId = localStorage.getItem("customerId") || JSON.parse(localStorage.getItem("contact")).customerId || (customer.profile && customer.profile.customerId) 
      try {

         let response = await api(savedToken).get("/customershipping/customer/" + customerId)

         if (typeof callback === 'function') {
            callback(response.data.data)
         }
         setFetchingShipping(false)
         setErrorMessage("")

      } catch ({response}) {
         setFetchingShipping(false)
         const errorMessage = (response && response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         if (response.status !== 400 && response.status !== 401 && response.status !== 404) {
            setErrorMessage(errorMessage)
         }



      }
   }


   const nextStepHandler = e => {
      e.preventDefault()
      if (!validZip) {
         setErrorMessage("Please enter a valid ZIP Code.")
         return
      }
      createCustomerBilling()
   }

   const setBillingToShipping = e => {
      const checked = e.target.checked

      const currentShipping = shipping || JSON.parse(localStorage.getItem("shipping"))

      inputAdd.current.value = (checked && currentShipping) ? currentShipping.address1 : ""
      inputCity.current.value = (checked && currentShipping) ? currentShipping.city : ""
      inputState.current.value = (checked && currentShipping) ? currentShipping.state : ""
      inputZipcode.current.value = (checked && currentShipping)  ? currentShipping.zipCode : ""
      setZipCode((checked && currentShipping) ? currentShipping.zipCode : "")

      setValidZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(currentShipping.zipCode))

      setStateValidity(inputState.current.value !== "")

   }

   const onStateChange = e => {
      const currentValue = e.target.value
      setStateValidity(currentValue !== "")
   }

   const prevStepHandler = e => {
      e.preventDefault()
      props.handleNextStep("shipping")

   }

   const onZipChanged = e => {
      if (e.target.value.length > 5) return
      setZipCode(e.target.value)
      setValidZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(e.target.value))
   }

   useEffect(()=>{
      let mounted = true
      const enterKeyListener = e => {
         if (e.code === "Enter" || e.code === "NumpadEnter" || e.keyCode === 13) {
            e.preventDefault()
            if (mounted) {
               if (!validZip) {
                  setErrorMessage("Please enter a valid ZIP Code.")
               
               } else {
                  createCustomerBilling()

               }
            }
         }
      }
      if (!billingId) {
         getCustomerBilling((data)=> {
            const {address1, city, state, zipCode, customerMailingId} = data

            inputAdd.current.value = address1
            inputCity.current.value = city
            inputState.current.value = state
            inputZipcode.current.value = zipCode
            setZipCode(zipCode)
      
            setValidZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode))
      
            setStateValidity(state !== "")
            setBillingId(customerMailingId)

         })

         getCustomerShipping(data => {
            const {address1, city, state, zipCode} = data
            setShipping({
               address1,
               city,
               state,
               zipCode
            })
         })
      }

      gaPageview("/billing", "Sign up - Billing")

      document.addEventListener("keydown", enterKeyListener)
      return () => {
        document.removeEventListener("keydown", enterKeyListener);
        mounted = false
      }
   },[])

   return (
         <RxPlSignupBase>
            <RxPlSignupBase.Content>

               <RxPlFormLayout ref={form}>
                  {
                     errorMesage !== "" && <p className="error-message">Error: {errorMesage} </p>

                  }
                  <RxPlFormContentCenterWrapper>
                  <Card>
                     <div className="header">
                      <Checkbox onChange={setBillingToShipping} id="billingToShipping" name="billingToShipping" label="Billing Address is same as Shipping Address"/>
                      <p>* = Required field</p>

                     </div>
                     <RxPlFormGroup>
                        <label>Street Address *</label>
                        <Input ref={inputAdd} name="address1" placeholder="Street Address"  required minlength="4" />
                     </RxPlFormGroup>


                     <RxPlFormGroupWrapper>
                     <RxPlFormGroup>
                        <label>City *</label>
                        <Input ref={inputCity} name="city" placeholder="City" required minlength="3" />
                     </RxPlFormGroup>
                     <RxPlFormGroup>
                        <label>State *</label>
                        <Select className={`${stateValidity === true ? '' : 'invalid'}`} ref={inputState} onChange={onStateChange} name="state" data={stateList} required  minlength="2" />
                     </RxPlFormGroup>
                     </RxPlFormGroupWrapper>

                     <RxPlFormGroup>
                        <label>Zipcode *</label>
                        
                        <Input ref={inputZipcode}  className={`${validZip === true ? '' : 'invalid'}`} name="zipCode" placeholder="91800" type="number" pattern="^[0-9]{5}(?:-[0-9]{4})?$" required value={zip} onChange={onZipChanged}/>

                     </RxPlFormGroup>
                  </Card>  
                  </RxPlFormContentCenterWrapper>
                   <RxPlFormButtonWrapper>
                      <Button type="outline" onClick={prevStepHandler}>Go to Previous Screen</Button>
                      <Button onClick={nextStepHandler} type="primary">Save and Continue</Button>
                   </RxPlFormButtonWrapper>
               </RxPlFormLayout>
             
            </RxPlSignupBase.Content>

         </RxPlSignupBase>
   )
}


export default Billing
