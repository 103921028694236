export const Icon24 = {
   DOC: 'doc',
   USER: 'user',
   BOX: 'box',
   LOGOUT: 'logout'
 }

 export const Icon32 = {
   NOTIFICATION: 'notification',
   CART: 'cart',
   PROFILE: 'profile'
 }

 export const IconRegular = {
  FORWARD: 'forward',
  CANCEL: 'cancel',
  EDIT: 'edit',
  LICENSE: 'license',
  PROFILE: 'profile',
  BAR: 'bar',
  ORDER: 'order',
  INVENTORY: 'inventory',
  HOME: 'home',
  SHOP: 'shop',
  MESSAGE: 'message',
  MENU: 'menu',
  LOCK: 'lock',
  UNLOCK: 'unlock',
  CLOSE: 'close'
}