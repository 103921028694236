import {useContext, useEffect, useState} from 'react'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'

import {PageContext} from 'shared/contexts/PageContext'
import {ModalType} from 'shared/constants/modal'
import useService from 'shared/hooks/useService'
import moment from 'moment'

import axios from 'axios'
import {
   RxPlSignupBase,
   RxPlFormLayout, 
   RxPlFormGroup, 
   RxPlFormButtonWrapper, 
   RxPlLicensingCard
} 
from './style'
import { gaPageview, gaSignUpProgress } from 'shared/utils/ga'


const Licensing = props => {

   const {setModal, customer, setCustomer} = useContext(PageContext)
   const {uploadAPI, api} = useService()

   const [errorMessage, setErrorMessage] = useState("")
   const [deaLicenseNumber, setDeaLicenseNumber] = useState('')
   const [deaExpirationDate, setDeaExpirationDate] = useState({})
   const [deaUploadForm, setDeaUploadForm] = useState({})


   const [stateLicenseNumber, setStateLicenseNumber] = useState('')
   const [stateExpirationDate, setStateExpirationDate] = useState('')
   const [stateUploadForm, setStateUploadForm] = useState({})
   const [fetching, setFetching] = useState(false)
   const [creating, setCreating] = useState(false)
   const [stateLicenseId, setStateLicenseId] = useState(null)
   const [deaLicenseId, setdeaLicenseId] = useState(null)
   const [agreement, setAgreement] = useState(false)
   const [updatingAgreement, setUpdatingAgreement] = useState(false)
   const onDeaLicenseNumberChange = e => {
      setErrorMessage("")
      setDeaLicenseNumber(e.target.value)
   }

   const onDeaExpirationDateChange = e => {
      setErrorMessage("")
     setDeaExpirationDate(e.target.value)
   }

   const onDeaUploadFormChange = e => {
      setErrorMessage("")
      setDeaUploadForm(e.target.files[0])
   }


   const onStateLicenseNumberChange = e => {
      setStateLicenseNumber(e.target.value)
   }

   const onStateExpirationDateChange = e => {
      setErrorMessage("")
      setStateExpirationDate(e.target.value)
   }

   const onStateUploadFormChange = e => {
      setErrorMessage("")
      setStateUploadForm(e.target.files[0])
   }

   const onAgreementChange = confirm => {
      console.log(confirm)
      setAgreement(confirm)
   }

   const updateCustomerAgreement = async(agreementAcknowledge) => {
      if (updatingAgreement || agreementAcknowledge === false) return
      setUpdatingAgreement(true)
      try {
         const savedToken = localStorage.getItem("token")
         const customerId = localStorage.getItem("customerId") 

         await api(savedToken).put("/customer/agreement/" + customerId, {agreementAcknowledge}) 
         setModal({type: ""})
         localStorage.setItem('onboarding', 'review')
         props.handleNextStep("review")
         gaSignUpProgress('license', customerId)
         setUpdatingAgreement(false)

      } catch ({response}) {
         const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         if (response && response.status !== 400 && response && response.status !== 401 && response && response.status !== 404) {
            setErrorMessage(errorMessage)
         }
      }
   }





   const nextStepHandler = e => {
      if ( e) {
         e.preventDefault()
      }  

      if (creating) return
      setCreating(true)

      const customerId = localStorage.getItem("customerId") || null
     // createCustomerLicenses()
     let deaFormData = new FormData()
     deaFormData.append("CustomerId", customerId)
     deaFormData.append("LicenseDocType" , 'document')
     deaFormData.append("LicenseNumber" , deaLicenseNumber)
     deaFormData.append("LicenseType" , 'DEA')
     deaFormData.append("ExpirationDate", deaExpirationDate)
     deaFormData.append('UploadedForm', deaUploadForm)
     if (deaLicenseId) {
      deaFormData.append('LicenseId', deaLicenseId)
     }


     let stateFormData = new FormData()
     stateFormData.append("CustomerId", customerId)
     stateFormData.append("LicenseDocType" , 'document')
     stateFormData.append("LicenseNumber" , stateLicenseNumber)
     stateFormData.append("LicenseType" , 'STATE')
     stateFormData.append("ExpirationDate", stateExpirationDate)
     stateFormData.append('UploadedForm', stateUploadForm)
     if (stateLicenseId) {
      stateFormData.append('LicenseId', stateLicenseId)

     }

      const deaRequest = deaLicenseId && stateLicenseId ? uploadAPI(deaFormData._boundary).put("/CustomerLicense/" + deaLicenseId, deaFormData) : uploadAPI(deaFormData._boundary).post("/CustomerLicense", deaFormData)
      const stateRequest = stateLicenseId && deaLicenseId ? uploadAPI(deaFormData._boundary).put("/CustomerLicense/" + stateLicenseId, stateFormData) : uploadAPI(stateFormData._boundary).post("/CustomerLicense", stateFormData)
      axios.all([deaRequest, stateRequest]).then(axios.spread((...responses) => {
         const deaResponse = responses[0]
         const stateResponse = responses[1]


         setCustomer({...customer,
            deaLicense: deaResponse.data,
            stateLicense: stateResponse.data
         })

         localStorage.setItem('deaLicense', JSON.stringify(deaResponse.data));
         localStorage.setItem('stateLicense', JSON.stringify(stateResponse.data));

         localStorage.setItem("onboarding", "agreement")

         setCreating(false)
         setModal({type: ModalType.USER_AGREEMENT, data: {onSubmit: updateCustomerAgreement, onAgreementChange, agreement}})

         // use/access the results 
       })).catch(error => {
         const {response} = error
         const errorMessage =  (response.data && response.data.messages && response.data.messages.toString()) || (response.data.errors && response.data.errors.UploadedForm && response.data.errors.UploadedForm.toString()) || (response && response.data && response.data.title)
         if (typeof error.response === 'undefined') {
            setErrorMessage("Something went wrong")
         } else {
            setErrorMessage(errorMessage)
         }
         setCreating(false)

       })
   }

   const getCustomer = async(callback) => {
      try {
         const savedToken = localStorage.getItem("token")

         let response = await api(savedToken).get("/customer/profile")
         const {customerId, fullName, shortName, uid, contactName, dba, title, officeNumber, mobileNumber, agreementAcknowledge} = response.data.data
         const profile = {
            customerId,
            fullName,
            shortName,
            uid,
            contactName,
            dba,
            title,
            mobileNumber,
            officeNumber,
            agreementAcknowledge
         }

         if (typeof callback !== 'undefined') {
            callback(profile)
         }


      } catch ({response}) {
         const errorMessage =  (response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         setErrorMessage(errorMessage)

      }
   }

   const getLicenses = async(callback) => {
      if (fetching) return

      setFetching(true)
      const savedToken = localStorage.getItem("token")
      const customerId = localStorage.getItem("customerId") 
      try {
         const response = await api(savedToken).get("/customerlicense/customer/" + customerId)

         if (typeof callback === 'function') callback(response.data.data)
         setFetching(false)

      } catch ({response}) {
         setFetching(false)

         const errorMessage =  (response && response.data && response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Something went wrong"
         if (response && response.status !== 400 && response && response.status !== 401 && response && response.status !== 404) {
            setErrorMessage(errorMessage)
         }

      }
   }


   const prevStepHandler = e => {
      e.preventDefault()
      props.handleNextStep("billing")

   }


   useEffect(()=>{
      let mounted = true
      const enterKeyListener = e => {
         if (e.code === "Enter" || e.code === "NumpadEnter" || e.keyCode === 13) {
            e.preventDefault()
            if (mounted) {
               nextStepHandler()
            }
         }
      }

      if (localStorage.getItem("onboarding") === 'agreement') {
         setModal({type: ModalType.USER_AGREEMENT, data: {onSubmit: updateCustomerAgreement, onAgreementChange, agreement}})
      }

      getLicenses((data) => {
         const deaLicense = data.filter ( item => item.licenseType === 'DEA' )[0]
         const stateLicense = data.filter ( item => item.licenseType === 'STATE')[0]


         setdeaLicenseId(deaLicense.licenseId)
         setStateLicenseId(stateLicense.licenseId)

         setDeaLicenseNumber(deaLicense.licenseNumber)
         setDeaExpirationDate(moment(deaLicense.expirationDate).format("YYYY-MM-DD"))

         setStateLicenseNumber(stateLicense.licenseNumber)
         setStateExpirationDate(moment(stateLicense.expirationDate).format("YYYY-MM-DD"))
  
      })

      getCustomer( ({agreementAcknowledge}) => {
         setAgreement(agreementAcknowledge)
      })

      gaPageview("/license", "Sign up - Licenses")

      document.addEventListener("keydown", enterKeyListener)
      return () => {
        document.removeEventListener("keydown", enterKeyListener);
        mounted = false
      }
   },[])

   return (
         <RxPlSignupBase>
            <RxPlSignupBase.Content>

               {
                  creating && <Modal type={ModalType.UPLOADING} />

               }
               <RxPlFormLayout>
               {
                     errorMessage !== "" && <p className="error-message">Error: {errorMessage}</p>

                  }
                  <RxPlLicensingCard>
                  <p>* = Required field</p>

                     <RxPlLicensingCard.Item>
                        <RxPlFormGroup>
                           <label>DEA License Number*</label>
                           <Input name="DEALicenseNumber" pattern="[a-zA-Z0-9-]+" placeholder="License Number" value={deaLicenseNumber} required onChange={onDeaLicenseNumberChange} />
                        </RxPlFormGroup>

                        <RxPlFormGroup>
                           <label>Expiration Date *</label>
                           <Input name="DEAExpirationDate" placeholder="MM/DD/YYYY" type="date" value={deaExpirationDate} required onChange={onDeaExpirationDateChange} />
                        </RxPlFormGroup>

                        <RxPlFormGroup>
                           <label>Upload License * <span>(max file size 3 MB)</span></label>
                           <Input name="DEAUploadedForm" type="file" required onChange={onDeaUploadFormChange} />
                        </RxPlFormGroup>

                     </RxPlLicensingCard.Item>

                     <RxPlLicensingCard.Item>
                        <RxPlFormGroup>
                           <label>State Board of Pharmacy License Number*</label>
                           <Input name="StateLicenseNumber" pattern="[a-zA-Z0-9-]+" placeholder="License Number" value={stateLicenseNumber} required onChange={onStateLicenseNumberChange} />
                        </RxPlFormGroup>

                        <RxPlFormGroup>
                           <label>Expiration Date *</label>
                           <Input name="StateExpirationDate" placeholder="MM/DD/YYYY" type="date" value={stateExpirationDate} required onChange={onStateExpirationDateChange} />
                        </RxPlFormGroup>

                        <RxPlFormGroup>
                           <label>Upload License * <span>(max file size 3 MB)</span></label>
                           <Input name="StateUploadedForm" type="file" required onChange={onStateUploadFormChange} />
                        </RxPlFormGroup>

                     </RxPlLicensingCard.Item>

                  </RxPlLicensingCard>  
                   <RxPlFormButtonWrapper>
                      <Button type="outline" onClick={prevStepHandler}>Go to Previous Screen</Button>
                      <Button onClick={nextStepHandler} type="primary">Save and Continue</Button>
                   </RxPlFormButtonWrapper>
               </RxPlFormLayout>
             
            </RxPlSignupBase.Content>

         </RxPlSignupBase>
   )
}


export default Licensing
