import styled from 'styled-components'
import Card from 'shared/components/Card'

import {color, font} from 'shared/theme'
import {RxPlLayout} from 'shared/layout'
import {device} from 'shared/layout'

export const RxPlCheckoutLayout = styled(RxPlLayout)`
`

export const RxPlCheckoutHeader = styled.div`
   display: flex;
   margin-bottom: 56px;

   h5 {
      margin-bottom: 0;
      font-size: 30px;
      color: ${color.sky};
      span {
         font-size: 24px;
         color: ${color.primary};
      }
   }
   button {
      width: 276px;
      margin-left: auto;
      margin-right: 32px;
      ${font.medium}
   }
`

export const RxPlCheckoutCard = styled(Card)`
   width: 100%;
   margin-bottom: 72px;
   padding: 24px;

   h4 {
      margin: 8px 0 32px;
   }
`

export const RxPlCheckoutContainer = styled.div`
   width: 100%;
   flex-direction: column-reverse;
   display: flex;

   @media only screen and ${device.sm} {
      flex-direction: unset;

   }
`

export const RxPlCartList = styled.div`
   flex-grow: 1;
   margin-right: 0;

   @media only screen and ${device.sm} {
      margin-right: 40px;
      
   }
`


export const RxPlCheckoutSummary = styled.div`
   flex-basis: 456px;

   > div {
      width: 100%;
   }
`
export const RxPlRow = styled.div`
   display: flex;
   margin-bottom: 32px;
   h6 {
      margin-right: 32px;
      margin-bottom: 0;
      line-height: 22px;
   }

   p {
      margin-bottom: 0;
   }

   .price {
      margin-left: auto;
      color: ${color.success};
   }

   .align-right {
      margin-left: auto;
   }

   .text-align-center{
      text-align: center;
   }

   .wholesaler-text-total {
      font-size: 24px;
      ${font.bold};
      color: ${color.sky};
   }
`