import React from 'react'
import Routes from './Routes'

const App = () => (
   <>
         <Routes />
   </>
)

export default App
