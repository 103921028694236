import styled from 'styled-components'
import {color} from 'shared/theme'

export const RxPlPagination = styled.ul`
   position: relative;
   list-style-type: none;
    display: flex;
    gap: 12px;
    margin: 24px auto 0;
    width: fit-content;
   li {
     width: 16px;
     height: 16px;
     flex-shrink: 0;
     background-color: white;
     border-radius: 50%;
     border: 1px solid ${color.blue};
     cursor: pointer;

     &.active {
       background-color: ${color.blue};
     }
   }
`