export const parseJwt = (token) => {
    if (token == null) return
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const debounce = (func, wait) => {
    let timeout = 0;
 
    return (...args) => {
       const later = () => {
          clearTimeout(timeout);
          timeout = null
          func(...args);
       }
 
       clearTimeout(timeout);
       timeout = setTimeout(later, wait);
    }
 
 }

 
export const clamp = (num, min, max) => {
   return num <= min ? min : num >= max ? max : num;
}


 export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
 
// export const isNDC = code =>  /\d{4,5}-\d{3,4}-\d{1,2}/g.test(code)
export const isNDC = (term) =>  /^[\d-]+$/.test(term)


export const dataFormatter = (data) => {
   return data
   .map ( product => { return { 
         inventoryId: product.inventoryId,
         productId: product.productMaster.productId,
         productUID: product.productMaster.productUID,
         providerId: product.provider.providerId,
         providerUID: product.provider.providerUid,
         brandName: product.productMaster.brandName || "Brand name",
         ndc: product.productMaster.productCode,
         description: product.productMaster.productDescription.replace(/\s+/g, ' ').trim(),
         imageFilename: product.productMaster.imageFilename ? "https://d1fvel84160jt0.cloudfront.net/" + product.productMaster.imageFilename + ".JPG" : null,
         labeler: product.productMaster.labelerName || "Labaler",
         genericName: product.productMaster.genericName,
         shortName: product.provider.shortName || "Shortname",
         wholeSaler: product.provider.fullName || "Wholesaler",
         dose: product.productMaster.dose,
         dosageForm: product.productMaster.dosageForm,
         productQuantity:  product.productMaster.quantity,
         itemQuantity: product.quantity,
         price: product.price,
         priceStr: product.priceStr,
         shippingOptions: product.shippingOptions,
         categoryCode: product.categoryCode,
         smartSearchCategory: product.smartSearchCategory
      } } )
}