import styled from 'styled-components'

export const RxCarouselContainer = styled.div`
   position: relative;
   overflow: hidden;
   padding: 24px 0 24px;

   h3 {
      font-size: 32px !important;
      text-align: left;
      margin-left: 11px;
   }

   .container-wrapper {
      display: flex;
      flex-wrap: no-wrap;
      transition: transform 1s cubic-bezier(.2,.84,.5,1);
   }

   &.theme-blue {
      background-color:#6080BB;
      border-radius: 8px;
      h3 {
         color: white;
      }
   }

`