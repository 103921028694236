import styled from 'styled-components'
import {color} from 'shared/theme'
import {device} from 'shared/layout'

export const RxPlSidebar = styled.div`

   min-width: unset;
   width: 100%;
   height: auto;
   position: fixed;
   left: auto;
   top: auto;
   background: ${color.white};
   z-index: 10;

   h5 {
      margin: 0;
   }


   @media only screen and ${device.sm} {

      min-width: 310px;
      width: unset;
      height: 100%;
      left: 0;
      top: 80px;
      background: ${color.white};
   
   }
`

RxPlSidebar.Header = styled.div`
   border-bottom: 1px solid ${color.grayLight};
   padding: 20px 24px;
   position: relative;

   i[type=close],
   i[type=menu] {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      display: block;
   }

   @media only screen and ${device.sm} {
      padding: 20px 32px;

      i[type=close],
      i[type=menu] {
         display: none;
      }

   
   }
`

RxPlSidebar.Menu = styled.ul`
   list-style: none;
   margin: 0;
   display: none;

   &.open {
      display: block;
      border-bottom: 1px solid #BDBDBD;

   }
   @media only screen and ${device.sm} {
      display: block;
   }
`

RxPlSidebar.MenuItem = styled.li`
   padding: 16px 24px;
   font-size: 18px;
   line-height: 24px;
   display: flex;
   align-items: center;
   cursor: pointer;
   i {
      margin-right: 24px;
   }

   &.active {
       background: ${color.backgroundSearch}
   }
   @media only screen and ${device.sm} {
      padding: 16px 32px;

   &:active {
       background: ${color.backgroundSearch}
   }

   }
`