import React from 'react'
import styled from 'styled-components'
import {color} from 'shared/theme'

const RxPlSpinner = styled.div`
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: round-dots 1.8s infinite ease-in-out;
  animation: round-dots 1.8s infinite ease-in-out;

  font-size: .625rem;
  margin: 0 auto;
  position: relative;
  text-indent: -9999rem;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  
   &:after,
   &:before {
      border-radius: 50%;
      width: 1rem;
      height: 1rem;

      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation: round-dots 1.8s infinite ease-in-out;
      animation: round-dots 1.8s infinite ease-in-out; 
  }

  &:before {
   left: -2rem;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  }


  &:after {
   left: 2rem;
  }

  &:after,
   &:before {
      content: '';
  position: absolute;
  top: 0;
   }


   @-webkit-keyframes round-dots {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5625rem 0 -.8125rem ${color.sky};
  }
  40% {
    box-shadow: 0 1.5625rem 0 0 ${color.sky};
  }
}
@keyframes round-dots {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5625rem 0 -.8125rem ${color.sky};
  }
  40% {
    box-shadow: 0 1.5625rem 0 0 ${color.sky};
  }
}
`

const Spinner = props => (
   <RxPlSpinner className="spinner" />
)

export default Spinner
