import styled from 'styled-components'
import Card from 'shared/components/Card'
import { color, font} from 'shared/theme'

export const RxPlSummaryCard = styled(Card)`
   width: 100%;
   padding: 24px;
   margin-bottom: 90px;

   h6 {
      position: relative;
      span {
         position: absolute;
         right: 0;
         color: ${color.sky};
      }
   }
   hr {
      height: 1px;
      background-color:  ${color.grayLight};
      border: none;
      margin: 16px 0;
   }

   button {
      margin: 16px auto;
      display: block;
      min-width: 76%;
   }

   .title {
      font-size: 16px;
      ${font.bold};
      margin: 8px 0;

      &.regular {
         ${font.regular}
      }
   }

   .indent {
      margin-bottom: 0;
      margin-left: 16px;
   }

   .amount-save {
      color: ${color.sky};
      font-weight: bold;
      text-align: center;
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 16px;
      margin-top: 0;
      strong {
         ${font.bold}
      }
   }

   p {
      line-height: 1.3em;

      strong {
         ${font.bold}
      }

   }

   .mb-0 {
      margin-bottom: 0;
   }


`

export const RxPlSummaryItemRow = styled.div`
   display: flex;
   align-items: center;
   .align-right {
      margin-left: auto;
   }

   p {
      margin-top: 4px;
      margin-bottom: 4px;
   }


`

