import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'

import reportWebVitals from './reportWebVitals';

let root = document.getElementById('root');

if (root) {

  // const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
	// if (path) {
	// 	history.replace(path);
	// }


  ReactDOM.render(
    <React.StrictMode>
      <App />

    </React.StrictMode>,
    root
  );
  
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
