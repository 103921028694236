import styled, {css} from 'styled-components'
import {color} from 'shared/theme'
import solidIcon from 'assets/icons/select_down_icon.svg'

export const RxPlSelect = styled.select`
   -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
   color: ${color.grayPrimary};
   font-size: 16px;
   padding: 16px 48px 16px 24px;
   border-radius: $border-radius;
   border: 1px solid ${color.grayLight};
   border-radius: 4px;
   max-height: 52px;

   ${
    props => props.iconType === 'solid' && 
    css`
      background: ${color.backgroundSearch} url(${solidIcon}) no-repeat !important;
      background-position: 80% center !important;
   `

  }


   &:focus {
      outline: none;
   }

   &.invalid,
   &:disabled,
   &:invalid {
      border: 1px solid ${color.error};

   }

   
   option {
      background: $color-white;
   }
`