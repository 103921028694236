import styled from 'styled-components'
import {color} from 'shared/theme'
import Card from 'shared/components/Card'



export const RxPlBanner = styled(Card)`
    padding: 24px 42px;
    margin-bottom: 42px;
    position: relative;

    h6 {
        color: ${color.sky};
        font-size: 24px;
    }
    p {
        span {
            color: ${color.sky};
            font-weight: bold;
        }
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
    }
`
