import styled from 'styled-components'
import {color} from 'shared/theme'

export const RxPlTextarea = styled.div`
   width: 100%;

   textarea {
    overflow-y: hidden;
    width: 100%;
    color: ${color.grayPrimary};
    padding: 16px;
    border-radius: 4px;
    margin: 0;
    background: ${color.backgroundSearch};
    border: 1px solid ${color.grayLight};
    resize: none;
  }
`;