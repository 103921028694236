import { ModalType } from "shared/constants/modal";

export const gaPageview = (path, title) => {
  window.gtag('event', 'page_view', {
    page_title: title,
    page_path: path,
    send_to: 'G-N8SNRH09E0'
  })
}

export const gaSearch = (ndc) => {
  window.gtag('event', 'search', {
    'event_category' : 'engagement',
    'event_label' : 'search_term',
    'ndc': ndc
  });
}

export const gaAddToCart = (product) => {

  window.gtag('event', 'add_to_cart', {
    'event_category' : 'ecommerce',
    'event_label' : 'Add to cart',
    'product': {
      'inventoryId': product.inventoryId,
      'productCode': product.productCode,
      'price': product.price,
      'quantity': product.quantity
    }
  });
}

export const gaPlaceOrder = (cartUID) => {

  window.gtag('event', 'place_order', {
    'event_category' : 'ecommerce',
    'event_label' : 'Customer Complete order',
    'cartUID': cartUID
  });
}

export const gaModal = (name) => {

  if (name === ModalType.ERROR || name === ModalType.LOADING || name === "") return

  window.gtag('event', 'open_modal', {
    'event_category' : 'engagement',
    'event_label' : 'Open modal',
    'value': name
  });
}

export const gaSignin = (customerId) => {
  if (typeof customerId === 'undefined') return

  window.gtag('event', 'user_signin', {
    'event_category' : 'customer successful authentication',
    'event_label' : 'authentication',
    'customerId': customerId
  });
}

export const gaSignUp = (customerId) => {
  if (typeof customerId === 'undefined') return

  window.gtag('event', 'user_signup_successful', {
    'event_category' : 'customer successful registration',
    'event_label' : 'successful registration',
    'customerId': customerId
  });
}

export const gaSignUpProgress = (progress, customerId) => {
  if (typeof progress === 'undefined') return

  window.gtag('event', 'user_signup_progress', {
    'event_category' : 'customer currrent registration progress',
    'event_label' : 'registration progress',
    'customerId': customerId || '',
    'progress': progress 
  });
}







