import styled from 'styled-components'
import Card from 'shared/components/Card'

import {color, font} from 'shared/theme'

export const RxPlGroupCard = styled(Card)`
   width: 100%;
   margin-bottom: 72px;
   padding: 24px;

   h4 {
      margin: 8px 0 32px;
   }

   h6 {
      font-size: 20px;
   }

   .save-btn {
      display: block;
      margin: 24px 0 0 auto;
   }
`


export const RxPlRow = styled.div`
   display: flex;
   margin-bottom: 32px;
   h6 {
      margin-right: 32px;
      margin-bottom: 0;
      line-height: 22px;
      
   }

   p {
      margin-bottom: 0;
   }


   .price {
      margin-left: auto;
      color: ${color.success};
   }

   .align-right {
      margin-left: auto;
   }

   .text-align-center{
      text-align: center;
   }

   .wholesaler-text-total {
      font-size: 24px;
      ${font.bold};
      color: ${color.sky};
   }
`