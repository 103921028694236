import React, {forwardRef} from 'react'
import {RxPlCard, RxPlProductCard, RxPlCardSignup} from './style'


const Card = forwardRef( (props, ref) => (
   <>
       {
         props.type == null && 
      
         <RxPlCard {...props} ref={ref}>
            {props.children}
         </RxPlCard>

       }
      {
         props.type === 'product' &&
         <RxPlProductCard onClick={props.onClick} ref={ref}>
            <img src={props.thumbnail} alt="Product" />
            <RxPlProductCard.Manufacturer>
               {props.manufacturer}
            </RxPlProductCard.Manufacturer>
            <RxPlProductCard.Title>
               {props.name}
            </RxPlProductCard.Title>
            <RxPlProductCard.Qty>
               QTY: {props.qty}
            </RxPlProductCard.Qty>
            <RxPlProductCard.Price>
               ${props.cost}
            </RxPlProductCard.Price>
         </RxPlProductCard>

      }


      {
         props.type === "signup" && 
         <RxPlCardSignup  {...props} ref={ref}>
            {props.children}
         </RxPlCardSignup>
      }
   </>
))

export default Card
