import React from 'react'
import rxLogo from './logo.png'
import rxShortLogo from './logo_short.png'
import styled from 'styled-components'
import {device, size} from 'shared/layout'

const RxPlLogo = styled.picture`
   img {
      height: 40px;
      margin-right: 24px;
      cursor: pointer;
   }

  @media only screen and ${device.sm} {
      img {
         height: 30px;
      }

  }

`;
const Logo = props => (
   <span>
      <RxPlLogo onClick={props.onClick}>
         <source 
         media={`(min-width: ${size.sm})`}
         srcSet={rxLogo} />
      <source 
         media={`(min-width: ${size.xs})`}
         srcSet={rxShortLogo} />
           <img src={rxLogo} alt="rx logo" />
      </RxPlLogo>
   </span>
)

export default Logo
