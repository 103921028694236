import Button from 'shared/components/Button'
import { useHistory} from 'react-router-dom'

import {
      RxPlThankYouCard,
   } from './styles'

const propTypes = {
}

const defaultProps = {
}


const ThankYou = props => {
   const history = useHistory()

   return (

      <>
         <RxPlThankYouCard textAlign="center">
            <h1>Thank you for your order!</h1>
            <p>We sent a reciept to {props.email}</p>
            <Button type="primary" onClick={()=>{ history.push("/")}}>CONTINUE SHOPPING</Button>
         </RxPlThankYouCard>
      </>

   )
}
ThankYou.propTypes = propTypes;
ThankYou.defaultProps = defaultProps;

export default ThankYou