
import {useState, useRef, useCallback, useContext, useEffect} from 'react'
import Button from 'shared/components/Button'
import Card from 'shared/components/Card'
import Input from 'shared/components/Input'
// import Spinner from 'shared/components/Spinner'
import {PageContext} from 'shared/contexts/PageContext'
import useToken from 'shared/hooks/useToken'
import useService from 'shared/hooks/useService'
import {AuthenticationType} from 'shared/constants/authentication'
import PropTypes from 'prop-types'
import {RxPlSignupBase, RxPlFormLayout, RxPlFormGroup, RxPlFormContentWrapper, RxPlFormButtonWrapper} from './style'
import { gaPageview, gaSignUpProgress } from 'shared/utils/ga'

const propTypes = {
    type: PropTypes.oneOf([AuthenticationType.SIGNUP, AuthenticationType.VERIFICATION]),
    data: PropTypes.object,
 }

 const defaultProps = {
    type: AuthenticationType.SIGNUP,
  }

  
const Account = props => {
    const {setToken} = useToken()
    const {api} = useService()
    const form = useRef(null)
    const [type, setType] = useState(props.type)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [accessCode, setAccessCode] = useState("")
    const [maskEmail, setMaskEmail] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const {setPage} = useContext(PageContext)


    const resendCode = async () => {
        setErrorMessage("")
        const formData = new FormData(form.current)
        formData.append("emailaddress", email) // must supply

        const serializeFormData = Object.fromEntries(formData)
        try {
            let response = await api().post("/auth/resend", serializeFormData)
            if (response.data.httpStatusCode === 200) {
                setSuccessMessage("A code has been sent to your email.")
                setTimeout(()=> { setSuccessMessage("")}, 4000)
            }
         } catch ({response}) {
            console.log("Error Response", response)
            if (response && response.data) {
                setErrorMessage((response.data.messages && response.data.messages.toString()) || "Server Error")
            }
         }
    }


    const signIn = useCallback ( async (callback) => {
        const formData = new FormData(form.current)
        formData.append("emailaddress", email) // must supply
        formData.append("password", password)
        const serializeFormData = Object.fromEntries(formData)

        try {
            let response = await api().post("/auth/signin", serializeFormData)
            setToken(response.data.idToken)
            localStorage.setItem("token", response.data.idToken)
            if (typeof callback === 'function') {
                callback()
            }
         } catch (error) {
             const {response} = error
             if (response && response.data) {
                 setErrorMessage(response.data.messages.toString() || response.data.title)
             } else {
                 setErrorMessage("Network Error")
 
             }
         }

    }, [api, email, password, setToken])
    
    const signUp = async () => {
        setErrorMessage("")

        const formData = new FormData(form.current)
        const serializeFormData = Object.fromEntries(formData)
        try {
           let response = await api().post("/auth/register", serializeFormData)
            const {destination} = response.data.codeDeliveryDetails
            if (destination) {
                setMaskEmail(destination)
                setType(AuthenticationType.VERIFICATION)
            }
        } catch (error) {
            const {response} = error
            
            if (response && response.status && response.status === 404) {
                const errorMessage = (response.data && response.data.messages && response.data.messages.toString()) || response.data && response.data.validations && response.data.validations[9001]
                setErrorMessage(errorMessage)
               return
            }
   
            const errorMessage = (response.data.messages && response.data.messages.toString()) || (response && response.data && response.data.title) || "Network Error"
            setErrorMessage(errorMessage)

        }
     }

     const confirm = async () => {
        setErrorMessage("")
        const formData = new FormData(form.current)
        formData.append("emailaddress", email) // must supply

        const serializeFormData = Object.fromEntries(formData)

        try {
            let response = await api().post("/auth/confirm", serializeFormData)
            // 
            if (response.data.httpStatusCode === 200) {
             //   props.handleNextStep("contact")
                 signIn(()=> {
                    localStorage.setItem('onboarding', 'contact')
                    props.handleNextStep("contact")
                    gaSignUpProgress('account')

                 })
            }

         } catch ({response}) {
            if (response && response.data) {
                setErrorMessage((response.data.messages && response.data.messages.toString()) || "Server Error")

            } else {
                setErrorMessage("Server Error")

            }

         }
     }

    const onPasswordChange = e => {
        setErrorMessage("")
        setPassword(e.target.value)
    }

    const onEmailChange = e => {
        setErrorMessage("")
        setEmail(e.target.value)
    }

    const onAccessCodeChange = e => {
        setErrorMessage("")
        setAccessCode(e.target.value)
    }

    const onCancel = () => {
        setPage("")
    }

    const onNextStepHandler = e => {
        e.preventDefault()
        confirm()
    }

    const onRegister = e => {
        e.preventDefault()

        signUp()
    }

    const onResendCode = e => {
        e.preventDefault()
        resendCode()
    }

    useEffect(()=>{
        let mounted = true
        const enterKeyListener = e => {
           if (e.code === "Enter" || e.code === "NumpadEnter" || e.keyCode === 13) {
              e.preventDefault()
              if (mounted) {

                if (type === AuthenticationType.VERIFICATION) {
                    confirm()
                }
                if (type === AuthenticationType.SIGNUP) {
                    signUp()
                }


              }
           }
        }
        gaPageview("/signup", "Sign up - Account")

        document.addEventListener("keydown", enterKeyListener)
        return () => {
          document.removeEventListener("keydown", enterKeyListener);
          mounted = false
        }
     },[confirm, signUp])

    return (
        <RxPlSignupBase>
            <RxPlSignupBase.Content>
                <RxPlFormLayout ref={form}>

                    {
                     errorMessage !== "" && <p className="error-message-auth">Error: {errorMessage}</p>
                    }

                    {
                        successMessage !== "" && <p className="success-message-auth">{successMessage}</p>
                    }
                    <RxPlFormContentWrapper className="max-w620">
  
                        {
                            type === AuthenticationType.SIGNUP && 
                            <Card>

                            <p>* = Required field</p>
                            <RxPlFormGroup>
                            <label>Email Address * (will be used as your account name)</label>
                            <Input name="emailaddress" type="email" placeholder="Email" onChange={onEmailChange} required />
                            </RxPlFormGroup>

                            <RxPlFormGroup>
                            <label>Password*</label>
                            <Input name="password" type="password" placeholder="Password" onChange={onPasswordChange} pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required />
                            <p className="text-align-left">Must be at least 8 characters</p>
                            </RxPlFormGroup>

                            <RxPlFormGroup>
                            <label>Access Code*</label>
                            <Input name="activationcode" type="text" placeholder="Access Code" onChange={onAccessCodeChange} maxLength="20" /> 
                            {/* <p className="text-align-left">Must be at least 8 characters</p> */}
                            </RxPlFormGroup>

                            </Card> 
                        }

                        {
                            type === AuthenticationType.VERIFICATION && 
                            <Card>
                                <p className="text-align-right-reset">We have sent a code by email to {maskEmail}. <br/>
                                Enter it below to confirm account.
                                </p>
                                <RxPlFormGroup>
                                <label>Verification code*</label>
                                <Input type="number" name="confirmationcode" placeholder="Code" />
                                <Input name="emailaddress" type="hidden" value={email} required />
                                <Input name="password" type="hidden" value={password} required />
                                </RxPlFormGroup>

                                {
                 type === AuthenticationType.VERIFICATION && 
                 <p>Didn't receive a code?<Button className="resend-btn" onClick={onResendCode}>Resend it</Button></p>

                    }

                            </Card> 
                        }

                        
                    </RxPlFormContentWrapper>
                   <RxPlFormButtonWrapper>
                   <Button onClick={onCancel} type="outline">Cancel</Button>

                    {
                    type === AuthenticationType.SIGNUP && 
                    <Button onClick={onRegister} type="primary">Save and Continue</Button>

                    }

                    {
                    type === AuthenticationType.VERIFICATION && 
                    <Button onClick={onNextStepHandler} type="primary">Confirm and Continue</Button>

                    }
                   </RxPlFormButtonWrapper>
                </RxPlFormLayout>
            </RxPlSignupBase.Content>
        </RxPlSignupBase>
    )
}

Account.propTypes = propTypes
Account.defaultProps = defaultProps


export default Account