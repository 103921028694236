
import styled from 'styled-components'
import Card from 'shared/components/Card'
import {color, font} from 'shared/theme'
import {RxPlLayout, device} from 'shared/layout'

export const RxPlAccountLayout = styled(RxPlLayout)`
   padding-left: 0;
   margin-top: 40px;
   h2 {
      margin-bottom: 70px;
   }

   h4 {
      margin-top: 32px;
   }

   @media only screen and ${device.sm} {
     padding-left: 310px;
     margin-top: 0;

   }
`

export const RxPlInformationCard = styled(Card)`
   display: block;
   padding: 48px 40px;
   width: 100%;
   ${font.size}

   .edit-button {
      position: absolute;
      top: 20px; 
      right: 20px;
      ${font.bold}
      color: ${color.sky};
      padding: 0;
      margin: 0;
      i {
         margin-right: 4px;
      }
   }


   input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #ccc;
   opacity: 1; /* Firefox */
      }

      input:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #ccc;
      }

      input::-ms-input-placeholder { /* Microsoft Edge */
      color: #ccc;
      }

      input {
         width: 100%;
      }
`

RxPlInformationCard.Header = styled.h4`
   display: block;
   margin-bottom: 70px;

`

RxPlInformationCard.ItemWrapper = styled.div`
   display: block;
   margin: 0 -16px;


   @media only screen and ${device.sm} {
      display: flex;
   }
`

RxPlInformationCard.Item = styled.div`
   position :relative;
   flex-basis: 0;
   flex-grow: 1;
   margin: 0 16px;
`

RxPlInformationCard.OrderItem = styled.div`
   flex-basis: 0;
   flex-grow: 1;
   margin: 0;

   button {
      display: block;
      color: ${color.sky};
      font-size: 16px;
      padding: 0;
      margin: 0 0 10px;
      line-height: 28px;
   }
`


RxPlInformationCard.ItemHeader = styled.h6`
   width: 100%;
   padding-bottom: 8px;
   margin-top: 32px;
   border-bottom: 1px solid ${color.grayLight};
   font-size: 20px;
   .edit-button {
      top: unset;
   }

   @media only screen and ${device.sm} {
      &:first-child {
         margin-top: 0;
      }
   }
`

RxPlInformationCard.OrderItemHeader = styled.h5`
   margin: 0 0 16px;
   padding: 16px 0;
   border-bottom: 1px solid ${color.grayLight};

`

RxPlInformationCard.Avatar = styled.div`
`


export const RxPlFormFieldWrapper = styled.div`
   display: flex;
   margin: 0 -8px;
   > div {
      flex-basis: 0;
      flex-grow: 1;
      margin: 0 8px;
   }
`

export const RxPlFormButtonWrapper = styled.div`
   display: flex;
   justify-content: center;
   margin: 56px 0 0;
`


export const RxPlLisenceItem = styled.div`
position: relative;
   display: flex;
   &.edit {
      display: block;
   }

   .dea-submit-btn {


      width: max-content;
      margin-right: 0;
      margin-top: 32px;
   }

   .stack {
      flex-direction: row;
      justify-content: flex-end;
   }
   p {
      margin-bottom: 0;

      &.file-name {
         color: ${color.sky};
         font-size: 18px;
         cursor: pointer;
      }
   }

   i {
      margin-right: 12px;
   }
`
