import  { useContext } from 'react';
import { PageContext } from 'shared/contexts/PageContext';

export const usePage = () => {
   
    const ctx = useContext(PageContext)

    return {
        ...ctx
    }
}