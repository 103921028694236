export const ModalType = {
   PRODUCT_DETAIL: 'product-detail',
   AUTHENTICATION: 'authentication',
   USER_AGREEMENT: 'user-agreement',
   TERMS_OF_USE: 'terms-of-use',
   TERMS_OF_USE_AUTHENTICATED: 'terms-of-use-authenticated',
   FORGOT_PASSWORD: 'forgot-password',
   RESET_PASSWORD: 'reset-password',
   PRIVACY_POLICY: 'privacy-policy',
   LICENSOR_INFORMATION: 'licensor-information',
   SURVEY: 'survey',
   ERROR: 'error',
   LOADING: 'loading',
   UPLOADING: 'uploading'
 }