
import {useState, useRef, useCallback, useContext, useEffect} from 'react'
import Button from 'shared/components/Button'
import Card from 'shared/components/Card'
import Input from 'shared/components/Input'
import Topbar from 'shared/components/Topbar'
import Footer from 'shared/components/Footer'
// import Spinner from 'shared/components/Spinner'
import {PageContext} from 'shared/contexts/PageContext'
import useAdminToken from 'shared/hooks/useAdminToken'
import useService from 'shared/hooks/useService'
import {AuthenticationType} from 'shared/constants/authentication'
import PropTypes from 'prop-types'
import {RxPlSignupBase, RxPlFormLayout, RxPlFormGroup, RxPlFormContentWrapper, RxPlFormButtonWrapper} from '../styles'
import {RxPlContent} from 'shared/layout'
import { useHistory} from 'react-router-dom'


const propTypes = {
    type: PropTypes.oneOf([AuthenticationType.SIGNUP, AuthenticationType.VERIFICATION]),
    data: PropTypes.object,
 }

 const defaultProps = {
    type: AuthenticationType.SIGNUP,
  }

  
const AdminLogin = props => {
    const {setToken, token} = useAdminToken()
    const {adminAPI} = useService()
    const form = useRef(null)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const history = useHistory()

    const {
        setAdminAuthentication, 
     }  = useContext(PageContext)

    const signIn = useCallback ( async (callback) => {
        setErrorMessage("")
        const formData = new FormData(form.current)
        const serializeFormData = Object.fromEntries(formData)
        try {
           let response = await adminAPI().post("/auth/signin", serializeFormData)
           setToken(response.data.idToken, 1)
           setAdminAuthentication(true)
           history.push("/admin/access-code")

        } catch (error) {
            const {response} = error
            console.log(error.response)
            if (response && response.data) {
                setErrorMessage(response.data.messages.toString() || response.data.validations[9002] || "Something went wrong.")
            } else {
                setErrorMessage("Network Error")

            }
        }
    }, [email, password, setToken])
    

    const onPasswordChange = e => {
        setErrorMessage("")
        setPassword(e.target.value)
    }

    const onEmailChange = e => {
        setErrorMessage("")
        setEmail(e.target.value)
    }


    const onSignIn = e => {
        e.preventDefault()
        signIn()
     }


    useEffect(()=>{
        let mounted = true
        const enterKeyListener = e => {
           if (e.code === "Enter" || e.code === "NumpadEnter" || e.keyCode === 13) {
              e.preventDefault()
              if (mounted) {
                onSignIn()

              }
           }
        }
        if (token) {
          history.push('/admin/access-code')
        }

        document.addEventListener("keydown", enterKeyListener)
        return () => {
          document.removeEventListener("keydown", enterKeyListener);
          mounted = false
        }
     },[])

    return (
      <>
        <Topbar type='admin'  />
        <RxPlContent>
        <RxPlSignupBase>
            <RxPlSignupBase.Content>
                <h4>Pharmacy Onboarding Admin</h4>
                <RxPlFormLayout ref={form}>

                    {
                     errorMessage !== "" && <p className="error-message-auth">Error: {errorMessage}</p>
                    }

                    {
                        successMessage !== "" && <p className="success-message-auth">{successMessage}</p>
                    }
                    <RxPlFormContentWrapper className="max-w620">


                      <Card>

                        <h3>Sign in</h3>
                        <RxPlFormGroup>
                        <label>Email address</label>
                        <Input name="emailaddress" type="email" placeholder="Email" onChange={onEmailChange} required />
                        </RxPlFormGroup>

                        <RxPlFormGroup>
                        <label>Password</label>
                        <Input name="password" type="password" placeholder="Password" onChange={onPasswordChange} pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" required />
                        <Button onClick={onSignIn} type="primary">Sign in</Button>
                        <p className="text-align-left">By clicking Sign in, you agree to RxPlace’s Terms of Use and Privacy Policy. RxPlace may send you communications.</p>

                        </RxPlFormGroup>

                      </Card> 

                    </RxPlFormContentWrapper>
                   <RxPlFormButtonWrapper>

                   </RxPlFormButtonWrapper>
                </RxPlFormLayout>
            </RxPlSignupBase.Content>
        </RxPlSignupBase>
        </RxPlContent>
        <Footer />
      </>
    )
}

AdminLogin.propTypes = propTypes
AdminLogin.defaultProps = defaultProps


export default AdminLogin