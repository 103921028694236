import styled from 'styled-components'
import {color} from 'shared/theme'
import checkIcon from 'assets/icons/check.svg'

export const RxPlCheckbox = styled.div`
 position: relative;
   font-family: $primary-font;
  margin-bottom: 16px;

   input[type="checkbox"] {
       position: absolute;
       width: 24px;
       height: 24px;
       top : 0;
       left: 0;
      & + label {
          border-radius: 9px;
          padding: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          transition: box-shadow .4s;
          margin:0;
          &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              border-radius: inherit;
          }
          .control {
              position: relative;
              display: inline-block;
              z-index: 1;
              vertical-align: top;
              height: 24px;
              width: 24px;
              border-radius: 2px;
              border: 1px solid var(--sky, ${color.grayMedium});
              &:before {
                 content: '';
                 position: absolute;
                 left: 0;
                 top: 0;
                 right: 0;
                 bottom: 0;
                 border-radius: inherit;

             }

              .checkmark {
                  background: ${color.sky};
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  transition: transform .4s;
                  background: ${color.sky} url(${checkIcon}) no-repeat;
                  background-position: center;
                  opacity: var(--switch, 0);

              }
          }

          span {
           line-height: 22px;
           font-size: 16px;
           font-weight: 500;
           display: inline-block;
           vertical-align: top;
           z-index: 1;
           position: relative;
           margin-left: 12px;

        }

      }

      &:checked {
         & + label {
             --offset: 16px;
             --switch: 1;
             --sky: ${color.sky};
         }
     }
  }

`